/**
 * @fileoverview gRPC-Web generated client stub for devWithMazey.org
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: org.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.devWithMazey = {};
proto.devWithMazey.org = require('./org_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.devWithMazey.org.OrganisationServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.devWithMazey.org.OrganisationServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devWithMazey.org.GeneralRequest,
 *   !proto.devWithMazey.org.GetOrganisationsResponse>}
 */
const methodDescriptor_OrganisationService_GetOrganisations = new grpc.web.MethodDescriptor(
  '/devWithMazey.org.OrganisationService/GetOrganisations',
  grpc.web.MethodType.UNARY,
  proto.devWithMazey.org.GeneralRequest,
  proto.devWithMazey.org.GetOrganisationsResponse,
  /**
   * @param {!proto.devWithMazey.org.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devWithMazey.org.GetOrganisationsResponse.deserializeBinary
);


/**
 * @param {!proto.devWithMazey.org.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devWithMazey.org.GetOrganisationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devWithMazey.org.GetOrganisationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devWithMazey.org.OrganisationServiceClient.prototype.getOrganisations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devWithMazey.org.OrganisationService/GetOrganisations',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_GetOrganisations,
      callback);
};


/**
 * @param {!proto.devWithMazey.org.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devWithMazey.org.GetOrganisationsResponse>}
 *     Promise that resolves to the response
 */
proto.devWithMazey.org.OrganisationServicePromiseClient.prototype.getOrganisations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devWithMazey.org.OrganisationService/GetOrganisations',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_GetOrganisations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devWithMazey.org.GetOrganisationDetailsRequest,
 *   !proto.devWithMazey.org.Organisation>}
 */
const methodDescriptor_OrganisationService_GetOrganisationDetails = new grpc.web.MethodDescriptor(
  '/devWithMazey.org.OrganisationService/GetOrganisationDetails',
  grpc.web.MethodType.UNARY,
  proto.devWithMazey.org.GetOrganisationDetailsRequest,
  proto.devWithMazey.org.Organisation,
  /**
   * @param {!proto.devWithMazey.org.GetOrganisationDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devWithMazey.org.Organisation.deserializeBinary
);


/**
 * @param {!proto.devWithMazey.org.GetOrganisationDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devWithMazey.org.Organisation)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devWithMazey.org.Organisation>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devWithMazey.org.OrganisationServiceClient.prototype.getOrganisationDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devWithMazey.org.OrganisationService/GetOrganisationDetails',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_GetOrganisationDetails,
      callback);
};


/**
 * @param {!proto.devWithMazey.org.GetOrganisationDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devWithMazey.org.Organisation>}
 *     Promise that resolves to the response
 */
proto.devWithMazey.org.OrganisationServicePromiseClient.prototype.getOrganisationDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devWithMazey.org.OrganisationService/GetOrganisationDetails',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_GetOrganisationDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devWithMazey.org.CreateOrganisationRequest,
 *   !proto.devWithMazey.org.Organisation>}
 */
const methodDescriptor_OrganisationService_CreateOrganisation = new grpc.web.MethodDescriptor(
  '/devWithMazey.org.OrganisationService/CreateOrganisation',
  grpc.web.MethodType.UNARY,
  proto.devWithMazey.org.CreateOrganisationRequest,
  proto.devWithMazey.org.Organisation,
  /**
   * @param {!proto.devWithMazey.org.CreateOrganisationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devWithMazey.org.Organisation.deserializeBinary
);


/**
 * @param {!proto.devWithMazey.org.CreateOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devWithMazey.org.Organisation)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devWithMazey.org.Organisation>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devWithMazey.org.OrganisationServiceClient.prototype.createOrganisation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devWithMazey.org.OrganisationService/CreateOrganisation',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_CreateOrganisation,
      callback);
};


/**
 * @param {!proto.devWithMazey.org.CreateOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devWithMazey.org.Organisation>}
 *     Promise that resolves to the response
 */
proto.devWithMazey.org.OrganisationServicePromiseClient.prototype.createOrganisation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devWithMazey.org.OrganisationService/CreateOrganisation',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_CreateOrganisation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devWithMazey.org.GeneralRequest,
 *   !proto.devWithMazey.org.GeneralResponse>}
 */
const methodDescriptor_OrganisationService_DeleteOrganisation = new grpc.web.MethodDescriptor(
  '/devWithMazey.org.OrganisationService/DeleteOrganisation',
  grpc.web.MethodType.UNARY,
  proto.devWithMazey.org.GeneralRequest,
  proto.devWithMazey.org.GeneralResponse,
  /**
   * @param {!proto.devWithMazey.org.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devWithMazey.org.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.devWithMazey.org.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devWithMazey.org.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devWithMazey.org.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devWithMazey.org.OrganisationServiceClient.prototype.deleteOrganisation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devWithMazey.org.OrganisationService/DeleteOrganisation',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_DeleteOrganisation,
      callback);
};


/**
 * @param {!proto.devWithMazey.org.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devWithMazey.org.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.devWithMazey.org.OrganisationServicePromiseClient.prototype.deleteOrganisation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devWithMazey.org.OrganisationService/DeleteOrganisation',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_DeleteOrganisation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devWithMazey.org.ChangeUserAccessRequest,
 *   !proto.devWithMazey.org.GeneralResponse>}
 */
const methodDescriptor_OrganisationService_ChangeUserAccess = new grpc.web.MethodDescriptor(
  '/devWithMazey.org.OrganisationService/ChangeUserAccess',
  grpc.web.MethodType.UNARY,
  proto.devWithMazey.org.ChangeUserAccessRequest,
  proto.devWithMazey.org.GeneralResponse,
  /**
   * @param {!proto.devWithMazey.org.ChangeUserAccessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devWithMazey.org.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.devWithMazey.org.ChangeUserAccessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devWithMazey.org.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devWithMazey.org.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devWithMazey.org.OrganisationServiceClient.prototype.changeUserAccess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devWithMazey.org.OrganisationService/ChangeUserAccess',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_ChangeUserAccess,
      callback);
};


/**
 * @param {!proto.devWithMazey.org.ChangeUserAccessRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devWithMazey.org.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.devWithMazey.org.OrganisationServicePromiseClient.prototype.changeUserAccess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devWithMazey.org.OrganisationService/ChangeUserAccess',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_ChangeUserAccess);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devWithMazey.org.GetActivitiesRequest,
 *   !proto.devWithMazey.org.GetActivitiesResponse>}
 */
const methodDescriptor_OrganisationService_GetActivities = new grpc.web.MethodDescriptor(
  '/devWithMazey.org.OrganisationService/GetActivities',
  grpc.web.MethodType.UNARY,
  proto.devWithMazey.org.GetActivitiesRequest,
  proto.devWithMazey.org.GetActivitiesResponse,
  /**
   * @param {!proto.devWithMazey.org.GetActivitiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devWithMazey.org.GetActivitiesResponse.deserializeBinary
);


/**
 * @param {!proto.devWithMazey.org.GetActivitiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devWithMazey.org.GetActivitiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devWithMazey.org.GetActivitiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devWithMazey.org.OrganisationServiceClient.prototype.getActivities =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devWithMazey.org.OrganisationService/GetActivities',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_GetActivities,
      callback);
};


/**
 * @param {!proto.devWithMazey.org.GetActivitiesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devWithMazey.org.GetActivitiesResponse>}
 *     Promise that resolves to the response
 */
proto.devWithMazey.org.OrganisationServicePromiseClient.prototype.getActivities =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devWithMazey.org.OrganisationService/GetActivities',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_GetActivities);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devWithMazey.org.InviteUserRequest,
 *   !proto.devWithMazey.org.GeneralResponse>}
 */
const methodDescriptor_OrganisationService_InviteUser = new grpc.web.MethodDescriptor(
  '/devWithMazey.org.OrganisationService/InviteUser',
  grpc.web.MethodType.UNARY,
  proto.devWithMazey.org.InviteUserRequest,
  proto.devWithMazey.org.GeneralResponse,
  /**
   * @param {!proto.devWithMazey.org.InviteUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devWithMazey.org.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.devWithMazey.org.InviteUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devWithMazey.org.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devWithMazey.org.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devWithMazey.org.OrganisationServiceClient.prototype.inviteUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devWithMazey.org.OrganisationService/InviteUser',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_InviteUser,
      callback);
};


/**
 * @param {!proto.devWithMazey.org.InviteUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devWithMazey.org.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.devWithMazey.org.OrganisationServicePromiseClient.prototype.inviteUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devWithMazey.org.OrganisationService/InviteUser',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_InviteUser);
};


module.exports = proto.devWithMazey.org;

