import React from 'react'
import { Grid, CircularProgress, Typography, Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import AppsList from './appsListCard'
import { useNavigate, useParams } from 'react-router'
import useOrgData from '../customHooks/generalHooks/useOrgData'

export default function AppsInfo() {

    const { orgId } = useParams()
    const [stat, orgData] = useOrgData(orgId)
    const navigate = useNavigate()

    const render = () => {
        if (stat === 2) {
            return <CircularProgress size={25} />
        } else if (stat === 0) {
            return <>
                <Button
                    color="primary"
                    variant="contained"
                    style={{ textTransform: 'none', marginBottom: 20 }}
                    endIcon={<Add />}
                    onClick={() => {
                        setTimeout(() => navigate('/dashboard/' + orgId + '/clients/new'), 300)

                    }}
                >
                    Register New
                </Button>
                <AppsList
                    type="Production"
                    list={orgData.clientsList.filter((client) => client.environment === "Production")}
                    orgId={orgId}
                />
                <AppsList
                    type="Testing"
                    list={orgData.clientsList.filter((client) => client.environment === "Development")}
                    orgId={orgId}
                />
                <AppsList
                    type="Suspended"
                    list={orgData.clientsList.filter((client) => client.environment === "Suspended")}
                    orgId={orgId}
                />
            </>
        } else if (stat === -1) {
            return <Typography varian="body1" color="error">
                Something went wrong
            </Typography>
        } else {
            return <></>
        }
    }

    return <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ height: '100%', width: '100%', paddingTop: 20 }}
    >
        {render()}
    </Grid>
}
