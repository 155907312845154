import React, { useState } from 'react'
import { Button, Typography } from '@mui/material'
import ButtonOnState from '../../../components/buttonOnState'

export default function ProdRequested(props) {

    const [btnStat, setBtnStat] = useState(0)

    return <>
        <Typography variant="subtitle2" color="textSecondary" align="left" gutterBottom>
            Requested for Production Access
        </Typography>
        <Button
            style={{ textTransform: 'none' }}
            disabled
        >
            <ButtonOnState
                loadState={btnStat}
                successText="Cancel Request"
            />
        </Button>
        <br />
        <Typography variant="caption" color="textSecondary" align="left">
            - Your request is usually responded within 2-3 working days.
        </Typography>
        <br />
        <br />
    </>
}