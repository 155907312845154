import React from 'react'
import { Button, Typography } from '@mui/material'

export default function SuspendApp(props) {

    return <>
        <Button style={{ textTransform: 'none' }}>
            Suspend Client
        </Button>
        <br />
        <Typography variant="caption" color="textSecondary" align="left">
            - No token requests will be fulfilled for your client.
            <br />
            - Your client will stay in Production. You can resume anytime.
            <br />
            <br />
        </Typography>
    </>
}