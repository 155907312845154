import React from 'react'
import { useParams } from 'react-router'
import { Grid } from '@mui/material'
import SideOpts from './sideOpts'
import LoginFields from './login'
import SignupFields from './signup'
import ResetPassword from './resetPassword'
import VerifyEmail from './verifyEmail'

export default function AuthFace() {
    const { type } = useParams()

    const renderField = () => {
        if (type === 'login') {
            return <LoginFields />
        } else if (type === 'signup') {
            return <SignupFields />
        } else if (type === "resetPassword") {
            return <ResetPassword />
        } else if (type === "verify") {
            return <VerifyEmail />
        } else {
            return <></>
        }
    }

    return <Grid container style={{ height: '100vh' }} >
        <Grid item xs={12} md={7} sx={{ height: '100%' }} >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ height: '100%' }}
            >
                {renderField()}
            </Grid>
        </Grid>
        <Grid item xs={0} md={5} sx={{ display: { xs: 'none', md: 'block' } }} >
            <SideOpts />
        </Grid>
    </Grid>
}
