import React from 'react'
import { Provider } from 'react-redux'
import { store } from './globalState'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme'
import PreLogin from './preLogin'

export default function Console() {
    return <Provider store={store}>
        <ThemeProvider theme={theme}>
            <PreLogin />
        </ThemeProvider>
    </Provider>
}
