import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material'
import TopOptions from '../login/topOptions'
import SignupForm from './signupForm'
import SignupSuccess from './signupSuccess'

export default function SignupFields() {
    const [view, setView] = useState('signupform')

    const renderView = () => {
        if (view === 'signupform') {
            return <SignupForm
                setView={setView}
            />
        } else if (view === 'signupsuccess') {
            return <SignupSuccess />
        } else {
            return <></>
        }
    }

    return <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
            minHeight: '100vh',
            zIndex: 100,
        }}
    >
        <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            sx={{ textAlign: 'center' }}
        >
            <TopOptions />
            <Grid container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Typography
                    variant="h6"
                    color="textPrimary"
                    gutterBottom
                    style={{ paddingBottom: 20 }}
                >
                    Signup
                </Typography>
                {renderView()}
            </Grid>
            <div style={{ width: '100%', height: 30 }} />
        </Grid>
    </Grid>
}
