import React, { useState } from 'react'
import { Typography, FormControl, Select, MenuItem } from '@mui/material'
import EndpointHitsView from './endpointHitsView'

export default function EndpointHits(props) {

    const [hitsByGroup, setHitsByGroup] = useState(0);

    const changeGroup = (event) => {
        setHitsByGroup(event.target.value)
    }

    const renderHitsCount = () => {
        if (hitsByGroup === 0) {
            return <EndpointHitsView
                hits={props.hitstoday}
            />
        } else {
            return <EndpointHitsView
                hits={props.hitsoverall}
            />
        }
    }

    return <>
        <Typography variant="subtitle1" align="center">
            Top 5 Endpoints by Hits
        </Typography>
        <FormControl style={{ width: '90%', margin: '10px 5%' }}>
            <Select
                id="hits-by-group"
                value={hitsByGroup}
                onChange={changeGroup}
            >
                <MenuItem value={0}>Today</MenuItem>
                <MenuItem value={1}>Overall</MenuItem>
            </Select>
        </FormControl>
        {renderHitsCount()}
    </>
}