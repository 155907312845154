import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material'
import PermsAddBox from './permAddBox'
import PermsSearchBox from './permsSearchBox'

export default function AddPerms(props) {

    const [view, setView] = useState('permSearch')
    const [permToAdd, setPermToAdd] = useState({})

    const render = () => {
        if (view === 'permSearch') {
            return <PermsSearchBox
                setView={setView}
                setPermToAdd={setPermToAdd}
            />
        } else if (view === 'permAdd') {
            return <PermsAddBox
                setView={setView}
                permToAdd={permToAdd}
                setPermToAdd={setPermToAdd}
                selectedPermKey={props.selectedPermKey}
                setSelectedPermKey={props.setSelectedPermKey}
                appId={props.appId}
                orgId={props.orgId}
            />
        } else {
            return <></>
        }
    }

    return <>
        <Typography variant="h6" gutterBottom align="center">
            Add Permission
        </Typography>
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            style={{ padding: '10px 0' }}
        >
            {render()}
        </Grid>
        <Typography
            variant="caption"
            gutterBottom
            align="center"
            color="textSecondary"
        >
            <br />
            <br />
            <br />
            Added permissions cannot be modified.
            <br />
            You need to delete the permission and add it again.
        </Typography>
    </>
}