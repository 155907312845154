import React from 'react'
import { Grid, Button } from '@mui/material'

export default function TopOptions() {

    const options = [
        {
            title: 'DevConsole',
            clickHandler: () => { }
        },
        {
            title: 'Help',
            clickHandler: () => {
                window.open('https://www.mazeyard.com/contact', '_blank', 'noopener,noreferrer')
            }
        }
    ]

    return <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
            padding: '20px', marginTop: '-40px',
            width: '100%',
            maxWidth: 500
        }}
    >
        {
            options.map((opt, index) => {
                return <Button
                    sx={{
                        textTransform: 'none',
                        ml: index === 0 ? '-20px' : 0,
                        mr: index === 1 ? '-20px' : 0,
                    }}
                    onClick={opt.clickHandler}
                    key={index}
                >
                    {opt.title}
                </Button>
            })
        }
    </Grid>
}
