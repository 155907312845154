import React, { useState } from 'react'
import { Button, Typography } from '@mui/material'
import GetMetadata from '../components/getMetadata'
import { OrgServerAddress } from '../protos/server'
import { OrganisationServiceClient } from '../protos/org_grpc_web_pb'
import { GeneralRequest } from '../protos/org_pb'
import { useDispatch, useSelector } from 'react-redux'
import ButtonOnState from '../components/buttonOnState'
import { useParams, useNavigate } from 'react-router'

export default function DeleteOrg() {

    const [deleteStat, setDeleteStat] = useState(0)
    const [error, setError] = useState("")
    const dispatch = useDispatch()
    const userInfo = useSelector(state => state.userInfo)
    const { orgId } = useParams()
    const navigate = useNavigate()

    const warnText = [
        {
            text: 'Delete Organisation',
            variant: 'body1',
            style: { fontSize: 18, marginBottom: '15px' }
        },
        {
            text: ' - You will be able to delete this organisation only if you have admin access or if you are the creator.',
            variant: 'body2',
            style: {}
        },
        {
            text: ` - Deleting organisation means every resource related to it will also be deleted.`,
            variant: 'body2',
            style: {},
        },
        {
            text: ` - This includes clients you have registered, their access keys, user relationships, activity logs, etc.`,
            variant: 'body2',
            style: {}
        },
        {
            text: 'This action is irreversible.',
            variant: 'body1',
            style: {}
        }
    ]

    const renderWarnText = () => {
        return warnText.map((text, index) => {
            return <Typography
                variant={text.variant}
                color="textPrimary"
                gutterBottom
                style={text.style}
                key={index}
            >
                {text.text}
            </Typography>
        })
    }

    const handleDelete = () => {
        setDeleteStat(2)
        const md = GetMetadata()
        md.authorization = userInfo.token
        const client = new OrganisationServiceClient(OrgServerAddress, null, null)

        const req = new GeneralRequest()
        req.setRequest(orgId)

        client.deleteOrganisation(req, md, (err, response) => {
            if (err) {
                if (err.message === "token expired" || err.message === "error decoding token" || err.message === "no token" || err.message === "invalid token") {
                    dispatch({ type: 'LOGOUT' })
                    return
                } else if (err.message === 5) {
                    setDeleteStat(-1)
                    setError(err.message)
                    dispatch({ type: 'REMOVE_ORG', orgId: orgId })
                    return
                }
                setDeleteStat(-1)
                setError(err.message)
            } else {
                const res = response.toObject()
                dispatch({ type: 'REMOVE_ORG', orgId: orgId })
                setError("")
                setDeleteStat(1)
                setTimeout(() => navigate('/dashboard'), 2000)
            }
        })
    }


    return <>
        {renderWarnText()}
        <br />
        <Typography variant="h6" color="textSecondary" gutterBottom >
            Are you sure?
        </Typography>
        <Button
            variant="contained"
            color="error"
            style={{ marginTop: '10px', textTransform: 'none' }}
            disabled={deleteStat !== 0}
            onClick={handleDelete}
        >
            <ButtonOnState
                loadState={deleteStat}
                successText="Delete"
            />
        </Button>
        <br />
        <br />
        <Typography variant="body2" color="error" gutterBottom >
            {error}
        </Typography>
    </>
}