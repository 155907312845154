import React, { useState } from 'react'
import { Button, TextField, CircularProgress, Typography } from '@mui/material'
import { DoneAll, Error } from '@mui/icons-material'
import { useDispatch } from 'react-redux'

const fieldReq = {
    appName: {
        lowLimit: 4,
        highLimit: 30,
    },
    logoUrl: {
        lowLimit: 10,
        highLimit: 512,
    },
    redirectUri: {
        lowLimit: 5,
        highLimit: 128,
    },
    privacyPolicyUrl: {
        lowLimit: 10,
        highLimit: 512,
    },
    termsUrl: {
        lowLimit: 10,
        highLimit: 512,
    },
}

export default function EditDetails(props) {

    const [values, setValues] = useState({
        appName: props.appData.clientname,
        orgName: props.appData.orgname,
        appEnv: props.appData.status,
        logoUrl: props.appData.logo,
        redirectUri: props.appData.redirecturi,
        privacyPolicyUrl: props.appData.privacypolicy,
        termsUrl: props.appData.terms,
    })
    const [btnStat, setBtnStat] = useState(0)
    const [error, setError] = useState("")
    const dispatch = useDispatch()

    const changeValues = (prop) => (event) => {
        setBtnStat(0)
        setValues({ ...values, [prop]: event.target.value })
    }

    const getHelperText = (fieldName) => {
        if (values[fieldName].length < fieldReq[fieldName].lowLimit) {
            return "Minimum " + fieldReq[fieldName].lowLimit + " characters"
        } else if (values[fieldName].length > fieldReq[fieldName].highLimit) {
            return "Maximum " + fieldReq[fieldName].highLimit + " characters"
        } else {
            return " "
        }
    }

    const handleSubmit = () => {
        setBtnStat(2)
        props.setContainerKey(props.containerKey + 1)
    }

    const renderSubmitButton = () => {
        if (btnStat === 0) {
            return "Submit"
        } else if (btnStat === 2) {
            return <CircularProgress size={25} />
        } else if (btnStat === 1) {
            return <DoneAll />
        } else if (btnStat === -1) {
            return <Error color="error" />
        }
    }

    return <form
        noValidate autoComplete="off"
        onSubmit={e => e.preventDefault()}
        style={{ width: '100%', maxWidth: '800px', marginTop: '20px' }}
    >
        <TextField
            id="org-name"
            label="Organisation"
            variant="outlined"
            name="orgName"
            value={values.orgName}
            fullWidth
            style={{ marginBottom: 30 }}
            disabled
            helperText="Cross check your organisation."
        />
        <TextField
            id="app-name"
            label="App Name"
            variant="outlined"
            name="appName"
            value={values.appName}
            fullWidth
            onChange={changeValues('appName')}
            helperText={getHelperText('appName')}
            style={{ marginBottom: 30 }}
        />
        <TextField
            id="redirectUri"
            label="Redirect URI"
            variant="outlined"
            name="redirectUri"
            value={values.redirectUri}
            fullWidth
            onChange={changeValues('redirectUri')}
            helperText={getHelperText('redirectUri')}
            style={{ marginBottom: 30 }}
        />
        <TextField
            id="logoUrl"
            label="Address to App's Logo"
            variant="outlined"
            name="logoUrl"
            value={values.logoUrl}
            fullWidth
            onChange={changeValues('logoUrl')}
            helperText={getHelperText('logoUrl')}
            style={{ marginBottom: 30 }}
        />
        <TextField
            id="privacyPolicyUrl"
            label="Address to Privacy Policy"
            variant="outlined"
            name="privacyPolicyUrl"
            value={values.privacyPolicyUrl}
            fullWidth
            onChange={changeValues('privacyPolicyUrl')}
            helperText={getHelperText('privacyPolicyUrl')}
            style={{ marginBottom: 30 }}
        />
        <TextField
            id="termsUrl"
            label="Address to Terms & Conditions"
            variant="outlined"
            name="termsUrl"
            value={values.termsUrl}
            fullWidth
            onChange={changeValues('termsUrl')}
            helperText={getHelperText('termsUrl')}
            style={{ marginBottom: 30 }}
        />
        <Typography variant="body2" color="error" gutterBottom>
            {error}
        </Typography>
        <br />
        <Button
            variant="contained"
            color="primary"
            disabled={btnStat !== 0}
            onClick={handleSubmit}
            style={{ textTransform: 'none' }}
        >
            {renderSubmitButton()}
        </Button>
    </form>
}
