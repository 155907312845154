import React from 'react'
import { Grid } from '@mui/material'
import UserLostContent from './userLostContent'

export default function Fallback() {

    return <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%' }}
    >
        <UserLostContent />
    </Grid>
}
