import React from 'react'
import { Grid, Typography } from '@mui/material'
import useQuery from '../../customHooks/generalHooks/useQuery'
import RequestFields from './requestFields'
import TokenPage from './tokenPage'
import HelperButtons from './helperButtons'
import SuccessPage from './successPage'

export default function ResetPassword() {
    const query = useQuery()
    const token = query.get('token')
    const message = query.get('message')

    const renderFields = () => {
        if (token !== null) {
            return <TokenPage token={token} />
        } else if (message !== null) {
            return <SuccessPage />
        } else {
            return <RequestFields />
        }
    }

    return <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
            padding: '10px',
            textAlign: 'center',
            height: '100%',
            width: {
                xs: '98%',
                md: '80%',
            },
        }}
    >
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ width: '100%' }}
        >
            <Typography
                variant="h6"
                color="textPrimary"
                gutterBottom
                style={{ paddingBottom: 20 }}
            >
                Reset Password
            </Typography>
            {renderFields()}
            <HelperButtons />
        </Grid>
        <div style={{ width: '100%', height: 30 }} />
    </Grid>
}
