import React from 'react'
import { Typography, Grid } from '@mui/material'

export default function AppsBreakdown(props) {

    return <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%', width: 250, marginRight: 5 }}
    >
        <Typography variant="body2" gutterBottom align="left">
            In Production : {props.orgInfo.clientsList.filter((client) => client.environment === "Production").length}
        </Typography>
        <Typography variant="body2" gutterBottom>
            In Testing : {props.orgInfo.clientsList.filter((client) => client.environment === "Development").length}
        </Typography>
        <Typography variant="body2" gutterBottom>
            Suspended : {props.orgInfo.clientsList.filter((client) => client.environment === "Suspended").length}
        </Typography>
    </Grid>
}
