import React from 'react'
import { Button, Box } from '@mui/material'
import { Computer } from '@mui/icons-material'
import { useNavigate } from 'react-router'

export default function Logo() {
    const navigate = useNavigate()

    return <Box
        sx={{
            width: '100%',
            bgcolor: 'background.main',
            padding: '20px 0',
            textAlign: 'center'
        }}
    >
        <Button
            startIcon={<Computer sx={{ fontSize: 'large' }} />}
            onClick={() => navigate('/dashboard')}
            sx={{ textTransform: 'none' }}
            size="large"
        >
            Dev Console
        </Button>
    </Box>
}
