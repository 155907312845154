import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import useQuery from '../../customHooks/generalHooks/useQuery'
import GetMetadata from '../../components/getMetadata'
import { AuthServerAddress } from '../../protos/server'
import { AuthServiceClient } from '../../protos/auth_grpc_web_pb'
import { GeneralRequest } from '../../protos/auth_pb'

export default function VerifyEmail() {
    const [stat, setStat] = useState(0)
    const [error, setError] = useState("")
    const navigate = useNavigate()
    const query = useQuery()
    const token = query.get('token')

    const render = () => {
        if (stat === 2) {
            return <CircularProgress size={25} />
        } else if (stat === -1) {
            return <Typography variant='body2' color='error'>
                {error}
            </Typography>
        } else if (stat === 1) {
            return <>
                <Typography variant="body2">
                    Your email has been verified.
                    <br />
                    You can proceed to Login.
                </Typography>
                <br />
                <Button
                    sx={{ textTransform: 'none' }}
                    onClick={() => navigate('/auth/login')}
                >
                    Login
                </Button>
            </>
        }
    }

    const callVerify = () => {
        setStat(2)

        const md = GetMetadata()
        const client = new AuthServiceClient(AuthServerAddress, null, null)

        const req = new GeneralRequest();
        req.setRequest(token)

        client.verifyEmail(req, md, (err, response) => {
            if (err) {
                setError(err.message)
                setStat(-1)
            } else {
                setStat(1)
            }
        })
    }

    useEffect(() => {
        callVerify()
    }, [])

    return <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        sx={{
            padding: '10px',
            textAlign: 'center',
            height: '100%',
            width: {
                xs: '98%',
                md: '80%',
            }
        }}
        spacing={0}
    >
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            {render()}
        </Grid>
    </Grid>
}
