import React from 'react'
import { Card } from '@mui/material'
import EachUser from './eachUser'

export default function UserCard(props) {
    const orgInfo = props.orgInfo

    return <Card variant="outlined" style={{ width: '100%', marginTop: '20px' }}>
        {
            orgInfo.membersList.map((user, index) => {
                return <EachUser
                    user={user}
                    lastUser={index === orgInfo.membersList.length - 1}
                    key={index}
                    orgInfo={orgInfo}
                />
            })
        }
    </Card>
}