// source: org.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.devWithMazey.org.Activity', null, global);
goog.exportSymbol('proto.devWithMazey.org.ChangeUserAccessRequest', null, global);
goog.exportSymbol('proto.devWithMazey.org.Client', null, global);
goog.exportSymbol('proto.devWithMazey.org.CreateOrganisationRequest', null, global);
goog.exportSymbol('proto.devWithMazey.org.GeneralRequest', null, global);
goog.exportSymbol('proto.devWithMazey.org.GeneralResponse', null, global);
goog.exportSymbol('proto.devWithMazey.org.GetActivitiesRequest', null, global);
goog.exportSymbol('proto.devWithMazey.org.GetActivitiesResponse', null, global);
goog.exportSymbol('proto.devWithMazey.org.GetOrganisationDetailsRequest', null, global);
goog.exportSymbol('proto.devWithMazey.org.GetOrganisationsResponse', null, global);
goog.exportSymbol('proto.devWithMazey.org.InviteUserRequest', null, global);
goog.exportSymbol('proto.devWithMazey.org.Organisation', null, global);
goog.exportSymbol('proto.devWithMazey.org.User', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.org.GeneralRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devWithMazey.org.GeneralRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.org.GeneralRequest.displayName = 'proto.devWithMazey.org.GeneralRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.org.GeneralResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devWithMazey.org.GeneralResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.org.GeneralResponse.displayName = 'proto.devWithMazey.org.GeneralResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.org.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devWithMazey.org.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.org.User.displayName = 'proto.devWithMazey.org.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.org.Client = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devWithMazey.org.Client, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.org.Client.displayName = 'proto.devWithMazey.org.Client';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.org.Organisation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.devWithMazey.org.Organisation.repeatedFields_, null);
};
goog.inherits(proto.devWithMazey.org.Organisation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.org.Organisation.displayName = 'proto.devWithMazey.org.Organisation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.org.GetOrganisationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.devWithMazey.org.GetOrganisationsResponse.repeatedFields_, null);
};
goog.inherits(proto.devWithMazey.org.GetOrganisationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.org.GetOrganisationsResponse.displayName = 'proto.devWithMazey.org.GetOrganisationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.org.GetOrganisationDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devWithMazey.org.GetOrganisationDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.org.GetOrganisationDetailsRequest.displayName = 'proto.devWithMazey.org.GetOrganisationDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.org.CreateOrganisationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devWithMazey.org.CreateOrganisationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.org.CreateOrganisationRequest.displayName = 'proto.devWithMazey.org.CreateOrganisationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.org.ChangeUserAccessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devWithMazey.org.ChangeUserAccessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.org.ChangeUserAccessRequest.displayName = 'proto.devWithMazey.org.ChangeUserAccessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.org.GetActivitiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devWithMazey.org.GetActivitiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.org.GetActivitiesRequest.displayName = 'proto.devWithMazey.org.GetActivitiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.org.Activity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devWithMazey.org.Activity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.org.Activity.displayName = 'proto.devWithMazey.org.Activity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.org.GetActivitiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.devWithMazey.org.GetActivitiesResponse.repeatedFields_, null);
};
goog.inherits(proto.devWithMazey.org.GetActivitiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.org.GetActivitiesResponse.displayName = 'proto.devWithMazey.org.GetActivitiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.org.InviteUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devWithMazey.org.InviteUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.org.InviteUserRequest.displayName = 'proto.devWithMazey.org.InviteUserRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.org.GeneralRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.org.GeneralRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.org.GeneralRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.GeneralRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    request: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.org.GeneralRequest}
 */
proto.devWithMazey.org.GeneralRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.org.GeneralRequest;
  return proto.devWithMazey.org.GeneralRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.org.GeneralRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.org.GeneralRequest}
 */
proto.devWithMazey.org.GeneralRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.org.GeneralRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.org.GeneralRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.org.GeneralRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.GeneralRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequest();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string request = 1;
 * @return {string}
 */
proto.devWithMazey.org.GeneralRequest.prototype.getRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.org.GeneralRequest} returns this
 */
proto.devWithMazey.org.GeneralRequest.prototype.setRequest = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.org.GeneralResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.org.GeneralResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.org.GeneralResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.GeneralResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    response: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.org.GeneralResponse}
 */
proto.devWithMazey.org.GeneralResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.org.GeneralResponse;
  return proto.devWithMazey.org.GeneralResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.org.GeneralResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.org.GeneralResponse}
 */
proto.devWithMazey.org.GeneralResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.org.GeneralResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.org.GeneralResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.org.GeneralResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.GeneralResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponse();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string response = 1;
 * @return {string}
 */
proto.devWithMazey.org.GeneralResponse.prototype.getResponse = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.org.GeneralResponse} returns this
 */
proto.devWithMazey.org.GeneralResponse.prototype.setResponse = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.org.User.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.org.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.org.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accesslevel: jspb.Message.getFieldWithDefault(msg, 4, ""),
    joined: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.org.User}
 */
proto.devWithMazey.org.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.org.User;
  return proto.devWithMazey.org.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.org.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.org.User}
 */
proto.devWithMazey.org.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesslevel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setJoined(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.org.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.org.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.org.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccesslevel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getJoined();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 userid = 1;
 * @return {number}
 */
proto.devWithMazey.org.User.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.devWithMazey.org.User} returns this
 */
proto.devWithMazey.org.User.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string firstName = 2;
 * @return {string}
 */
proto.devWithMazey.org.User.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.org.User} returns this
 */
proto.devWithMazey.org.User.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.devWithMazey.org.User.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.org.User} returns this
 */
proto.devWithMazey.org.User.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string accessLevel = 4;
 * @return {string}
 */
proto.devWithMazey.org.User.prototype.getAccesslevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.org.User} returns this
 */
proto.devWithMazey.org.User.prototype.setAccesslevel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string joined = 5;
 * @return {string}
 */
proto.devWithMazey.org.User.prototype.getJoined = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.org.User} returns this
 */
proto.devWithMazey.org.User.prototype.setJoined = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.org.Client.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.org.Client.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.org.Client} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.Client.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    environment: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.org.Client}
 */
proto.devWithMazey.org.Client.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.org.Client;
  return proto.devWithMazey.org.Client.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.org.Client} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.org.Client}
 */
proto.devWithMazey.org.Client.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnvironment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.org.Client.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.org.Client.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.org.Client} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.Client.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEnvironment();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string clientId = 1;
 * @return {string}
 */
proto.devWithMazey.org.Client.prototype.getClientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.org.Client} returns this
 */
proto.devWithMazey.org.Client.prototype.setClientid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.devWithMazey.org.Client.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.org.Client} returns this
 */
proto.devWithMazey.org.Client.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string logo = 3;
 * @return {string}
 */
proto.devWithMazey.org.Client.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.org.Client} returns this
 */
proto.devWithMazey.org.Client.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string environment = 4;
 * @return {string}
 */
proto.devWithMazey.org.Client.prototype.getEnvironment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.org.Client} returns this
 */
proto.devWithMazey.org.Client.prototype.setEnvironment = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.devWithMazey.org.Organisation.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.org.Organisation.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.org.Organisation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.org.Organisation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.Organisation.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    orgname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdon: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastupdatedon: jspb.Message.getFieldWithDefault(msg, 4, ""),
    membersList: jspb.Message.toObjectList(msg.getMembersList(),
    proto.devWithMazey.org.User.toObject, includeInstance),
    clientsList: jspb.Message.toObjectList(msg.getClientsList(),
    proto.devWithMazey.org.Client.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.org.Organisation}
 */
proto.devWithMazey.org.Organisation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.org.Organisation;
  return proto.devWithMazey.org.Organisation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.org.Organisation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.org.Organisation}
 */
proto.devWithMazey.org.Organisation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrgid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedon(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdatedon(value);
      break;
    case 5:
      var value = new proto.devWithMazey.org.User;
      reader.readMessage(value,proto.devWithMazey.org.User.deserializeBinaryFromReader);
      msg.addMembers(value);
      break;
    case 6:
      var value = new proto.devWithMazey.org.Client;
      reader.readMessage(value,proto.devWithMazey.org.Client.deserializeBinaryFromReader);
      msg.addClients(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.org.Organisation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.org.Organisation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.org.Organisation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.Organisation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOrgname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedon();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastupdatedon();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMembersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.devWithMazey.org.User.serializeBinaryToWriter
    );
  }
  f = message.getClientsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.devWithMazey.org.Client.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 orgId = 1;
 * @return {number}
 */
proto.devWithMazey.org.Organisation.prototype.getOrgid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.devWithMazey.org.Organisation} returns this
 */
proto.devWithMazey.org.Organisation.prototype.setOrgid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string orgName = 2;
 * @return {string}
 */
proto.devWithMazey.org.Organisation.prototype.getOrgname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.org.Organisation} returns this
 */
proto.devWithMazey.org.Organisation.prototype.setOrgname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string createdOn = 3;
 * @return {string}
 */
proto.devWithMazey.org.Organisation.prototype.getCreatedon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.org.Organisation} returns this
 */
proto.devWithMazey.org.Organisation.prototype.setCreatedon = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string lastUpdatedOn = 4;
 * @return {string}
 */
proto.devWithMazey.org.Organisation.prototype.getLastupdatedon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.org.Organisation} returns this
 */
proto.devWithMazey.org.Organisation.prototype.setLastupdatedon = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated User members = 5;
 * @return {!Array<!proto.devWithMazey.org.User>}
 */
proto.devWithMazey.org.Organisation.prototype.getMembersList = function() {
  return /** @type{!Array<!proto.devWithMazey.org.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.devWithMazey.org.User, 5));
};


/**
 * @param {!Array<!proto.devWithMazey.org.User>} value
 * @return {!proto.devWithMazey.org.Organisation} returns this
*/
proto.devWithMazey.org.Organisation.prototype.setMembersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.devWithMazey.org.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.devWithMazey.org.User}
 */
proto.devWithMazey.org.Organisation.prototype.addMembers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.devWithMazey.org.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devWithMazey.org.Organisation} returns this
 */
proto.devWithMazey.org.Organisation.prototype.clearMembersList = function() {
  return this.setMembersList([]);
};


/**
 * repeated Client clients = 6;
 * @return {!Array<!proto.devWithMazey.org.Client>}
 */
proto.devWithMazey.org.Organisation.prototype.getClientsList = function() {
  return /** @type{!Array<!proto.devWithMazey.org.Client>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.devWithMazey.org.Client, 6));
};


/**
 * @param {!Array<!proto.devWithMazey.org.Client>} value
 * @return {!proto.devWithMazey.org.Organisation} returns this
*/
proto.devWithMazey.org.Organisation.prototype.setClientsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.devWithMazey.org.Client=} opt_value
 * @param {number=} opt_index
 * @return {!proto.devWithMazey.org.Client}
 */
proto.devWithMazey.org.Organisation.prototype.addClients = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.devWithMazey.org.Client, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devWithMazey.org.Organisation} returns this
 */
proto.devWithMazey.org.Organisation.prototype.clearClientsList = function() {
  return this.setClientsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.devWithMazey.org.GetOrganisationsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.org.GetOrganisationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.org.GetOrganisationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.org.GetOrganisationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.GetOrganisationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    organisationsList: jspb.Message.toObjectList(msg.getOrganisationsList(),
    proto.devWithMazey.org.Organisation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.org.GetOrganisationsResponse}
 */
proto.devWithMazey.org.GetOrganisationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.org.GetOrganisationsResponse;
  return proto.devWithMazey.org.GetOrganisationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.org.GetOrganisationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.org.GetOrganisationsResponse}
 */
proto.devWithMazey.org.GetOrganisationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = new proto.devWithMazey.org.Organisation;
      reader.readMessage(value,proto.devWithMazey.org.Organisation.deserializeBinaryFromReader);
      msg.addOrganisations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.org.GetOrganisationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.org.GetOrganisationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.org.GetOrganisationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.GetOrganisationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOrganisationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.devWithMazey.org.Organisation.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 userid = 1;
 * @return {number}
 */
proto.devWithMazey.org.GetOrganisationsResponse.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.devWithMazey.org.GetOrganisationsResponse} returns this
 */
proto.devWithMazey.org.GetOrganisationsResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Organisation organisations = 2;
 * @return {!Array<!proto.devWithMazey.org.Organisation>}
 */
proto.devWithMazey.org.GetOrganisationsResponse.prototype.getOrganisationsList = function() {
  return /** @type{!Array<!proto.devWithMazey.org.Organisation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.devWithMazey.org.Organisation, 2));
};


/**
 * @param {!Array<!proto.devWithMazey.org.Organisation>} value
 * @return {!proto.devWithMazey.org.GetOrganisationsResponse} returns this
*/
proto.devWithMazey.org.GetOrganisationsResponse.prototype.setOrganisationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.devWithMazey.org.Organisation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.devWithMazey.org.Organisation}
 */
proto.devWithMazey.org.GetOrganisationsResponse.prototype.addOrganisations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.devWithMazey.org.Organisation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devWithMazey.org.GetOrganisationsResponse} returns this
 */
proto.devWithMazey.org.GetOrganisationsResponse.prototype.clearOrganisationsList = function() {
  return this.setOrganisationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.org.GetOrganisationDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.org.GetOrganisationDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.org.GetOrganisationDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.GetOrganisationDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.org.GetOrganisationDetailsRequest}
 */
proto.devWithMazey.org.GetOrganisationDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.org.GetOrganisationDetailsRequest;
  return proto.devWithMazey.org.GetOrganisationDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.org.GetOrganisationDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.org.GetOrganisationDetailsRequest}
 */
proto.devWithMazey.org.GetOrganisationDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrgid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.org.GetOrganisationDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.org.GetOrganisationDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.org.GetOrganisationDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.GetOrganisationDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 orgId = 1;
 * @return {number}
 */
proto.devWithMazey.org.GetOrganisationDetailsRequest.prototype.getOrgid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.devWithMazey.org.GetOrganisationDetailsRequest} returns this
 */
proto.devWithMazey.org.GetOrganisationDetailsRequest.prototype.setOrgid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.org.CreateOrganisationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.org.CreateOrganisationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.org.CreateOrganisationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.CreateOrganisationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orgpurpose: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.org.CreateOrganisationRequest}
 */
proto.devWithMazey.org.CreateOrganisationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.org.CreateOrganisationRequest;
  return proto.devWithMazey.org.CreateOrganisationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.org.CreateOrganisationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.org.CreateOrganisationRequest}
 */
proto.devWithMazey.org.CreateOrganisationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgpurpose(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.org.CreateOrganisationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.org.CreateOrganisationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.org.CreateOrganisationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.CreateOrganisationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrgpurpose();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string orgName = 1;
 * @return {string}
 */
proto.devWithMazey.org.CreateOrganisationRequest.prototype.getOrgname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.org.CreateOrganisationRequest} returns this
 */
proto.devWithMazey.org.CreateOrganisationRequest.prototype.setOrgname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string orgPurpose = 2;
 * @return {string}
 */
proto.devWithMazey.org.CreateOrganisationRequest.prototype.getOrgpurpose = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.org.CreateOrganisationRequest} returns this
 */
proto.devWithMazey.org.CreateOrganisationRequest.prototype.setOrgpurpose = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.org.ChangeUserAccessRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.org.ChangeUserAccessRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.org.ChangeUserAccessRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.ChangeUserAccessRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    newaccess: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.org.ChangeUserAccessRequest}
 */
proto.devWithMazey.org.ChangeUserAccessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.org.ChangeUserAccessRequest;
  return proto.devWithMazey.org.ChangeUserAccessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.org.ChangeUserAccessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.org.ChangeUserAccessRequest}
 */
proto.devWithMazey.org.ChangeUserAccessRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrgid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewaccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.org.ChangeUserAccessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.org.ChangeUserAccessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.org.ChangeUserAccessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.ChangeUserAccessRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getNewaccess();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 orgId = 1;
 * @return {number}
 */
proto.devWithMazey.org.ChangeUserAccessRequest.prototype.getOrgid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.devWithMazey.org.ChangeUserAccessRequest} returns this
 */
proto.devWithMazey.org.ChangeUserAccessRequest.prototype.setOrgid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 userId = 2;
 * @return {number}
 */
proto.devWithMazey.org.ChangeUserAccessRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.devWithMazey.org.ChangeUserAccessRequest} returns this
 */
proto.devWithMazey.org.ChangeUserAccessRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string newAccess = 3;
 * @return {string}
 */
proto.devWithMazey.org.ChangeUserAccessRequest.prototype.getNewaccess = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.org.ChangeUserAccessRequest} returns this
 */
proto.devWithMazey.org.ChangeUserAccessRequest.prototype.setNewaccess = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.org.GetActivitiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.org.GetActivitiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.org.GetActivitiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.GetActivitiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.org.GetActivitiesRequest}
 */
proto.devWithMazey.org.GetActivitiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.org.GetActivitiesRequest;
  return proto.devWithMazey.org.GetActivitiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.org.GetActivitiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.org.GetActivitiesRequest}
 */
proto.devWithMazey.org.GetActivitiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrgid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.org.GetActivitiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.org.GetActivitiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.org.GetActivitiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.GetActivitiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 orgId = 1;
 * @return {number}
 */
proto.devWithMazey.org.GetActivitiesRequest.prototype.getOrgid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.devWithMazey.org.GetActivitiesRequest} returns this
 */
proto.devWithMazey.org.GetActivitiesRequest.prototype.setOrgid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.org.Activity.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.org.Activity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.org.Activity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.Activity.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activitytype: jspb.Message.getFieldWithDefault(msg, 3, ""),
    clientid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    author: jspb.Message.getFieldWithDefault(msg, 5, ""),
    statement: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.org.Activity}
 */
proto.devWithMazey.org.Activity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.org.Activity;
  return proto.devWithMazey.org.Activity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.org.Activity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.org.Activity}
 */
proto.devWithMazey.org.Activity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrgid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivitytype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthor(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.org.Activity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.org.Activity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.org.Activity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.Activity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActivitytype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getClientid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAuthor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatement();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 orgId = 1;
 * @return {number}
 */
proto.devWithMazey.org.Activity.prototype.getOrgid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.devWithMazey.org.Activity} returns this
 */
proto.devWithMazey.org.Activity.prototype.setOrgid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string timeStamp = 2;
 * @return {string}
 */
proto.devWithMazey.org.Activity.prototype.getTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.org.Activity} returns this
 */
proto.devWithMazey.org.Activity.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string activityType = 3;
 * @return {string}
 */
proto.devWithMazey.org.Activity.prototype.getActivitytype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.org.Activity} returns this
 */
proto.devWithMazey.org.Activity.prototype.setActivitytype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string clientId = 4;
 * @return {string}
 */
proto.devWithMazey.org.Activity.prototype.getClientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.org.Activity} returns this
 */
proto.devWithMazey.org.Activity.prototype.setClientid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string author = 5;
 * @return {string}
 */
proto.devWithMazey.org.Activity.prototype.getAuthor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.org.Activity} returns this
 */
proto.devWithMazey.org.Activity.prototype.setAuthor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string statement = 6;
 * @return {string}
 */
proto.devWithMazey.org.Activity.prototype.getStatement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.org.Activity} returns this
 */
proto.devWithMazey.org.Activity.prototype.setStatement = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.devWithMazey.org.GetActivitiesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.org.GetActivitiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.org.GetActivitiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.org.GetActivitiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.GetActivitiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    activitiesList: jspb.Message.toObjectList(msg.getActivitiesList(),
    proto.devWithMazey.org.Activity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.org.GetActivitiesResponse}
 */
proto.devWithMazey.org.GetActivitiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.org.GetActivitiesResponse;
  return proto.devWithMazey.org.GetActivitiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.org.GetActivitiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.org.GetActivitiesResponse}
 */
proto.devWithMazey.org.GetActivitiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrgid(value);
      break;
    case 2:
      var value = new proto.devWithMazey.org.Activity;
      reader.readMessage(value,proto.devWithMazey.org.Activity.deserializeBinaryFromReader);
      msg.addActivities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.org.GetActivitiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.org.GetActivitiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.org.GetActivitiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.GetActivitiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getActivitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.devWithMazey.org.Activity.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 orgId = 1;
 * @return {number}
 */
proto.devWithMazey.org.GetActivitiesResponse.prototype.getOrgid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.devWithMazey.org.GetActivitiesResponse} returns this
 */
proto.devWithMazey.org.GetActivitiesResponse.prototype.setOrgid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Activity activities = 2;
 * @return {!Array<!proto.devWithMazey.org.Activity>}
 */
proto.devWithMazey.org.GetActivitiesResponse.prototype.getActivitiesList = function() {
  return /** @type{!Array<!proto.devWithMazey.org.Activity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.devWithMazey.org.Activity, 2));
};


/**
 * @param {!Array<!proto.devWithMazey.org.Activity>} value
 * @return {!proto.devWithMazey.org.GetActivitiesResponse} returns this
*/
proto.devWithMazey.org.GetActivitiesResponse.prototype.setActivitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.devWithMazey.org.Activity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.devWithMazey.org.Activity}
 */
proto.devWithMazey.org.GetActivitiesResponse.prototype.addActivities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.devWithMazey.org.Activity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devWithMazey.org.GetActivitiesResponse} returns this
 */
proto.devWithMazey.org.GetActivitiesResponse.prototype.clearActivitiesList = function() {
  return this.setActivitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.org.InviteUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.org.InviteUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.org.InviteUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.InviteUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    useremail: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.org.InviteUserRequest}
 */
proto.devWithMazey.org.InviteUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.org.InviteUserRequest;
  return proto.devWithMazey.org.InviteUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.org.InviteUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.org.InviteUserRequest}
 */
proto.devWithMazey.org.InviteUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrgid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUseremail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.org.InviteUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.org.InviteUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.org.InviteUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.org.InviteUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUseremail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 orgId = 1;
 * @return {number}
 */
proto.devWithMazey.org.InviteUserRequest.prototype.getOrgid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.devWithMazey.org.InviteUserRequest} returns this
 */
proto.devWithMazey.org.InviteUserRequest.prototype.setOrgid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string userEmail = 2;
 * @return {string}
 */
proto.devWithMazey.org.InviteUserRequest.prototype.getUseremail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.org.InviteUserRequest} returns this
 */
proto.devWithMazey.org.InviteUserRequest.prototype.setUseremail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.devWithMazey.org);
