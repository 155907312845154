import React, { useState } from 'react'
import { Button, Typography } from '@mui/material'
import ButtonOnState from '../../../components/buttonOnState'

export default function AppSuspended(props) {

    const [btnStat, setBtnStat] = useState(0)

    const revokeSuspension = () => {
        setBtnStat(2)
    }

    return <>
        <Button
            style={{ textTransform: 'none' }}
            disabled={!props.appData.isUserSuspended}
            onClick={revokeSuspension}
        >
            <ButtonOnState
                loadState={btnStat}
                successText="Revoke Suspension"
            />
        </Button>
        <br />
        <Typography variant="caption" color="textSecondary" align="left">
            {
                props.appData.isUserSuspended ? <>
                    - Your request is usually responded within 2-3 working days.
                </> : <>
                    - Your client is suspended from our side.
                    <br />
                    - Everyone in your organisation have been notified about this.
                    <br />
                    - You can raise a support ticket regarding this issue.
                </>
            }
        </Typography>
        <br />
        <br />
    </>
}