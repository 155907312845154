import React, { useState } from 'react';
import { Grid, Box, TextField, Collapse, Button, Typography, CircularProgress } from '@mui/material'
import { Error } from '@mui/icons-material'
import { OrganisationServiceClient } from '../protos/org_grpc_web_pb'
import { InviteUserRequest } from '../protos/org_pb'
import GetMetadata from '../components/getMetadata'
import { OrgServerAddress } from '../protos/server'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

export default function InviteUser() {
    const [open, setOpen] = useState(false)
    const [values, setValues] = useState({
        email: "",
        error: "",
    })
    const [stat, setStat] = useState(0)
    const userInfo = useSelector((state) => state.userInfo)
    const { orgId } = useParams()
    const dispatch = useDispatch()

    const changeValue = (prop) => (event) => {
        if (prop !== 'error') {
            setValues({ ...values, [prop]: event.target.value, error: '' })
            setStat(0)
        } else {
            setValues({ ...values, [prop]: event.target.value })
        }
    }

    const renderSubmitButton = () => {
        if (stat === 2) {
            return <CircularProgress size={25} />
        } else if (stat === 0) {
            return "Send Invite"
        } else if (stat === 1) {
            return "Invitation Sent"
        } else {
            return <Error />
        }
    }

    const handleSubmit = () => {
        setStat(2)
        const md = GetMetadata()
        md.authorization = userInfo.token
        const client = new OrganisationServiceClient(OrgServerAddress, null, null)

        const req = new InviteUserRequest()
        req.setOrgid(orgId)
        req.setUseremail(values.email)
        client.inviteUser(req, md, (err, response) => {
            if (err) {
                if (err.message === "token expired" || err.message === "error decoding token" || err.message === "no token" || err.message === "invalid token") {
                    dispatch({ type: 'LOGOUT' })
                    return
                } else if (err.code === 16 || err.code === 5) {
                    dispatch({ type: 'REMOVE_ORG', orgId: orgId })
                    setStat(-1)
                    return
                }
                setStat(-1)
                setValues({ ...values, error: err.message })
            } else {
                setStat(1)
            }
        })
    }

    return (
        <Box sx={{ width: '100%', margin: '20px 0' }}>
            <Button
                variant="contained"
                onClick={() => {
                    setOpen(!open);
                }}
                style={{ textTransform: 'none', marginBottom: '15px' }}
            >
                Invite User
            </Button>
            <Collapse in={open}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    component="form"
                    noValidate
                    autoComplete="off"
                    onSubmit={e => { handleSubmit() }}
                    style={{ paddingTop: '20px' }}
                >
                    <TextField
                        id="new-user-email"
                        label="Email Address"
                        variant="outlined"
                        style={{ width: '300px', marginRight: '20px' }}
                        value={values.email}
                        onChange={changeValue('email')}
                        helperText="User must be registered"
                    />
                    <Button
                        variant="contained"
                        style={{
                            marginRight: '20px',
                            textTransform: 'none',
                            marginBottom: '25px'
                        }}
                        onClick={handleSubmit}
                        disabled={stat !== 0}
                    >
                        {renderSubmitButton()}
                    </Button>
                    <Typography variant="body2" color="error">
                        {values.error}
                    </Typography>
                </Grid>
            </Collapse>
        </Box>
    );
}
