import React from 'react'
import { Box, Typography } from '@mui/material'

export default function SuccessPage() {

    return <Box
        style={{ width: '100%', maxWidth: 500, minHeight: 145 }}
    >
        <Typography variant="body2">
            If the email you entered is registered with us,
            <br />
            you will receive a link to reset the password in you mailbox.
        </Typography>
    </Box>
}