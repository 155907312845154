import React, { useState } from 'react'
import { Typography, Grid, Button, TextField, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

export default function PermsAddParams(props) {

    const [newParamName, setNewParamName] = useState('')

    const addParam = () => {
        if (props.permToAdd.paramsList === undefined) {
            props.setPermToAdd({
                ...props.permToAdd,
                paramsList: [newParamName]
            })
        } else {
            props.setPermToAdd({
                ...props.permToAdd,
                paramsList: [...props.permToAdd.paramsList, newParamName]
            })
        }
        setNewParamName('')
    }

    const removeParam = (paramName) => {
        props.setPermToAdd({
            ...props.permToAdd,
            paramsList: props.permToAdd.paramsList.filter(param => param !== paramName)
        })
    }

    const render = () => {
        if (props.permToAdd.paramsList === undefined) {
            return <Typography variant="body2" color="textSecondary" align="left" style={{ width: '100%' }}>
                None
            </Typography>
        }
        return props.permToAdd.paramsList.map((param) => {
            return <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                key={param}
            >
                <IconButton color="error" onClick={() => removeParam(param)}>
                    <Close />
                </IconButton>
                <Typography variant="subtitle1" style={{ marginLeft: 20 }}>
                    {param}
                </Typography>
            </Grid>
        })
    }

    return <>
        <Typography variant="subtitle1" align="left" style={{ width: '100%' }}>
            Parameters :
        </Typography>
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            style={{ paddingLeft: 20 }}
        >
            {render()}
        </Grid>
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            style={{ marginTop: 20 }}
        >
            <TextField
                id="perm-param"
                label="Paramater"
                variant="outlined"
                style={{ width: `calc(100% - 80px)`, margin: '0 10px 0 0' }}
                value={newParamName}
                onChange={(event) => setNewParamName(event.target.value)}
            />
            <Button variant="outlined"
                style={{ width: '70px', textTransform: 'none' }}
                disabled={newParamName.length < 1}
                onClick={addParam}
            >
                Add
            </Button>
        </Grid>
    </>
}