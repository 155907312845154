import React from 'react'
import { Grid, Box } from '@mui/material'
import TestingLimits from './testingLimits'
import NewAppForm from './newAppForm'

export default function NewAppContainer(props) {

    return <Box
        style={{ width: '100%' }}
    >
        <Grid container spacing={2} >
            <Grid item xs={7} style={{ padding: 40 }}>
                <NewAppForm
                    orgData={props.orgData}
                />
            </Grid>
            <Grid item xs={5} style={{ padding: 40 }}>
                <TestingLimits />
            </Grid>
        </Grid>
    </Box>
}
