import React from 'react'
import { Box, Tab, Tabs } from '@mui/material'

function a11yProps(index) {
    return {
        id: `app-tab-${index}`,
        'aria-controls': `app-tab-${index}`,
    };
}

export default function AppsTabs(props) {

    const appTabs = ["Details", "Permissions", "Activities", "Metrics", "Settings"]

    return <Box sx={{ borderBottom: 1, width: '100%', borderColor: 'divider', marginTop: '-10px' }}>
        <Tabs
            value={props.appTab}
            onChange={props.changeAppTab}
            aria-label="app-tabs-container"
            variant="scrollable"
            scrollButtons="auto"
            style={{ margin: 0 }}
        >
            {
                appTabs.map((eachTab, index) => {
                    return <Tab
                        label={eachTab}
                        {...a11yProps(index)}
                        style={{ textTransform: 'none', marginTop: 10 }}
                        key={index}
                    />
                })
            }
        </Tabs>
    </Box>
}