import React, { useState } from 'react'
import { Box, Grid, CircularProgress, Typography, TableRow, Table, TableCell, TableContainer, TableBody, TableHead, Paper, IconButton } from '@mui/material'
import useAppActivites from '../../customHooks/generalHooks/useAppActivities'

export default function AppActivites(props) {

    const [loadStat, appActivities] = useAppActivites(props.appId, props.orgId)

    const render = () => {
        if (loadStat === 0) {
            return <TableContainer component={Paper}>
                <Table aria-label="activities table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Time</TableCell>
                            <TableCell align="left">Activity Type</TableCell>
                            <TableCell align="left">Author</TableCell>
                            <TableCell align="left">Statement</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            appActivities.map((row) => {
                                return <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                    <TableCell component="th" scope="row">
                                        {row.timestamp}
                                    </TableCell>
                                    <TableCell align="left">{row.activitytype}</TableCell>
                                    <TableCell align="left">{row.author}</TableCell>
                                    <TableCell align="left">{row.statement}</TableCell>
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        } else if (loadStat === 2) {
            return <CircularProgress size={25} />
        } else if (loadStat === -1) {
            return <Typography variant="subtitle2" color="error">
                Something went wrong.
            </Typography>
        }
    }

    return <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        style={{ width: '100%', marginTop: '10px' }
        }
    >
        {render()}
    </Grid >
}