import React from 'react';
import { Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export default function Dashboard() {

    return <Typography varaint="h6" color="textSecondary" gutterBottom align="center">
        Select an Organisation
        <br />
        or
        <br />
        <Link to="/dashboard/newOrg" style={{ color: 'inherit', textDecoration: 'none' }}>
            Create New
        </Link>
    </Typography>
}
