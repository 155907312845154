import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import GetMetadata from '../../components/getMetadata'
import { OrgServerAddress } from '../../protos/server'
import { OrganisationServiceClient } from '../../protos/org_grpc_web_pb'
import { GetOrganisationDetailsRequest } from '../../protos/org_pb'

export default function useOrgData(orgId) {
    const [stat, setStat] = useState(2)
    const [orgData, setOrgData] = useState({})
    const organizations = useSelector((state) => state.organizations)
    const userInfo = useSelector((state) => state.userInfo)
    const dispatch = useDispatch()

    const fetchOrg = () => {
        const md = GetMetadata()
        md.authorization = userInfo.token
        const client = new OrganisationServiceClient(OrgServerAddress, null, null)

        const req = new GetOrganisationDetailsRequest()
        req.setOrgid(orgId)

        client.getOrganisationDetails(req, md, (err, response) => {
            if (err) {
                if (err.message === "token expired" || err.message === "error decoding token" || err.message === "no token" || err.message === "invalid token") {
                    dispatch({ type: 'LOGOUT' })
                    return
                } else if (err.code === 16 || err.code === 5) {
                    dispatch({ type: 'REMOVE_ORG', orgId: orgId })
                    setStat(-1)
                    return
                }
                setStat(-1)
            } else {
                const res = response.toObject()
                dispatch({ type: 'ADD_ORG', org: res })
                setOrgData(res)
                setStat(0)
            }
        })
    }

    useEffect(() => {
        if (orgId === "newOrg") {
            setStat(-1)
            return
        } else if (orgId === undefined) {
            setStat(-1)
            return
        }
        if (organizations.length === 0) {
            fetchOrg()
            return
        }
        for (let i = 0; i < organizations.length; i++) {
            if (organizations[i].orgid === parseInt(orgId)) {
                if (organizations[i].clientsList.length === 0) {
                    fetchOrg()
                }
                setOrgData(organizations[i])
                setStat(0)
                return
            }
        }
        fetchOrg()
    }, [orgId])

    return [stat, orgData]
}