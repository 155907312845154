import React, { useEffect, useState } from 'react'
import { CircularProgress, Box, Typography, TextField, Button, Grid } from '@mui/material'
import GetMetadata from '../../components/getMetadata'
import { AuthServerAddress } from '../../protos/server'
import { AuthServiceClient } from '../../protos/auth_grpc_web_pb'
import { ResetPasswordRequest } from '../../protos/auth_pb'

export default function TokenPage(props) {
    const [stat, setStat] = useState(2)
    const [values, setValues] = useState({
        email: '',
        error: '',
        password: '',
    })
    const changeValue = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value, error: '' })
    }
    const md = GetMetadata()
    const client = new AuthServiceClient(AuthServerAddress, null, null)

    const renderForm = () => {
        return <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={setPassword}
            style={{ width: '100%', maxWidth: 500, minHeight: 145 }}
        >
            <TextField
                id="password"
                label="New Pasword"
                variant="outlined"
                type="password"
                fullWidth
                onChange={changeValue('password')}
                value={values.password}
                style={{ marginBottom: '40px' }}
            />
            <Button
                variant="contained"
                disabled={values.password.length < 8}
                style={{ width: '100%', marginBottom: 40, textTransform: 'none' }}
                onClick={setPassword}
            >
                Submit
            </Button>
            <Typography variant="caption" color="error">
                {values.error}
            </Typography>
        </Box>
    }

    const render = () => {
        if (stat === 2) {
            return <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ height: 145 }}
            >
                <CircularProgress size={25} />
            </Grid>
        } else if (stat === -1) {
            return <Typography
                variant="body2"
                sx={{ height: '145px', color: 'error.main' }}
            >
                {values.error}
            </Typography>
        } else if (stat === 0) {
            return renderForm()
        } else {
            return <Typography variant="body2" style={{ height: 145 }}  >
                Password reset successfull.
                <br />
                Login with your new password.
            </Typography>
        }
    }

    const verifyCode = () => {
        const req = new ResetPasswordRequest();
        req.setRequesttype("verify")
        req.setToken(props.token)

        client.resetPassword(req, md, (err, response) => {
            if (err) {
                if (err.message === "token expired") {
                    setValues({ ...values, error: "Token is expired" })
                    setStat(-1)
                } else {
                    setValues({ ...values, error: err.message })
                    setStat(-1)
                }
            } else {
                setStat(0)
            }
        })
    }

    const setPassword = (event) => {
        event.preventDefault()
        setStat(2)
        const req = new ResetPasswordRequest();
        req.setRequesttype("reset")
        req.setToken(props.token)
        req.setNewpassword(values.password)

        client.resetPassword(req, md, (err, response) => {
            if (err) {
                if (err.message === "token expired") {
                    setValues({ ...values, error: "Token is expired" })
                    setStat(-1)
                } else {
                    setValues({ ...values, error: err.message })
                    setStat(-1)
                }
            } else {
                setStat(1)
            }
        })
    }

    useEffect(() => {
        if (props.token) {
            verifyCode()
        }
    }, [props.token])

    return <Box style={{ width: '100%', maxWidth: 500 }}    >
        {render()}
    </Box>
}