import React from 'react'
import { Grid } from '@mui/material'
import ProdDetails from './prodDetails'
import DeleteApp from './deleteApp'
import GenerateSecret from './generateSecret'

export default function AppSettings(props) {

    return <Grid container spacing={0} style={{ marginTop: 10 }}>
        <GenerateSecret
            appData={props.appData}
        />
        <ProdDetails
            appData={props.appData}
            containerKey={props.containerKey}
            setContainerKey={props.setContainerKey}
        />
        <DeleteApp
            appData={props.appData}
            containerKey={props.containerKey}
            setContainerKey={props.setContainerKey}
        />
    </Grid>
}