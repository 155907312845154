import React from 'react'
import { Box } from '@mui/material'
import PushToProd from './pushToProd'
import ProdRequested from './prodRequested'
import SuspendApp from './suspendApp'
import AppSuspended from './appSuspended'

export default function ProdDetails(props) {

    const renderContent = () => {
        switch (props.appData.status) {
            case "Production":
                return <SuspendApp
                    appData={props.appData}
                />
            case "Suspended":
                return <AppSuspended
                    appData={props.appData}
                />
            case "Development":
                if (props.appData.isproductionrequested) {
                    return <ProdRequested
                        appData={props.appData}
                    />
                }
                return <PushToProd
                    appData={props.appData}
                />
            default:
                return <></>
        }
    }

    return <Box sx={{ width: '100%', marginBottom: '20px', borderBottom: '1px solid', borderColor: 'divider' }}>
        {renderContent()}
    </Box>
}