import React, { useState } from 'react'
import { Grid, Typography, Box, TextField, Button } from '@mui/material'
import ButtonOnState from '../components/buttonOnState'
import GetMetadata from '../components/getMetadata'
import { OrgServerAddress } from '../protos/server'
import { OrganisationServiceClient } from '../protos/org_grpc_web_pb'
import { CreateOrganisationRequest } from '../protos/org_pb'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

const sideNotes = [
    "You can register new users on devport site.",
    "Newly created clients do not have access to production environment.",
    "You can use devport users to test your workflow."
]

export default function NewOrg() {

    const [values, setValues] = useState({
        orgName: "",
        orgPurpose: "",
        error: "",
    })
    const [btnStat, setBtnStat] = useState(0)
    const changeValue = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value, error: "" })
        setBtnStat(0)
    }
    const dispatch = useDispatch()
    const userInfo = useSelector(state => state.userInfo)
    const navigate = useNavigate()

    const handleSubmit = () => {
        setBtnStat(2)
        const md = GetMetadata()
        md.authorization = userInfo.token
        const client = new OrganisationServiceClient(OrgServerAddress, null, null)

        const req = new CreateOrganisationRequest()
        req.setOrgname(values.orgName)

        client.createOrganisation(req, md, (err, response) => {
            if (err) {
                if (err.message === "token expired" || err.message === "error decoding token" || err.message === "no token" || err.message === "invalid token") {
                    dispatch({ type: 'LOGOUT' })
                    return
                }
                setValues({
                    ...values,
                    error: err.message,
                })
                setBtnStat(-1)
            } else {
                const res = response.toObject()
                setBtnStat(1)
                setTimeout(() => navigate('/dashboard/' + res.orgid), 1000)
            }
        })
    }

    return <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{
            height: '100%',
            width: '100%',
            paddingTop: 20
        }}
    >
        <Typography variant="h6" gutterBottom >
            Create New Organisation
        </Typography>
        <Box
            component="form"
            noValidate
            autoComplete="off"
            style={{
                width: '100%',
                maxWidth: 700,
                marginTop: 30,
            }}
        >
            <TextField
                id="org-name"
                label="Organisation Name"
                variant="outlined"
                fullWidth
                value={values.orgName}
                onChange={changeValue('orgName')}
            />
            <Typography
                variant="body2"
                color="error"
                style={{
                    marginTop: 30,
                    marginLeft: 7,
                    marginBottom: 30
                }}
            >
                {values.error}
            </Typography>
            <Button
                variant="contained"
                disabled={values.orgName.length < 4 || btnStat !== 0}
                onClick={handleSubmit}
            >
                <ButtonOnState successText={"Submit"} loadState={btnStat} />
            </Button>
        </Box>
        <Typography
            variant="caption"
            gutterBottom
            style={{
                marginTop: 3
            }}
            color="textSecondary"
        >
            <br />
            <ul>
                {
                    sideNotes.map((eachNote, index) => (
                        <li key={index}>
                            {eachNote}
                            <br />
                        </li>
                    ))
                }
            </ul>
        </Typography>
    </Grid>
}
