// source: client.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.devWithMazey.client.Activity', null, global);
goog.exportSymbol('proto.devWithMazey.client.Client', null, global);
goog.exportSymbol('proto.devWithMazey.client.ClientId', null, global);
goog.exportSymbol('proto.devWithMazey.client.GeneralRequest', null, global);
goog.exportSymbol('proto.devWithMazey.client.GeneralResponse', null, global);
goog.exportSymbol('proto.devWithMazey.client.GetActivitiesResponse', null, global);
goog.exportSymbol('proto.devWithMazey.client.GetUsageMetricsResponse', null, global);
goog.exportSymbol('proto.devWithMazey.client.HitsCount', null, global);
goog.exportSymbol('proto.devWithMazey.client.HitsGrouped', null, global);
goog.exportSymbol('proto.devWithMazey.client.Permission', null, global);
goog.exportSymbol('proto.devWithMazey.client.PermissionRequest', null, global);
goog.exportSymbol('proto.devWithMazey.client.SearchPermissionResponse', null, global);
goog.exportSymbol('proto.devWithMazey.client.User', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.client.GeneralRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devWithMazey.client.GeneralRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.client.GeneralRequest.displayName = 'proto.devWithMazey.client.GeneralRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.client.GeneralResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devWithMazey.client.GeneralResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.client.GeneralResponse.displayName = 'proto.devWithMazey.client.GeneralResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.client.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devWithMazey.client.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.client.User.displayName = 'proto.devWithMazey.client.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.client.Permission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devWithMazey.client.Permission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.client.Permission.displayName = 'proto.devWithMazey.client.Permission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.client.Client = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.devWithMazey.client.Client.repeatedFields_, null);
};
goog.inherits(proto.devWithMazey.client.Client, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.client.Client.displayName = 'proto.devWithMazey.client.Client';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.client.ClientId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devWithMazey.client.ClientId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.client.ClientId.displayName = 'proto.devWithMazey.client.ClientId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.client.PermissionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.devWithMazey.client.PermissionRequest.repeatedFields_, null);
};
goog.inherits(proto.devWithMazey.client.PermissionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.client.PermissionRequest.displayName = 'proto.devWithMazey.client.PermissionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.client.SearchPermissionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.devWithMazey.client.SearchPermissionResponse.repeatedFields_, null);
};
goog.inherits(proto.devWithMazey.client.SearchPermissionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.client.SearchPermissionResponse.displayName = 'proto.devWithMazey.client.SearchPermissionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.client.HitsCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devWithMazey.client.HitsCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.client.HitsCount.displayName = 'proto.devWithMazey.client.HitsCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.client.HitsGrouped = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.devWithMazey.client.HitsGrouped.repeatedFields_, null);
};
goog.inherits(proto.devWithMazey.client.HitsGrouped, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.client.HitsGrouped.displayName = 'proto.devWithMazey.client.HitsGrouped';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.client.GetUsageMetricsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devWithMazey.client.GetUsageMetricsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.client.GetUsageMetricsResponse.displayName = 'proto.devWithMazey.client.GetUsageMetricsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.client.Activity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.devWithMazey.client.Activity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.client.Activity.displayName = 'proto.devWithMazey.client.Activity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.devWithMazey.client.GetActivitiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.devWithMazey.client.GetActivitiesResponse.repeatedFields_, null);
};
goog.inherits(proto.devWithMazey.client.GetActivitiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.devWithMazey.client.GetActivitiesResponse.displayName = 'proto.devWithMazey.client.GetActivitiesResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.client.GeneralRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.client.GeneralRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.client.GeneralRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.GeneralRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    request: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.client.GeneralRequest}
 */
proto.devWithMazey.client.GeneralRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.client.GeneralRequest;
  return proto.devWithMazey.client.GeneralRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.client.GeneralRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.client.GeneralRequest}
 */
proto.devWithMazey.client.GeneralRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.client.GeneralRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.client.GeneralRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.client.GeneralRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.GeneralRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequest();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string request = 1;
 * @return {string}
 */
proto.devWithMazey.client.GeneralRequest.prototype.getRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.GeneralRequest} returns this
 */
proto.devWithMazey.client.GeneralRequest.prototype.setRequest = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.client.GeneralResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.client.GeneralResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.client.GeneralResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.GeneralResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    response: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.client.GeneralResponse}
 */
proto.devWithMazey.client.GeneralResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.client.GeneralResponse;
  return proto.devWithMazey.client.GeneralResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.client.GeneralResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.client.GeneralResponse}
 */
proto.devWithMazey.client.GeneralResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.client.GeneralResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.client.GeneralResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.client.GeneralResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.GeneralResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponse();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string response = 1;
 * @return {string}
 */
proto.devWithMazey.client.GeneralResponse.prototype.getResponse = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.GeneralResponse} returns this
 */
proto.devWithMazey.client.GeneralResponse.prototype.setResponse = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.client.User.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.client.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.client.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.client.User}
 */
proto.devWithMazey.client.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.client.User;
  return proto.devWithMazey.client.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.client.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.client.User}
 */
proto.devWithMazey.client.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.client.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.client.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.client.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 userId = 1;
 * @return {number}
 */
proto.devWithMazey.client.User.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.devWithMazey.client.User} returns this
 */
proto.devWithMazey.client.User.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string firstName = 2;
 * @return {string}
 */
proto.devWithMazey.client.User.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.User} returns this
 */
proto.devWithMazey.client.User.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.devWithMazey.client.User.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.User} returns this
 */
proto.devWithMazey.client.User.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.client.Permission.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.client.Permission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.client.Permission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.Permission.toObject = function(includeInstance, msg) {
  var f, obj = {
    permname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    permdesc: jspb.Message.getFieldWithDefault(msg, 2, ""),
    permid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.client.Permission}
 */
proto.devWithMazey.client.Permission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.client.Permission;
  return proto.devWithMazey.client.Permission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.client.Permission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.client.Permission}
 */
proto.devWithMazey.client.Permission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPermname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPermdesc(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPermid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.client.Permission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.client.Permission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.client.Permission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.Permission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPermdesc();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPermid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string permName = 1;
 * @return {string}
 */
proto.devWithMazey.client.Permission.prototype.getPermname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.Permission} returns this
 */
proto.devWithMazey.client.Permission.prototype.setPermname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string permDesc = 2;
 * @return {string}
 */
proto.devWithMazey.client.Permission.prototype.getPermdesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.Permission} returns this
 */
proto.devWithMazey.client.Permission.prototype.setPermdesc = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 permId = 3;
 * @return {number}
 */
proto.devWithMazey.client.Permission.prototype.getPermid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.devWithMazey.client.Permission} returns this
 */
proto.devWithMazey.client.Permission.prototype.setPermid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.devWithMazey.client.Client.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.client.Client.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.client.Client.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.client.Client} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.Client.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orgid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    orgname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdon: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lastupdatedon: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, ""),
    creator: (f = msg.getCreator()) && proto.devWithMazey.client.User.toObject(includeInstance, f),
    myverified: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    logo: jspb.Message.getFieldWithDefault(msg, 10, ""),
    redirecturi: jspb.Message.getFieldWithDefault(msg, 11, ""),
    privacypolicy: jspb.Message.getFieldWithDefault(msg, 12, ""),
    terms: jspb.Message.getFieldWithDefault(msg, 13, ""),
    permsList: jspb.Message.toObjectList(msg.getPermsList(),
    proto.devWithMazey.client.Permission.toObject, includeInstance),
    isproductionrequested: jspb.Message.getBooleanFieldWithDefault(msg, 15, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.client.Client}
 */
proto.devWithMazey.client.Client.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.client.Client;
  return proto.devWithMazey.client.Client.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.client.Client} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.client.Client}
 */
proto.devWithMazey.client.Client.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientname(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrgid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedon(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdatedon(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 8:
      var value = new proto.devWithMazey.client.User;
      reader.readMessage(value,proto.devWithMazey.client.User.deserializeBinaryFromReader);
      msg.setCreator(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMyverified(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirecturi(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivacypolicy(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerms(value);
      break;
    case 14:
      var value = new proto.devWithMazey.client.Permission;
      reader.readMessage(value,proto.devWithMazey.client.Permission.deserializeBinaryFromReader);
      msg.addPerms(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsproductionrequested(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.client.Client.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.client.Client.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.client.Client} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.Client.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrgid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOrgname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedon();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLastupdatedon();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreator();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.devWithMazey.client.User.serializeBinaryToWriter
    );
  }
  f = message.getMyverified();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRedirecturi();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPrivacypolicy();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTerms();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPermsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.devWithMazey.client.Permission.serializeBinaryToWriter
    );
  }
  f = message.getIsproductionrequested();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
};


/**
 * optional string clientId = 1;
 * @return {string}
 */
proto.devWithMazey.client.Client.prototype.getClientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.Client} returns this
 */
proto.devWithMazey.client.Client.prototype.setClientid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string clientName = 2;
 * @return {string}
 */
proto.devWithMazey.client.Client.prototype.getClientname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.Client} returns this
 */
proto.devWithMazey.client.Client.prototype.setClientname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 orgId = 3;
 * @return {number}
 */
proto.devWithMazey.client.Client.prototype.getOrgid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.devWithMazey.client.Client} returns this
 */
proto.devWithMazey.client.Client.prototype.setOrgid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string orgName = 4;
 * @return {string}
 */
proto.devWithMazey.client.Client.prototype.getOrgname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.Client} returns this
 */
proto.devWithMazey.client.Client.prototype.setOrgname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string createdOn = 5;
 * @return {string}
 */
proto.devWithMazey.client.Client.prototype.getCreatedon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.Client} returns this
 */
proto.devWithMazey.client.Client.prototype.setCreatedon = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string lastUpdatedOn = 6;
 * @return {string}
 */
proto.devWithMazey.client.Client.prototype.getLastupdatedon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.Client} returns this
 */
proto.devWithMazey.client.Client.prototype.setLastupdatedon = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string status = 7;
 * @return {string}
 */
proto.devWithMazey.client.Client.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.Client} returns this
 */
proto.devWithMazey.client.Client.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional User creator = 8;
 * @return {?proto.devWithMazey.client.User}
 */
proto.devWithMazey.client.Client.prototype.getCreator = function() {
  return /** @type{?proto.devWithMazey.client.User} */ (
    jspb.Message.getWrapperField(this, proto.devWithMazey.client.User, 8));
};


/**
 * @param {?proto.devWithMazey.client.User|undefined} value
 * @return {!proto.devWithMazey.client.Client} returns this
*/
proto.devWithMazey.client.Client.prototype.setCreator = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devWithMazey.client.Client} returns this
 */
proto.devWithMazey.client.Client.prototype.clearCreator = function() {
  return this.setCreator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devWithMazey.client.Client.prototype.hasCreator = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool myVerified = 9;
 * @return {boolean}
 */
proto.devWithMazey.client.Client.prototype.getMyverified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.devWithMazey.client.Client} returns this
 */
proto.devWithMazey.client.Client.prototype.setMyverified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string logo = 10;
 * @return {string}
 */
proto.devWithMazey.client.Client.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.Client} returns this
 */
proto.devWithMazey.client.Client.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string redirectURI = 11;
 * @return {string}
 */
proto.devWithMazey.client.Client.prototype.getRedirecturi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.Client} returns this
 */
proto.devWithMazey.client.Client.prototype.setRedirecturi = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string privacyPolicy = 12;
 * @return {string}
 */
proto.devWithMazey.client.Client.prototype.getPrivacypolicy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.Client} returns this
 */
proto.devWithMazey.client.Client.prototype.setPrivacypolicy = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string terms = 13;
 * @return {string}
 */
proto.devWithMazey.client.Client.prototype.getTerms = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.Client} returns this
 */
proto.devWithMazey.client.Client.prototype.setTerms = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated Permission perms = 14;
 * @return {!Array<!proto.devWithMazey.client.Permission>}
 */
proto.devWithMazey.client.Client.prototype.getPermsList = function() {
  return /** @type{!Array<!proto.devWithMazey.client.Permission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.devWithMazey.client.Permission, 14));
};


/**
 * @param {!Array<!proto.devWithMazey.client.Permission>} value
 * @return {!proto.devWithMazey.client.Client} returns this
*/
proto.devWithMazey.client.Client.prototype.setPermsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.devWithMazey.client.Permission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.devWithMazey.client.Permission}
 */
proto.devWithMazey.client.Client.prototype.addPerms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.devWithMazey.client.Permission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devWithMazey.client.Client} returns this
 */
proto.devWithMazey.client.Client.prototype.clearPermsList = function() {
  return this.setPermsList([]);
};


/**
 * optional bool isProductionRequested = 15;
 * @return {boolean}
 */
proto.devWithMazey.client.Client.prototype.getIsproductionrequested = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.devWithMazey.client.Client} returns this
 */
proto.devWithMazey.client.Client.prototype.setIsproductionrequested = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.client.ClientId.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.client.ClientId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.client.ClientId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.ClientId.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orgid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.client.ClientId}
 */
proto.devWithMazey.client.ClientId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.client.ClientId;
  return proto.devWithMazey.client.ClientId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.client.ClientId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.client.ClientId}
 */
proto.devWithMazey.client.ClientId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrgid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.client.ClientId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.client.ClientId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.client.ClientId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.ClientId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrgid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string clientId = 1;
 * @return {string}
 */
proto.devWithMazey.client.ClientId.prototype.getClientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.ClientId} returns this
 */
proto.devWithMazey.client.ClientId.prototype.setClientid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 orgId = 2;
 * @return {number}
 */
proto.devWithMazey.client.ClientId.prototype.getOrgid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.devWithMazey.client.ClientId} returns this
 */
proto.devWithMazey.client.ClientId.prototype.setOrgid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.devWithMazey.client.PermissionRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.client.PermissionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.client.PermissionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.client.PermissionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.PermissionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orgid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    permission: (f = msg.getPermission()) && proto.devWithMazey.client.Permission.toObject(includeInstance, f),
    parameterList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.client.PermissionRequest}
 */
proto.devWithMazey.client.PermissionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.client.PermissionRequest;
  return proto.devWithMazey.client.PermissionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.client.PermissionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.client.PermissionRequest}
 */
proto.devWithMazey.client.PermissionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrgid(value);
      break;
    case 3:
      var value = new proto.devWithMazey.client.Permission;
      reader.readMessage(value,proto.devWithMazey.client.Permission.deserializeBinaryFromReader);
      msg.setPermission(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addParameter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.client.PermissionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.client.PermissionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.client.PermissionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.PermissionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrgid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPermission();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.devWithMazey.client.Permission.serializeBinaryToWriter
    );
  }
  f = message.getParameterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string clientId = 1;
 * @return {string}
 */
proto.devWithMazey.client.PermissionRequest.prototype.getClientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.PermissionRequest} returns this
 */
proto.devWithMazey.client.PermissionRequest.prototype.setClientid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 orgId = 2;
 * @return {number}
 */
proto.devWithMazey.client.PermissionRequest.prototype.getOrgid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.devWithMazey.client.PermissionRequest} returns this
 */
proto.devWithMazey.client.PermissionRequest.prototype.setOrgid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Permission permission = 3;
 * @return {?proto.devWithMazey.client.Permission}
 */
proto.devWithMazey.client.PermissionRequest.prototype.getPermission = function() {
  return /** @type{?proto.devWithMazey.client.Permission} */ (
    jspb.Message.getWrapperField(this, proto.devWithMazey.client.Permission, 3));
};


/**
 * @param {?proto.devWithMazey.client.Permission|undefined} value
 * @return {!proto.devWithMazey.client.PermissionRequest} returns this
*/
proto.devWithMazey.client.PermissionRequest.prototype.setPermission = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devWithMazey.client.PermissionRequest} returns this
 */
proto.devWithMazey.client.PermissionRequest.prototype.clearPermission = function() {
  return this.setPermission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devWithMazey.client.PermissionRequest.prototype.hasPermission = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string parameter = 4;
 * @return {!Array<string>}
 */
proto.devWithMazey.client.PermissionRequest.prototype.getParameterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.devWithMazey.client.PermissionRequest} returns this
 */
proto.devWithMazey.client.PermissionRequest.prototype.setParameterList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.devWithMazey.client.PermissionRequest} returns this
 */
proto.devWithMazey.client.PermissionRequest.prototype.addParameter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devWithMazey.client.PermissionRequest} returns this
 */
proto.devWithMazey.client.PermissionRequest.prototype.clearParameterList = function() {
  return this.setParameterList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.devWithMazey.client.SearchPermissionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.client.SearchPermissionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.client.SearchPermissionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.client.SearchPermissionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.SearchPermissionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    permissionList: jspb.Message.toObjectList(msg.getPermissionList(),
    proto.devWithMazey.client.Permission.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.client.SearchPermissionResponse}
 */
proto.devWithMazey.client.SearchPermissionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.client.SearchPermissionResponse;
  return proto.devWithMazey.client.SearchPermissionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.client.SearchPermissionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.client.SearchPermissionResponse}
 */
proto.devWithMazey.client.SearchPermissionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.devWithMazey.client.Permission;
      reader.readMessage(value,proto.devWithMazey.client.Permission.deserializeBinaryFromReader);
      msg.addPermission(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.client.SearchPermissionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.client.SearchPermissionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.client.SearchPermissionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.SearchPermissionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermissionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.devWithMazey.client.Permission.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Permission permission = 1;
 * @return {!Array<!proto.devWithMazey.client.Permission>}
 */
proto.devWithMazey.client.SearchPermissionResponse.prototype.getPermissionList = function() {
  return /** @type{!Array<!proto.devWithMazey.client.Permission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.devWithMazey.client.Permission, 1));
};


/**
 * @param {!Array<!proto.devWithMazey.client.Permission>} value
 * @return {!proto.devWithMazey.client.SearchPermissionResponse} returns this
*/
proto.devWithMazey.client.SearchPermissionResponse.prototype.setPermissionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.devWithMazey.client.Permission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.devWithMazey.client.Permission}
 */
proto.devWithMazey.client.SearchPermissionResponse.prototype.addPermission = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.devWithMazey.client.Permission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devWithMazey.client.SearchPermissionResponse} returns this
 */
proto.devWithMazey.client.SearchPermissionResponse.prototype.clearPermissionList = function() {
  return this.setPermissionList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.client.HitsCount.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.client.HitsCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.client.HitsCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.HitsCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    endpoint: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hits: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.client.HitsCount}
 */
proto.devWithMazey.client.HitsCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.client.HitsCount;
  return proto.devWithMazey.client.HitsCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.client.HitsCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.client.HitsCount}
 */
proto.devWithMazey.client.HitsCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndpoint(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.client.HitsCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.client.HitsCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.client.HitsCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.HitsCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEndpoint();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHits();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string endpoint = 1;
 * @return {string}
 */
proto.devWithMazey.client.HitsCount.prototype.getEndpoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.HitsCount} returns this
 */
proto.devWithMazey.client.HitsCount.prototype.setEndpoint = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 hits = 2;
 * @return {number}
 */
proto.devWithMazey.client.HitsCount.prototype.getHits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.devWithMazey.client.HitsCount} returns this
 */
proto.devWithMazey.client.HitsCount.prototype.setHits = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.devWithMazey.client.HitsGrouped.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.client.HitsGrouped.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.client.HitsGrouped.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.client.HitsGrouped} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.HitsGrouped.toObject = function(includeInstance, msg) {
  var f, obj = {
    hitscountsList: jspb.Message.toObjectList(msg.getHitscountsList(),
    proto.devWithMazey.client.HitsCount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.client.HitsGrouped}
 */
proto.devWithMazey.client.HitsGrouped.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.client.HitsGrouped;
  return proto.devWithMazey.client.HitsGrouped.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.client.HitsGrouped} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.client.HitsGrouped}
 */
proto.devWithMazey.client.HitsGrouped.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.devWithMazey.client.HitsCount;
      reader.readMessage(value,proto.devWithMazey.client.HitsCount.deserializeBinaryFromReader);
      msg.addHitscounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.client.HitsGrouped.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.client.HitsGrouped.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.client.HitsGrouped} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.HitsGrouped.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHitscountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.devWithMazey.client.HitsCount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated HitsCount hitsCounts = 1;
 * @return {!Array<!proto.devWithMazey.client.HitsCount>}
 */
proto.devWithMazey.client.HitsGrouped.prototype.getHitscountsList = function() {
  return /** @type{!Array<!proto.devWithMazey.client.HitsCount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.devWithMazey.client.HitsCount, 1));
};


/**
 * @param {!Array<!proto.devWithMazey.client.HitsCount>} value
 * @return {!proto.devWithMazey.client.HitsGrouped} returns this
*/
proto.devWithMazey.client.HitsGrouped.prototype.setHitscountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.devWithMazey.client.HitsCount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.devWithMazey.client.HitsCount}
 */
proto.devWithMazey.client.HitsGrouped.prototype.addHitscounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.devWithMazey.client.HitsCount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devWithMazey.client.HitsGrouped} returns this
 */
proto.devWithMazey.client.HitsGrouped.prototype.clearHitscountsList = function() {
  return this.setHitscountsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.client.GetUsageMetricsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.client.GetUsageMetricsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.client.GetUsageMetricsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.GetUsageMetricsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hitstoday: (f = msg.getHitstoday()) && proto.devWithMazey.client.HitsGrouped.toObject(includeInstance, f),
    hitsoverall: (f = msg.getHitsoverall()) && proto.devWithMazey.client.HitsGrouped.toObject(includeInstance, f),
    tokenrequests: (f = msg.getTokenrequests()) && proto.devWithMazey.client.HitsGrouped.toObject(includeInstance, f),
    accessestotal: jspb.Message.getFieldWithDefault(msg, 5, ""),
    accessesinuse: jspb.Message.getFieldWithDefault(msg, 6, ""),
    accessesblocked: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.client.GetUsageMetricsResponse}
 */
proto.devWithMazey.client.GetUsageMetricsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.client.GetUsageMetricsResponse;
  return proto.devWithMazey.client.GetUsageMetricsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.client.GetUsageMetricsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.client.GetUsageMetricsResponse}
 */
proto.devWithMazey.client.GetUsageMetricsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClientid(value);
      break;
    case 2:
      var value = new proto.devWithMazey.client.HitsGrouped;
      reader.readMessage(value,proto.devWithMazey.client.HitsGrouped.deserializeBinaryFromReader);
      msg.setHitstoday(value);
      break;
    case 3:
      var value = new proto.devWithMazey.client.HitsGrouped;
      reader.readMessage(value,proto.devWithMazey.client.HitsGrouped.deserializeBinaryFromReader);
      msg.setHitsoverall(value);
      break;
    case 4:
      var value = new proto.devWithMazey.client.HitsGrouped;
      reader.readMessage(value,proto.devWithMazey.client.HitsGrouped.deserializeBinaryFromReader);
      msg.setTokenrequests(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessestotal(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessesinuse(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessesblocked(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.client.GetUsageMetricsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.client.GetUsageMetricsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.client.GetUsageMetricsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.GetUsageMetricsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getHitstoday();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.devWithMazey.client.HitsGrouped.serializeBinaryToWriter
    );
  }
  f = message.getHitsoverall();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.devWithMazey.client.HitsGrouped.serializeBinaryToWriter
    );
  }
  f = message.getTokenrequests();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.devWithMazey.client.HitsGrouped.serializeBinaryToWriter
    );
  }
  f = message.getAccessestotal();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAccessesinuse();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAccessesblocked();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 clientId = 1;
 * @return {number}
 */
proto.devWithMazey.client.GetUsageMetricsResponse.prototype.getClientid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.devWithMazey.client.GetUsageMetricsResponse} returns this
 */
proto.devWithMazey.client.GetUsageMetricsResponse.prototype.setClientid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional HitsGrouped hitsToday = 2;
 * @return {?proto.devWithMazey.client.HitsGrouped}
 */
proto.devWithMazey.client.GetUsageMetricsResponse.prototype.getHitstoday = function() {
  return /** @type{?proto.devWithMazey.client.HitsGrouped} */ (
    jspb.Message.getWrapperField(this, proto.devWithMazey.client.HitsGrouped, 2));
};


/**
 * @param {?proto.devWithMazey.client.HitsGrouped|undefined} value
 * @return {!proto.devWithMazey.client.GetUsageMetricsResponse} returns this
*/
proto.devWithMazey.client.GetUsageMetricsResponse.prototype.setHitstoday = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devWithMazey.client.GetUsageMetricsResponse} returns this
 */
proto.devWithMazey.client.GetUsageMetricsResponse.prototype.clearHitstoday = function() {
  return this.setHitstoday(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devWithMazey.client.GetUsageMetricsResponse.prototype.hasHitstoday = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional HitsGrouped hitsOverall = 3;
 * @return {?proto.devWithMazey.client.HitsGrouped}
 */
proto.devWithMazey.client.GetUsageMetricsResponse.prototype.getHitsoverall = function() {
  return /** @type{?proto.devWithMazey.client.HitsGrouped} */ (
    jspb.Message.getWrapperField(this, proto.devWithMazey.client.HitsGrouped, 3));
};


/**
 * @param {?proto.devWithMazey.client.HitsGrouped|undefined} value
 * @return {!proto.devWithMazey.client.GetUsageMetricsResponse} returns this
*/
proto.devWithMazey.client.GetUsageMetricsResponse.prototype.setHitsoverall = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devWithMazey.client.GetUsageMetricsResponse} returns this
 */
proto.devWithMazey.client.GetUsageMetricsResponse.prototype.clearHitsoverall = function() {
  return this.setHitsoverall(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devWithMazey.client.GetUsageMetricsResponse.prototype.hasHitsoverall = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional HitsGrouped tokenRequests = 4;
 * @return {?proto.devWithMazey.client.HitsGrouped}
 */
proto.devWithMazey.client.GetUsageMetricsResponse.prototype.getTokenrequests = function() {
  return /** @type{?proto.devWithMazey.client.HitsGrouped} */ (
    jspb.Message.getWrapperField(this, proto.devWithMazey.client.HitsGrouped, 4));
};


/**
 * @param {?proto.devWithMazey.client.HitsGrouped|undefined} value
 * @return {!proto.devWithMazey.client.GetUsageMetricsResponse} returns this
*/
proto.devWithMazey.client.GetUsageMetricsResponse.prototype.setTokenrequests = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.devWithMazey.client.GetUsageMetricsResponse} returns this
 */
proto.devWithMazey.client.GetUsageMetricsResponse.prototype.clearTokenrequests = function() {
  return this.setTokenrequests(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.devWithMazey.client.GetUsageMetricsResponse.prototype.hasTokenrequests = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string accessesTotal = 5;
 * @return {string}
 */
proto.devWithMazey.client.GetUsageMetricsResponse.prototype.getAccessestotal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.GetUsageMetricsResponse} returns this
 */
proto.devWithMazey.client.GetUsageMetricsResponse.prototype.setAccessestotal = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string accessesInUse = 6;
 * @return {string}
 */
proto.devWithMazey.client.GetUsageMetricsResponse.prototype.getAccessesinuse = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.GetUsageMetricsResponse} returns this
 */
proto.devWithMazey.client.GetUsageMetricsResponse.prototype.setAccessesinuse = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string accessesBlocked = 7;
 * @return {string}
 */
proto.devWithMazey.client.GetUsageMetricsResponse.prototype.getAccessesblocked = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.GetUsageMetricsResponse} returns this
 */
proto.devWithMazey.client.GetUsageMetricsResponse.prototype.setAccessesblocked = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.client.Activity.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.client.Activity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.client.Activity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.Activity.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activitytype: jspb.Message.getFieldWithDefault(msg, 3, ""),
    clientid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    author: jspb.Message.getFieldWithDefault(msg, 5, ""),
    statement: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.client.Activity}
 */
proto.devWithMazey.client.Activity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.client.Activity;
  return proto.devWithMazey.client.Activity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.client.Activity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.client.Activity}
 */
proto.devWithMazey.client.Activity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrgid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivitytype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthor(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.client.Activity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.client.Activity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.client.Activity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.Activity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActivitytype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getClientid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAuthor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatement();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 orgId = 1;
 * @return {number}
 */
proto.devWithMazey.client.Activity.prototype.getOrgid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.devWithMazey.client.Activity} returns this
 */
proto.devWithMazey.client.Activity.prototype.setOrgid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string timeStamp = 2;
 * @return {string}
 */
proto.devWithMazey.client.Activity.prototype.getTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.Activity} returns this
 */
proto.devWithMazey.client.Activity.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string activityType = 3;
 * @return {string}
 */
proto.devWithMazey.client.Activity.prototype.getActivitytype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.Activity} returns this
 */
proto.devWithMazey.client.Activity.prototype.setActivitytype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string clientId = 4;
 * @return {string}
 */
proto.devWithMazey.client.Activity.prototype.getClientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.Activity} returns this
 */
proto.devWithMazey.client.Activity.prototype.setClientid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string author = 5;
 * @return {string}
 */
proto.devWithMazey.client.Activity.prototype.getAuthor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.Activity} returns this
 */
proto.devWithMazey.client.Activity.prototype.setAuthor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string statement = 6;
 * @return {string}
 */
proto.devWithMazey.client.Activity.prototype.getStatement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.Activity} returns this
 */
proto.devWithMazey.client.Activity.prototype.setStatement = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.devWithMazey.client.GetActivitiesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.devWithMazey.client.GetActivitiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.devWithMazey.client.GetActivitiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.devWithMazey.client.GetActivitiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.GetActivitiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    activitiesList: jspb.Message.toObjectList(msg.getActivitiesList(),
    proto.devWithMazey.client.Activity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.devWithMazey.client.GetActivitiesResponse}
 */
proto.devWithMazey.client.GetActivitiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.devWithMazey.client.GetActivitiesResponse;
  return proto.devWithMazey.client.GetActivitiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.devWithMazey.client.GetActivitiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.devWithMazey.client.GetActivitiesResponse}
 */
proto.devWithMazey.client.GetActivitiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientid(value);
      break;
    case 2:
      var value = new proto.devWithMazey.client.Activity;
      reader.readMessage(value,proto.devWithMazey.client.Activity.deserializeBinaryFromReader);
      msg.addActivities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.devWithMazey.client.GetActivitiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.devWithMazey.client.GetActivitiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.devWithMazey.client.GetActivitiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.devWithMazey.client.GetActivitiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActivitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.devWithMazey.client.Activity.serializeBinaryToWriter
    );
  }
};


/**
 * optional string clientId = 1;
 * @return {string}
 */
proto.devWithMazey.client.GetActivitiesResponse.prototype.getClientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.devWithMazey.client.GetActivitiesResponse} returns this
 */
proto.devWithMazey.client.GetActivitiesResponse.prototype.setClientid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Activity activities = 2;
 * @return {!Array<!proto.devWithMazey.client.Activity>}
 */
proto.devWithMazey.client.GetActivitiesResponse.prototype.getActivitiesList = function() {
  return /** @type{!Array<!proto.devWithMazey.client.Activity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.devWithMazey.client.Activity, 2));
};


/**
 * @param {!Array<!proto.devWithMazey.client.Activity>} value
 * @return {!proto.devWithMazey.client.GetActivitiesResponse} returns this
*/
proto.devWithMazey.client.GetActivitiesResponse.prototype.setActivitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.devWithMazey.client.Activity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.devWithMazey.client.Activity}
 */
proto.devWithMazey.client.GetActivitiesResponse.prototype.addActivities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.devWithMazey.client.Activity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.devWithMazey.client.GetActivitiesResponse} returns this
 */
proto.devWithMazey.client.GetActivitiesResponse.prototype.clearActivitiesList = function() {
  return this.setActivitiesList([]);
};


goog.object.extend(exports, proto.devWithMazey.client);
