import React from 'react'
import { Box, Typography } from '@mui/material'

export default function SignupSuccess() {

    return <Box
        style={{ width: '100%', maxWidth: 500 }}
    >
        <Typography variant="body2">
            Open the link sent on your email address to confirm your email address.
        </Typography>
    </Box>
}
