import React from 'react'
import { Drawer } from '@mui/material'

export default function Sidebar(props) {
    return <Drawer
        variant="permanent"
        anchor="left"
        sx={{
            width: { xs: 0, md: 240 },
            display: { xs: 'none', md: 'block' },
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: 240,
                boxSizing: 'border-box',
                backgroundColor: 'background.main',
            },
        }}
    >
        {props.sidebarContent}
    </Drawer>
}
