import React from 'react'
import { Typography, Grid } from '@mui/material'
import { Link, useParams } from 'react-router-dom'

export default function AppsCount(props) {
    const { orgId } = useParams()

    return <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%', width: 250, marginRight: 5 }}
    >
        <Typography variant="body1" gutterBottom>
            Total Clients
        </Typography>
        <Typography variant="h3" color="primary" >
            <Link to={"/dashboard/" + orgId + '/clients'} style={{ textDecoration: 'none', color: 'inherit' }}>
                {props.orgInfo.clientsList.length}
            </Link>
        </Typography>
    </Grid>
}
