import React from 'react'
import { Card, Grid, Divider } from '@mui/material'
import AppsCount from './appsCount'
import AppsBreakdown from './appsBreakdown'

export default function Info(props) {

    return <Card variant="outlined" style={{ padding: 10, width: '100%', textAlign: 'left', marginBottom: 20 }}>
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            style={{ height: '100%', width: '100%' }}
        >
            <AppsCount
                orgInfo={props.orgInfo}
            />
            <Divider orientation="vertical" light />
            <AppsBreakdown
                orgInfo={props.orgInfo}
            />
            <Divider orientation="vertical" light />
        </Grid>
    </Card>
}
