import React from 'react'
import { Grid, Button } from '@mui/material'
import { useNavigate } from 'react-router'
import useQuery from '../../customHooks/generalHooks/useQuery'

export default function HelperButtons() {
    const navigate = useNavigate()
    const query = useQuery()
    const redirectUrl = query.get("redirect")

    const buttons = [
        {
            title: 'Login',
            clickHandler: () => {
                if (redirectUrl === undefined || redirectUrl === null || redirectUrl === "") {
                    navigate('/auth/login')
                } else {
                    navigate('/auth/login?redirect=' + redirectUrl)
                }
            }
        },
        {
            title: 'Reset Password',
            clickHandler: () => navigate('/auth/resetPassword')
        }
    ]

    return <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
    >
        {
            buttons.map((btn) => {
                return <Button
                    style={{ textTransform: 'none' }}
                    onClick={btn.clickHandler}
                    key={btn.title}
                >
                    {btn.title}
                </Button>
            })
        }
    </Grid>
}
