import React from 'react'
import { Grid, Typography, Button, CircularProgress } from '@mui/material'
import { useNavigate, useParams } from 'react-router'
import { ArrowBack, } from '@mui/icons-material'
import NewAppContainer from './newApp'
import useOrgData from '../customHooks/generalHooks/useOrgData'

export default function NewApp() {

    const { orgId } = useParams()
    const [loadStat, orgData] = useOrgData(orgId)
    const navigate = useNavigate()

    const returnInCenter = (elem) => {
        return <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ height: '80%', width: '100%' }}
        >
            {elem}
        </Grid>
    }

    const render = () => {
        if (loadStat === 2) {
            return returnInCenter(<CircularProgress size={25} />)
        } else if (loadStat === 0) {
            return <>
                <Typography variant="h6" gutterBottom
                    style={{ marginTop: 10 }}
                >
                    Register New Client
                </Typography>
                <NewAppContainer
                    orgData={orgData}
                />
            </>
        } else if (loadStat === -1) {
            return returnInCenter(
                <Typography variant="body1" color="error">
                    Something went wrong.
                </Typography>
            )
        }
    }

    return <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ height: '100%', width: '100%', paddingTop: 20 }}
    >
        <div style={{ width: '100%' }}>
            <Button
                startIcon={<ArrowBack />}
                sx={{ color: 'text.secondary', textTransform: 'none' }}
                onClick={() => navigate('/dashboard/' + orgId + '/clients')}
            >
                Back
            </Button>
        </div>
        {render()}
    </Grid>
}
