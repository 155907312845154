import React from 'react'
import { Card, Typography, Grid } from '@mui/material'
import { useNavigate } from 'react-router'

export default function AppsListCard(props) {
    const navigate = useNavigate()

    const renderList = () => {
        if (props.list.length === 0) {
            return <Grid item  >
                <Typography variant="body1" >
                    No Clients Found
                </Typography>
            </Grid>
        } else {
            return <>
                {
                    props.list.map((item) => {
                        return <div
                            style={{ width: '250px', height: '250px', marginRight: '15px', marginBottom: '20px', cursor: 'pointer' }}
                            onClick={() => navigate('/dashboard/' + props.orgId + '/clients/' + item.clientid)}
                            key={item.clientid}
                        >
                            <img src={item.logo} style={{ width: '250px', height: '200px', margin: 0, objectFit: 'cover' }} />
                            <Typography
                                variant="body1"
                                sx={{ paddingTop: '10px', paddingBottom: '10px', marginTop: '-7px', backgroundColor: 'text.secondary', color: 'background.paper', width: '100%' }} align="center"
                            >
                                {item.name}
                            </Typography>
                        </div>
                    })
                }
            </>
        }
    }

    return <Card variant="outlined" style={{ padding: 10, width: '100%', textAlign: 'left', marginBottom: 20 }} >
        <Typography variant="h6" color="textPrimary" gutterBottom>
            {props.type} ({props.list.length})
        </Typography>
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2} style={{ padding: 20, paddingBottom: 20 }}
        >
            {renderList()}
        </Grid>
    </Card >
}
