import React, { useState } from 'react'
import { TextField, Typography, Box, Paper, CircularProgress } from '@mui/material'
import useSearchPerms from '../../customHooks/generalHooks/useSearchPerms'

export default function PermsSearchBox(props) {

    const [permQuery, setPermQuery] = useState('')

    const [stat, permResponse] = useSearchPerms(permQuery)

    const renderResult = () => {
        if (permQuery.length < 4) {
            return <Typography variant="body2" color="textSecondary">
                Start Typing
            </Typography>
        } else {
            if (stat === 2) {
                return <CircularProgress size={25} />
            } else if (stat === -1) {
                return <Typography variant="body2" color="error">
                    Something went wrong
                </Typography>
            } else if (permResponse.length === 0) {
                return <Typography variant="body2">
                    No Result
                </Typography>
            } else {
                return permResponse.map((perm, index) => {
                    return <Box
                        sx={{ borderBottom: (index === permResponse.length - 1) ? 'none' : '1px solid', borderColor: 'divider', padding: '5px 0', cursor: 'pointer' }}
                        key={perm.permid}
                        onClick={() => {
                            props.setView('permAdd')
                            props.setPermToAdd(perm)
                        }}
                    >
                        <Typography variant="subtitle1" align="left">
                            {perm.permname}
                        </Typography>
                        <Typography variant="subtitle2" align="left"
                            style={{ margin: '0 0 5px 5px' }}
                        >
                            - {perm.permid}
                        </Typography>
                    </Box>
                })
            }
        }
    }

    return <>
        <TextField
            id="perm-search-box"
            label="Search Permission"
            variant="outlined"
            fullWidth
            value={permQuery}
            onChange={(event) => setPermQuery(event.target.value)}
        />
        <Paper
            elevation={0}
            variant="outlined"
            style={{ width: '100%', textAlign: 'center', margin: '30px 0 0 0', padding: '10px 20px' }}
        >
            {renderResult()}
        </Paper>
    </>
}