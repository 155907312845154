import { useState, useEffect } from 'react'
import GetMetadata from '../../components/getMetadata'
import { ClientServerAddress } from '../../protos/server'
import { ClientServiceClient } from '../../protos/client_grpc_web_pb'
import { ClientId } from '../../protos/client_pb'
import { useDispatch, useSelector } from 'react-redux'


// setMetrics({
//     endpointHits: {
//         hitsOverall: [
//             {
//                 endpoint: 'Mazey.Auth.AuthService/Login',
//                 hitsCount: 1879,
//             },
//             {
//                 endpoint: 'Mazey.Auth.AuthService/Signup',
//                 hitsCount: 1659,
//             },
//             {
//                 endpoint: 'Mazey.Classroom.ClassroomService/Create',
//                 hitsCount: 321,
//             },
//         ],
//         hitsThisMonth: [
//             {
//                 endpoint: 'Mazey.Auth.AuthService/Signup',
//                 hitsCount: 2349,
//             },
//             {
//                 endpoint: 'Mazey.Auth.AuthService/Login',
//                 hitsCount: 2168,
//             },
//             {
//                 endpoint: 'Mazey.Classroom.ClassroomService/Create',
//                 hitsCount: 532,
//             },
//         ],
//     },
//     hitsData: [
//         {
//             title: 'New Token Requests',
//             data: [
//                 {
//                     name: 'Sept 24',
//                     requests: 4000,
//                 },
//                 {
//                     name: 'Sept 25',
//                     requests: 3000,
//                 },
//                 {
//                     name: 'Sept 26',
//                     requests: 2000,
//                 },
//                 {
//                     name: 'Sept 27',
//                     requests: 2780,
//                 },
//                 {
//                     name: 'Sept 28',
//                     requests: 1890,
//                 },
//                 {
//                     name: 'Sept 29',
//                     requests: 2390,
//                 },
//             ]
//         },
//         {
//             title: 'Refresh Token Requests',
//             data: [
//                 {
//                     name: 'Sept 24',
//                     requests: 400,
//                 },
//                 {
//                     name: 'Sept 25',
//                     requests: 2000,
//                 },
//                 {
//                     name: 'Sept 26',
//                     requests: 1000,
//                 },
//                 {
//                     name: 'Sept 27',
//                     requests: 1780,
//                 },
//                 {
//                     name: 'Sept 28',
//                     requests: 2890,
//                 },
//                 {
//                     name: 'Sept 29',
//                     requests: 1390,
//                 },
//             ]
//         },
//     ]
// })

export default function useAppMetrics(clientId, orgId) {
    const [stat, setStat] = useState(2)
    const [metrics, setMetrics] = useState({})
    const userInfo = useSelector((state) => state.userInfo)
    const dispatch = useDispatch()

    const fetchUsageMetrics = () => {
        const md = GetMetadata()
        md.authorization = userInfo.token
        const client = new ClientServiceClient(ClientServerAddress, null, null)

        const req = new ClientId()
        req.setOrgid(orgId)
        req.setClientid(clientId)

        client.getUsageMetrics(req, md, (err, response) => {
            if (err) {
                if (err.message === "token expired" || err.message === "error decoding token" || err.message === "no token" || err.message === "invalid token") {
                    dispatch({ type: 'LOGOUT' })
                    return
                }
                setStat(-1)
            } else {
                const res = response.toObject()
                setMetrics(res)
                setStat(0)
            }
        })
    }

    useEffect(() => {
        fetchUsageMetrics()
    }, [clientId])

    return [stat, metrics]
}