import React from 'react'
import { Typography, Grid } from '@mui/material'
import { Link } from 'react-router-dom'

export default function UserCount(props) {

    return <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%', width: 250, marginRight: 5 }}
    >
        <Typography variant="body1" gutterBottom>
            Total Users
        </Typography>
        <Typography variant="h3" color="primary" >
            <Link to={"/dashboard/" + props.orgInfo.orgid + '/users'} style={{ textDecoration: 'none', color: 'inherit' }}>
                {props.orgInfo.membersList.length}
            </Link>
        </Typography>
    </Grid>
}
