import React, { useState, useEffect } from 'react'
import { Box, MenuItem, FormControl, Select, CircularProgress, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router'
import useOrgs from '../../customHooks/generalHooks/useOrgList'

export default function OrganizationSelector() {
    const { orgId } = useParams()
    const [org, setOrg] = useState('')
    const navigate = useNavigate()
    const [stat, orgs] = useOrgs()

    const handleChange = (event) => {
        setOrg(event.target.value)
        navigate('/dashboard/' + event.target.value)
    }

    useEffect(() => {
        if (orgId === undefined || orgId === 'newOrg') {
            setOrg('no-org')
        } else {
            setOrg(orgId)
        }
    }, [orgId])

    const render = () => {
        if (stat === 2) {
            return <CircularProgress size={25} />
        } else if (stat === 0) {
            return <Select
                labelId="organization-select-label"
                id="organization-select"
                value={org}
                onChange={handleChange}
                style={{ fontSize: 13 }}
            >
                <MenuItem value='no-org' style={{ fontSize: 13 }} disabled>
                    Select Organization
                </MenuItem>
                {
                    orgs.map((org) => {
                        return <MenuItem value={org.orgid} style={{ fontSize: 13, background: 'none' }} key={org.orgid} >
                            {org.orgname}
                        </MenuItem>
                    })
                }
            </Select>
        } else {
            return <Typography variant="body2" color="error">
                Something went wrong
            </Typography>
        }
    }

    return (
        <Box sx={{ minWidth: 250 }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
                {render()}
            </FormControl>
        </Box>
    );
}
