import * as React from 'react'
import { Table, TableCell, TableContainer, TableBody, TableRow, TableHead, Paper, Typography } from '@mui/material';
import ActivityRow from './activityRow'

export default function ActivitiesTable(props) {

    const render = () => {
        if (props.activities.length === 0) {
            return <Typography variant="body1">
                No Activities Recorded.
            </Typography>
        }
        return <TableContainer component={Paper}>
            <Table aria-label="activities table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Time</TableCell>
                        <TableCell align="left">Activity Type</TableCell>
                        <TableCell align="left">Author</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.activities.map((row) => (
                            <ActivityRow key={row.timestamp} row={row} />
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    }

    return render()
}
