import React, { useState } from 'react'
import { Button, Typography } from '@mui/material'
import GetMetadata from '../../../components/getMetadata'
import { ClientServerAddress } from '../../../protos/server'
import { ClientServiceClient } from '../../../protos/client_grpc_web_pb'
import { ClientId } from '../../../protos/client_pb'
import { useDispatch, useSelector } from 'react-redux'
import ButtonOnState from '../../../components/buttonOnState'

export default function PushToProd(props) {

    const [btnStat, setBtnStat] = useState(0)
    const [error, setError] = useState("")
    const dispatch = useDispatch()
    const userInfo = useSelector(state => state.userInfo)

    const pushToProd = () => {
        setBtnStat(2)
        const md = GetMetadata()
        md.authorization = userInfo.token
        const client = new ClientServiceClient(ClientServerAddress, null, null)

        const req = new ClientId()
        req.setOrgid(props.appData.orgid)
        req.setClientid(props.appData.clientid)

        client.pushToProd(req, md, (err, response) => {
            if (err) {
                if (err.message === "token expired" || err.message === "error decoding token" || err.message === "no token" || err.message === "invalid token") {
                    dispatch({ type: 'LOGOUT' })
                    return
                }
                setBtnStat(-1)
                setError(err.message)
            } else {
                setBtnStat(1)
            }
        })
    }

    return <>
        <Button
            color="primary"
            style={{ marginTop: '10px', textTransform: 'none' }}
            disabled={btnStat !== 0}
            onClick={pushToProd}
        >
            <ButtonOnState
                loadState={btnStat}
                successText="Push To Production"
            />
        </Button>
        <br />
        <Typography variant="caption" color="error" align="left">
            {error}
        </Typography>
        <br />
        <Typography variant="caption" color="textSecondary" align="left">
            - A request will be created to push your client to production.
            <br />
            - We will verify your request regarding all the details.
            <br />
            - Verification usually takes 2-3 working days.
            <br />
            - We will send updates regarding the status on the email addresses of everyone in the organisation.
            <br />
            - Make sure you have updated all the details to accurate.
            <br />
            <br />
        </Typography>
    </>
}