import React from 'react'
import { useParams } from 'react-router'
import AppsInfo from '../appsInfo'
import EachApp from '../eachApp'
import NewApp from '../newApp'

export default function Apps() {

    const { orgSubOpts } = useParams()

    const render = () => {
        if (orgSubOpts === undefined) {
            return <AppsInfo />
        } else if (orgSubOpts === "new") {
            return <NewApp />
        } else {
            return <EachApp />
        }
    }

    return render()
}