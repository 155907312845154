import React from 'react'
import { Grid, Typography, Divider } from '@mui/material'

export default function AccessesCount(props) {

    return <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        sx={{ border: '1px solid', borderColor: 'divider', borderRadius: '5px', width: '98%', padding: '10px', marginBottom: '20px' }}
    >
        <Typography variant="body2" color="textSecondary">
            Total Accesses - <strong>{props.accessestotal}</strong>
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Typography variant="body2" color="textSecondary">
            Accesses In Use - <strong>{props.accessesinuse}</strong>
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Typography variant="body2" color="textSecondary">
            Blocked Accesses - <strong>{props.accessesblocked}</strong>
        </Typography>
    </Grid>
}