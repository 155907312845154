import React, { useState } from 'react'
import { Box, Typography, TextField, Button, CircularProgress } from '@mui/material'
import GetMetadata from '../../components/getMetadata'
import { AuthServerAddress } from '../../protos/server'
import { AuthServiceClient } from '../../protos/auth_grpc_web_pb'
import { ResetPasswordRequest } from '../../protos/auth_pb'
import { useNavigate } from 'react-router-dom'

export default function RequestFields() {
    const [btnStat, setBtnStat] = useState(0)
    const [values, setValues] = useState({
        email: "",
        error: "",
    })
    const changeValue = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value, error: '' })
        setBtnStat(0)
    }
    const navigate = useNavigate()

    const submitButton = () => {
        if (btnStat === 2) {
            return <CircularProgress size={25} />
        } else if (btnStat === -1) {
            return "Error"
        } else {
            return "Request Link"
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        setBtnStat(2)

        const md = GetMetadata()
        const client = new AuthServiceClient(AuthServerAddress, null, null)

        const req = new ResetPasswordRequest();
        req.setRequesttype("request")
        req.setEmail(values.email)

        client.resetPassword(req, md, (err, response) => {
            if (err) {
                setValues({ ...values, error: err.message })
                setBtnStat(-1)
            } else {
                setBtnStat(0)
                navigate('/auth/resetPassword?message=success')
            }
        })
    }

    return <Box
        component="form"
        noValidate
        autoComplete="off"
        style={{ width: '100%', maxWidth: 500 }}
        onSubmit={handleSubmit}
    >
        <TextField
            id="email"
            label="Email Address"
            variant="outlined"
            fullWidth
            onChange={changeValue('email')}
            value={values.email}
            style={{ marginBottom: 15 }}
            disabled={btnStat === 2}
        />
        <Button
            variant="contained"
            disabled={btnStat !== 0 || values.email.length < 5 || values.error.length > 0}
            style={{ width: '100%', marginBottom: 40, textTransform: 'none' }}
            onClick={handleSubmit}
        >
            {submitButton()}
        </Button>
        <Typography variant="caption" color="error">
            {values.error}
        </Typography>
    </Box>
}