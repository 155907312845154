import React from 'react'
import { Grid, Typography, Paper } from '@mui/material'

export default function EndpointHitsView(props) {

    const render = () => {
        return props.hits.hitscountsList.map((hit, index) => {
            return <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                key={hit.endpoint + index}
                sx={{ padding: '5px 0' }}
            >
                <Grid item style={{ width: `calc(100% - 90px)` }} >
                    <Typography variant="subtitle2" style={{ overflowWrap: 'anywhere' }} color="textSecondary">
                        {hit.endpoint}
                    </Typography>
                </Grid>
                <Grid item style={{ width: '80px' }} >
                    <Typography variant="subtitle2" color="primary" textAlign="right">
                        {hit.hits}
                    </Typography>
                </Grid>
            </Grid>
        })
    }

    return <Paper variant="outlined" sx={{ width: '90%', margin: '20px 5%', padding: '10px' }}>
        {render()}
    </Paper>
}