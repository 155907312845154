import React from 'react'
import { CircularProgress, Grid, Typography } from '@mui/material'
import Info from './Info'
import UserInfo from './userInfo'
import { useParams } from 'react-router'
import useOrgData from '../customHooks/generalHooks/useOrgData'

export default function OrgInfo() {

    const { orgId } = useParams()
    const [stat, orgInfo] = useOrgData(orgId)

    const render = () => {
        if (stat === 2) {
            return <CircularProgress size={25} />
        } else if (stat === 0) {
            return <>
                <Info orgInfo={orgInfo} />
                <UserInfo orgInfo={orgInfo} />
            </>
        } else {
            return <Typography variant="body1" color="error">
                Something went wrong
            </Typography>
        }
    }

    return <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        style={{ height: '100%', width: '100%', paddingTop: 20 }}
    >
        {render()}
    </Grid>
}
