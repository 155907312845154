import React from 'react'
import { List, ListItem, ListItemAvatar, ListItemText, Button, Divider, Grid, Box, CircularProgress, Typography } from '@mui/material'
import { SettingsOutlined, Settings, AppsOutlined, Apps, InfoOutlined, Info, GroupOutlined, Group, HistoryOutlined, History } from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router'
import { useTheme } from '@mui/material/styles'
import useOrgData from '../../customHooks/generalHooks/useOrgData'

export default function SideBarOptions(props) {
    const navigate = useNavigate()
    const { orgId, orgOpts } = useParams()
    const theme = useTheme()
    const [loadStat, orgData] = useOrgData(orgId)

    const options = [
        {
            title: 'Info',
            name: undefined,
            icon: <InfoOutlined />,
            iconActive: <Info />,
            clickHandler: () => {
                navigate('/dashboard/' + orgId)
                props.setDisplaySideDrawer(false)
            }
        },
        {
            title: 'Clients',
            name: 'clients',
            icon: <AppsOutlined />,
            iconActive: <Apps />,
            clickHandler: () => {
                navigate('/dashboard/' + orgId + '/clients')
                props.setDisplaySideDrawer(false)
            }
        },
        {
            title: 'Users',
            name: 'users',
            icon: <GroupOutlined />,
            iconActive: <Group />,
            clickHandler: () => {
                navigate('/dashboard/' + orgId + '/users')
                props.setDisplaySideDrawer(false)
            }
        },
        {
            title: 'Activities',
            name: 'activities',
            icon: <HistoryOutlined />,
            iconActive: <History />,
            clickHandler: () => {
                navigate('/dashboard/' + orgId + '/activities')
                props.setDisplaySideDrawer(false)
            }
        },
        // {
        //     title: 'Settings',
        //     name: 'settings',
        //     icon: <SettingsOutlined />,
        //     iconActive: <Settings />,
        //     clickHandler: () => {
        //         navigate('/dashboard/' + orgId + '/settings')
        //         props.setDisplaySideDrawer(false)
        //     }
        // }
    ]

    const renderOptions = () => {
        if (orgId === undefined || orgId === 'newOrg') {
            return <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Divider orientation="vertical" style={{ height: 180 }} />
                <Box
                    sx={{ height: 50, width: 50, border: '1px solid ' + theme.palette.divider }}
                />
                <Divider orientation="vertical" style={{ height: 180 }} />
            </Grid>
        } else if (loadStat === 2) {
            return <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <CircularProgress size={25} />
            </Grid>
        } else if (loadStat === 0) {
            return options.map((opt) => {
                return <ListItem
                    onClick={opt.clickHandler}
                    component={Button}
                    sx={{
                        textTransform: 'none', margin: 0, paddingTop: 0, paddingBottom: 0,
                        color: orgOpts === opt.name ? theme.palette.primary.main : theme.palette.text.secondary
                    }}
                    key={opt.title}
                >
                    <ListItemAvatar >
                        {orgOpts === opt.name ? opt.iconActive : opt.icon}
                    </ListItemAvatar>
                    <ListItemText
                        primary={opt.title}
                        sx={{ marginBottom: 1.3 }}
                    />
                </ListItem>
            })
        } else {
            return <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Typography variant="body1" color="error">
                    Error
                </Typography>
            </Grid>
        }
    }

    return <List sx={{ width: '100%', bgcolor: 'background.main' }} >
        {renderOptions()}
    </List>
}
