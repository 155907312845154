import React, { useEffect } from 'react';
import { Box, CssBaseline, Grid, Typography } from '@mui/material'
import Topbar from './topbar'
import Sidebar from './sidebar'
import NoOrg from './noOrg'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import OrgInfo from '../orgInfo'
import Apps from '../apps';
import Users from '../users';
import Settings from '../settings';
import Activities from '../activities';
import NewOrg from '../newOrg';
import SideDrawer from './sidebar/sideDrawer';
import Logo from './sidebar/logo'
import SideBarOptions from './sidebar/sideBarOptions';
import { useSelector } from 'react-redux';

export default function Dashboard() {
    const { orgId, orgOpts, orgSubOpts } = useParams()
    const [displaySideDrawer, setDisplaySideDrawer] = React.useState(false)
    const navigate = useNavigate()
    const loggedIn = useSelector(state => state.loggedIn)


    useEffect(() => {
        if (!loggedIn) {
            if (orgId === null || orgId === undefined || orgId === "") {
                navigate('/auth/login?redirect=/dashboard')
            }
            if (orgOpts === null || orgOpts === undefined || orgOpts === "") {
                navigate('/auth/login?redirect=/dashboard/' + orgId)
            }
            if (orgSubOpts === null || orgSubOpts === undefined || orgSubOpts === "") {
                navigate('/auth/login?redirect=/dashboard/' + orgId + '/' + orgOpts)
            }
        }
    }, [loggedIn, orgId, orgOpts, orgSubOpts, navigate])

    const renderMain = () => {
        if (orgId === undefined) {
            return <NoOrg />
        } else if (orgId === "newOrg") {
            return <NewOrg />
        } else if (orgOpts === undefined || orgOpts === '') {
            return <OrgInfo />
        } else if (orgOpts === 'clients') {
            return <Apps />
        } else if (orgOpts === 'users') {
            return <Users />
        } else if (orgOpts === 'activities') {
            return <Activities />
        } else if (orgOpts === 'settings') {
            return <Settings />
        } else {
            return <Typography variant="body1" color="textSecondary" align="center">
                Oops You Seem Lost.
                <br />
                <Link to="/dashboard" style={{ color: 'inherit', textDecoration: 'none' }} >
                    Back to Dashboard.
                </Link>
            </Typography>
        }
    }

    return <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <CssBaseline />
        <Topbar
            displaySideDrawer={displaySideDrawer}
            setDisplaySideDrawer={setDisplaySideDrawer}
        />
        <Sidebar
            sidebarContent={
                <>
                    <Logo />
                    <SideBarOptions
                        setDisplaySideDrawer={setDisplaySideDrawer}
                    />
                </>
            }
        />
        <SideDrawer
            displaySideDrawer={displaySideDrawer}
            setDisplaySideDrawer={setDisplaySideDrawer}
            sidebarContent={
                <>
                    <Logo />
                    <SideBarOptions
                        setDisplaySideDrawer={setDisplaySideDrawer}
                    />
                </>
            }
        />
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: 65, padding: 25, paddingTop: 10, width: '100%' }}
        >
            {renderMain()}
        </Grid>
    </Box>
}
