import React from 'react'
import { Grid, CircularProgress, Typography } from '@mui/material'
import useOrgData from '../customHooks/generalHooks/useOrgData'
import { useParams } from 'react-router'
import UserCard from './userCard'
import InviteUser from './inviteUser'

export default function Users() {

    const { orgId } = useParams()
    const [loadStat, orgInfo] = useOrgData(orgId)

    const returnInCenter = (elem) => {
        return <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ height: '80%', width: '100%' }}
        >
            {elem}
        </Grid>
    }

    const render = () => {
        if (loadStat === 2) {
            return returnInCenter(<CircularProgress size={25} />)
        } else if (loadStat === 0) {
            return <>
                <InviteUser />
                <UserCard
                    orgInfo={orgInfo}
                />
            </>
        } else if (loadStat === -1) {
            return returnInCenter(
                <Typography variant="body1" color="error">
                    Something went wrong.
                </Typography>
            )
        } else {
            return <></>
        }
    }

    return <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ height: '100%', width: '100%' }}
    >
        {render()}
    </Grid>
}