import React from 'react'
import { DoneAll, Error } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'

export default function ButtonOnState(props) {

    const render = () => {
        switch (props.loadState) {
            case -1:
                return <Error />
            case 0:
                return props.successText
            case 1:
                return <DoneAll />
            case 2:
                return <CircularProgress size={25} />
            default:
                return "Unknown State"
        }
    }

    return render()
}