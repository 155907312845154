import React, { useState } from 'react'
import { Button, Box, Typography } from '@mui/material'
import ButtonOnState from '../../../components/buttonOnState'
import GetMetadata from '../../../components/getMetadata'
import { ClientServerAddress } from '../../../protos/server'
import { ClientServiceClient } from '../../../protos/client_grpc_web_pb'
import { ClientId } from '../../../protos/client_pb'
import { useDispatch, useSelector } from 'react-redux'

export default function DeleteApp(props) {

    const [btnStat, setBtnStat] = useState(0)
    const userInfo = useSelector((state) => state.userInfo)
    const dispatch = useDispatch()

    const handleDelete = () => {
        setBtnStat(2)
        const md = GetMetadata()
        md.authorization = userInfo.token
        const client = new ClientServiceClient(ClientServerAddress, null, null)

        const req = new ClientId()
        req.setOrgid(props.appData.orgid)
        req.setClientid(props.appData.clientid)

        client.deleteClient(req, md, (err, response) => {
            if (err) {
                if (err.message === "token expired" || err.message === "error decoding token" || err.message === "no token" || err.message === "invalid token") {
                    dispatch({ type: 'LOGOUT' })
                    return
                }
                setBtnStat(-1)
            } else {
                setBtnStat(1)
                dispatch({ type: 'REMOVE_CLIENT', clientId: props.appData.clientid, orgId: props.appData.orgid })
                setTimeout(() => { }, 3000)
            }
        })
    }


    return <Box sx={{ width: '100%', marginBottom: '20px', borderBottom: '1px solid', borderColor: 'divider' }}>
        <Button
            style={{ textTransform: 'none' }}
            color="error"
            disabled={props.appData.isProductionRequested || btnStat != 0}
            onClick={handleDelete}
        >
            <ButtonOnState
                loadState={btnStat}
                successText="Delete Client"
            />
        </Button>
        <br />
        <Typography variant="caption" color="textSecondary" align="left">
            - This action is irreversible.
            <br />
            - All the data related to this client will be deleted.
            < br />
            - All users will lose access to your client.
            < br />
            - You won't be able to delete if you have Read-Only access.
            < br />
            - You won't be able to delete if you have requested for Production access.
            < br />
            < br />
        </Typography>
    </Box>
}