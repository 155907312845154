import React from 'react'
import { Typography, Grid } from '@mui/material'

export default function UserBreakdown(props) {

    const countUsersAccess = (accessType) => {
        var count = 0
        if (props.orgInfo.users.length === 0) {
            return 0
        }
        for (let i = 0; i < props.orgInfo.membersList.length; i++) {
            if (props.orgInfo.membersList[i].access === accessType) {
                count++
            }
        }
        return count
    }

    return <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%', width: 250, marginRight: 5 }}
    >
        <Typography variant="body2" gutterBottom align="left">
            Admin Access : {props.orgInfo.membersList.filter((member) => member.accesslevel === "Admin" || member.accesslevel === "Creator").length}
        </Typography>
        <Typography variant="body2" gutterBottom>
            Read Only Access : {props.orgInfo.membersList.filter((member) => member.accesslevel === "Read-Only").length}
        </Typography>
        <Typography variant="body2" gutterBottom>
            Developer Access : {props.orgInfo.membersList.filter((member) => member.accesslevel === "Developer").length}
        </Typography>
    </Grid>
}
