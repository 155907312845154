import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
    palette: {
        primary: {
            ultraLight: '#BCDFFF',
            light: '#65B6FF',
            main: '#1B86E7',
            dark: '#0D5DA4',
            ultraDark: '#11416B',
        },
        secondary: {
            main: '#db4437',
        },
        background: {
            page: '#F6FBFF',
            main: '#FFFFFF',
            dark: '#F2F8FF'
        },
        error: {
            ultraLight: '#FFC8C4',
            light: '#FF8A81',
            main: '#F44336',
        },
        success: {
            main: '#54B258',
        },
        text: {
            primary: '#212121',
            secondary: '#9C9C9C',
            disabled: '#DADADA',
        },
        info: {
            main: '#2196F3',
        },
    },
});