import React from 'react'
import { Grid, CircularProgress, Typography } from '@mui/material'
import EndpointHits from './endpointHits'
import useAppMetrics from '../../customHooks/generalHooks/useAppMetrics'
// import ChartView from './chartView'
import AccessesCount from './accessesCount'

export default function AppMetrics(props) {

    const [loadStat, appMetrics] = useAppMetrics(props.appId, props.orgId)

    const render = () => {
        if (loadStat === 0) {
            return <>
                <Grid item
                    sx={{ width: `calc(100% - 405px)`, borderRight: '1px solid', borderColor: 'divider' }}
                >
                    <AccessesCount
                        accessesinuse={appMetrics.accessesinuse}
                        accessestotal={appMetrics.accessestotal}
                        accessesblocked={appMetrics.accessesblocked}
                    />
                    <Typography variant="body2" color="textSecondary">
                        Token requests chart coming soon.
                    </Typography>
                    {/* <ChartView
                        tokenrequests={appMetrics.tokenrequests}
                    /> */}
                </Grid>
                <Grid item sx={{ width: '400px' }}>
                    <EndpointHits
                        hitsoverall={appMetrics.hitsoverall}
                        hitstoday={appMetrics.hitstoday}
                    />
                </Grid>
            </>
        } else if (loadStat === 2) {
            return <CircularProgress size={25} />
        } else if (loadStat === -1) {
            return <Typography variant="subtitle2" color="error">
                Something went wrong.
            </Typography>
        }
    }

    return <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        style={{ width: '100%', marginTop: '10px' }
        }
    >
        {render()}
    </Grid >
}