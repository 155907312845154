import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Grid } from '@mui/material';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DeleteOrg from './deleteOrg';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`settings-tabpanel-${index}`}
            aria-labelledby={`settings-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `settings-tab-${index}`,
        'aria-controls': `settings-tabpanel-${index}`,
    };
}

export default function Settings() {
    const [tabValue, setTabValue] = useState(0);

    const changeTabValue = (event, newValue) => {
        setTabValue(newValue);
    }

    const tabOptions = [
        {
            label: 'General',
            color: 'text.secondary',
            component: <>Hello</>
        },
        {
            label: 'Delete',
            color: 'error.main',
            component: <DeleteOrg />
        },
    ]

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{ height: '100%', width: '100%' }}
        >
            <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    scrollButtons="auto"
                    value={tabValue}
                    onChange={changeTabValue}
                    aria-label="settings tabs"
                    sx={{ borderRight: 1, borderColor: 'divider', marginTop: '12px' }}
                >
                    {
                        tabOptions.map((opt, index) => {
                            return <Tab
                                label={opt.label}
                                key={opt.label}
                                {...a11yProps(index)}
                                style={{ textTransform: 'none' }}
                                sx={{ color: opt.color }}
                            />
                        })
                    }
                </Tabs>
                {
                    tabOptions.map((opt, index) => {
                        return <TabPanel value={tabValue} key={index} index={index}>
                            {opt.component}
                        </TabPanel>
                    })
                }
            </Box>
        </Grid>
    );
}
