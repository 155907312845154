import React, { useState } from 'react'
import { Grid, Button, Typography, Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import UserAccess from './userAccess'
import Collapse from '@mui/material/Collapse';
import GetMetadata from '../components/getMetadata'
import { OrgServerAddress } from '../protos/server'
import { OrganisationServiceClient } from '../protos/org_grpc_web_pb'
import { ChangeUserAccessRequest } from '../protos/org_pb'
import AccessUpdateAlert from './accessUpdateAlert';
import ButtonOnState from '../components/buttonOnState';

export default function EachUser(props) {
    const dispatch = useDispatch()
    const [accessUpdateAlert, setAccessUpdateAlert] = useState(false)
    const [accessUpdateResponse, setAccessUpdateResponse] = useState(0)
    const [accessUpdateMessage, setAccessUpdateMessage] = useState("")
    const userInfo = useSelector((state) => state.userInfo)
    const [removeBtnStat, setRemoveBtnStat] = useState(0)

    const removeHandler = () => {
        setRemoveBtnStat(2)

        const md = GetMetadata()
        const client = new OrganisationServiceClient(OrgServerAddress, null, null)

        const req = new ChangeUserAccessRequest()
        req.setOrgid(props.orgInfo.orgid)
        req.setUserid(props.user.userid)
        req.setNewaccess("Remove")
        md.authorization = userInfo.token

        client.changeUserAccess(req, md, (err, response) => {
            if (err) {
                if (err.message === "token expired" || err.message === "error decoding token" || err.message === "no token" || err.message === "invalid token") {
                    dispatch({ type: 'LOGOUT' })
                    return
                }
                setRemoveBtnStat(-1)
                setAccessUpdateMessage(err.message)
                setAccessUpdateAlert(true)
                setAccessUpdateResponse(-1)
            } else {
                dispatch({ type: 'REMOVE_ARG', orgId: props.orgInfo.orgid })
                setRemoveBtnStat(1)
                setAccessUpdateMessage("User Removed")
                setAccessUpdateAlert(true)
                setAccessUpdateResponse(1)
            }
        })
    }

    return <Box
        sx={{
            borderBottom: props.lastUser ? 'none' : '1px solid',
            borderColor: 'divider',
            padding: '20px', width: '100%'
        }}
    >
        <Grid
            container
            spacing={2}
            columns={16}
        >
            <Grid item xs={4}>
                <Typography variant="body2" >
                    {props.user.firstname} {props.user.lastname}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <UserAccess
                    accesslevel={props.user.accesslevel}
                    setAccessUpdateResponse={setAccessUpdateResponse}
                    setAccessUpdateAlert={setAccessUpdateAlert}
                    orgid={props.orgInfo.orgid}
                    userid={props.user.userid}
                    setAccessUpdateMessage={setAccessUpdateMessage}
                />
            </Grid>
            <Grid item xs={5}>
                <Typography variant="body2" >
                    Joined : &nbsp;
                    {props.user.joined}
                </Typography>
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'center' }}>
                <Button
                    color="error"
                    style={{ textTransform: 'none', margin: 'auto' }}
                    onClick={removeHandler}
                    disabled={removeBtnStat !== 0}
                >
                    <ButtonOnState
                        loadState={removeBtnStat}
                        successText="Remove"
                    />
                </Button>
            </Grid>
        </Grid>
        <Collapse in={accessUpdateAlert} style={{ marginTop: '10px', width: '40%', minWidth: '500px' }}>
            <AccessUpdateAlert
                setAccessUpdateAlert={setAccessUpdateAlert}
                response={accessUpdateResponse}
                accessUpdateMessage={accessUpdateMessage}
                setAccessUpdateMessage={setAccessUpdateMessage}
            />
        </Collapse>
    </Box>
}