import React from 'react'
import { AppBar, Toolbar, Box, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import OrganizationSelector from './organizationSelector'
import { Menu } from '@mui/icons-material'
import ProfileOptions from './profileOptions'
// import SupportOptions from './supportOptions'

export default function Topbar(props) {
    const theme = useTheme()

    return <AppBar
        position="fixed"
        sx={{
            width: { xs: '100%', md: `calc(100% - 240px)` },
            ml: { xs: 0, md: `240px` },
            backgroundColor: '#fff',
            boxShadow: '0 -5px 10px 2px ' + theme.palette.text.disabled,
        }}
    >
        <Toolbar>
            <Button
                size="small"
                sx={{
                    display: { xs: 'block', md: 'none' },
                    ml: '-10px',
                    mt: '10px'
                }}
                onClick={() => props.setDisplaySideDrawer(!props.displaySideDrawer)}
            >
                <Menu />
            </Button>
            <OrganizationSelector />
            <Box sx={{ flexGrow: 1 }} />
            {/* <SupportOptions /> */}
            <ProfileOptions />
        </Toolbar>
    </AppBar>
}
