import { Box, Grid, Typography } from '@mui/material'
import React from 'react'

export default function DefaultView(props) {
    return <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
    >
        <Box
            sx={{
                width: '100%',
                maxWidth: 300,
                border: '1px solid',
                borderColor: 'text.disabled',
                padding: 3,
                borderRadius: 2,
                mr: 4
            }}
        >
            <Typography
                variant="body1"
                sx={{ textDecoration: 'underline' }}
            >
                Open Tickets
            </Typography>
            <Typography
                variant="h3"
                align='center'
                sx={{
                    mt: 5
                }}
            >
                1
            </Typography>
        </Box>
        <Box
            sx={{
                width: '100%',
                maxWidth: 300,
                border: '1px solid',
                borderColor: 'text.disabled',
                padding: 3,
                borderRadius: 2,
                mr: 4
            }}
        >
            <Typography
                variant="body1"
                sx={{ textDecoration: 'underline' }}
            >
                Closed Tickets
            </Typography>
            <Typography
                variant="h3"
                align='center'
                sx={{
                    mt: 5
                }}
            >
                1
            </Typography>
        </Box>
    </Grid>
}