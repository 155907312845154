import React from 'react'
import { Alert, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

export default function AccessUpdateAlert(props) {

    return <Alert
        action={
            props.response === -1 || props.response === 1 ? <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                    props.setAccessUpdateAlert(false);
                }}
            >
                <CloseIcon fontSize="inherit" />
            </IconButton> : <></>
        }
        severity={props.response === 1 ? "success" : props.response === -1 ? "error" : ""}
        sx={{ mb: 2 }}
    >
        {props.accessUpdateMessage}
    </Alert>
}