import React from 'react'
import { Grid, Typography } from '@mui/material'
import TopOptions from './topOptions'
import LoginForm from './loginForm'

export default function LoginFields() {

    return <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        sx={{
            padding: '10px',
            textAlign: 'center',
            height: '100%',
            width: {
                xs: '98%',
                md: '80%',
            }
        }}
        spacing={0}
    >
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <TopOptions />
        </Grid>
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Typography
                variant="h6"
                color="textPrimary"
                gutterBottom
                style={{ paddingBottom: 20 }}
            >
                Login to Proceed
            </Typography>
            <LoginForm />
        </Grid>
    </Grid>
}
