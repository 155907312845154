import { useState, useEffect } from 'react'
import GetMetadata from '../../components/getMetadata'
import { ClientServerAddress } from '../../protos/server'
import { ClientServiceClient } from '../../protos/client_grpc_web_pb'
import { ClientId } from '../../protos/client_pb'
import { useDispatch, useSelector } from 'react-redux'

export default function useAppPerms(clientId, orgId) {
    const [stat, setStat] = useState(2)
    const [clientPerms, setClientPerms] = useState([])
    const userInfo = useSelector((state) => state.userInfo)
    const dispatch = useDispatch()

    const fetchData = () => {
        const md = GetMetadata()
        md.authorization = userInfo.token
        const client = new ClientServiceClient(ClientServerAddress, null, null)

        const req = new ClientId()
        req.setOrgid(orgId)
        req.setClientid(clientId)

        client.getClientPermissions(req, md, (err, response) => {
            if (err) {
                if (err.message === "token expired" || err.message === "error decoding token" || err.message === "no token" || err.message === "invalid token") {
                    dispatch({ type: 'LOGOUT' })
                    return
                }
                setStat(-1)
            } else {
                const res = response.toObject()
                setClientPerms(res.permsList)
                setStat(0)
            }
        })
    }

    useEffect(() => {
        fetchData()
    }, [clientId])

    return [stat, clientPerms]
}