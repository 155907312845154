import React, { useState } from 'react'
import { Typography, Grid, Button } from '@mui/material'
import ButtonOnState from '../../components/buttonOnState'
import GetMetadata from '../../components/getMetadata'
import { ClientServerAddress } from '../../protos/server'
import { ClientServiceClient } from '../../protos/client_grpc_web_pb'
import { PermissionRequest, Permission } from '../../protos/client_pb'
import { useDispatch, useSelector } from 'react-redux'

export default function SelectedEachPerm(props) {

    const perm = props.perm
    const [removeBtnStat, setRemoveBtnStat] = useState(0)
    const [removalError, setRemovalError] = useState("")
    const dispatch = useDispatch()
    const userInfo = useSelector(state => state.userInfo)

    const removePerm = () => {
        setRemoveBtnStat(2)
        const md = GetMetadata()
        md.authorization = userInfo.token
        const client = new ClientServiceClient(ClientServerAddress, null, null)

        const permToAdd = new Permission()
        permToAdd.setPermid(parseInt(perm.permid))

        const req = new PermissionRequest()
        req.setClientid(props.appId)
        req.setOrgid(props.orgId)
        req.setPermission(permToAdd)

        client.removePermission(req, md, (err, response) => {
            if (err) {
                if (err.message === "token expired" || err.message === "error decoding token" || err.message === "no token" || err.message === "invalid token") {
                    dispatch({ type: 'LOGOUT' })
                    return
                }
                setRemoveBtnStat(-1)
                setRemovalError(err.message)
            } else {
                setRemoveBtnStat(1)
                props.updateSelectedPermsState()
            }
        })
    }

    return <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
        key={perm.permid}
        sx={{ borderBottom: '1px solid', borderColor: 'divider', padding: '10px 0' }}
    >
        <Grid item style={{ width: `calc(100% - 80px)` }}>
            <Typography variant="body1" align="left">
                {perm.permname}
            </Typography>
            <Typography variant="body2" align="left" style={{ margin: '0 0 5px 5px' }}>
                - {perm.permdesc}
                <br />
                - {perm.permid}
            </Typography>
        </Grid>
        <Grid item style={{ width: '80px' }}>
            <Button
                color="error"
                style={{ textTransform: 'none' }}
                disabled={removeBtnStat !== 0}
                onClick={() => removePerm(perm.permid)}
            >
                <ButtonOnState
                    loadState={removeBtnStat}
                    successText="Remove"
                />
            </Button>
        </Grid>
        <Grid item style={{ width: '100%' }}>
            <Typography variant="body2" align="left" color="error" style={{ marginLeft: '10px', marginTop: '2px' }}>
                {removalError}
            </Typography>
        </Grid>
    </Grid>
}