import { AppBar, Toolbar, useTheme, Button, Typography, IconButton } from '@mui/material'
import React from 'react'
import { Menu, ArrowBack } from '@mui/icons-material'
import { useNavigate } from 'react-router'

export default function SupportTopbar(props) {
    const theme = useTheme()
    const navigate = useNavigate()
    return <AppBar
        position="fixed"
        sx={{
            width: '100%',
            backgroundColor: '#fff',
            boxShadow: '0 -5px 10px 2px ' + theme.palette.text.secondary,
            color: 'text.primary',
            zIndex: (theme) => theme.zIndex.drawer + 1
        }}
    >
        <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={props.handleDrawerToggle}
                sx={{
                    mr: 2,
                    display: { sm: 'none' },
                    color: 'primary.main'
                }}
            >
                <Menu />
            </IconButton>
            <Typography variant="h6" noWrap component="div"
                sx={{
                    flexGrow: 1
                }}
            >
                Support
            </Typography>
            <Button
                onClick={() => navigate('/dashboard')}
            >
                <ArrowBack />
            </Button>
        </Toolbar>
    </AppBar>
}