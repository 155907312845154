import React from 'react'
import { Typography, Box, CircularProgress } from '@mui/material'
import useAppPerms from '../../customHooks/generalHooks/useAppPerms'
import SelectedEachPerm from './selectedEachPerm'

export default function SelectedPerms(props) {

    const [loadStat, perms] = useAppPerms(props.appId, props.orgId)

    const render = () => {
        if (loadStat === 2) {
            return <CircularProgress size={25} style={{ marginTop: 50 }} />
        } else if (loadStat === 0) {
            if (perms.length === 0) {
                return <Typography variant="body2" color="textSecondary" style={{ marginTop: '50px' }}>
                    No Permissions Added.
                </Typography>
            }
            return perms.map((perm) => {
                return <SelectedEachPerm
                    perm={perm}
                    key={perm.permid}
                    updateSelectedPermsState={props.updateSelectedPermsState}
                    appId={props.appId}
                    orgId={props.orgId}
                />
            })
        } else if (loadStat === -1) {
            return <Typography variant="subtitle2" color="error" style={{ marginTop: 50 }}>
                Something went wrong
            </Typography>
        } else {
            return <></>
        }
    }

    return <>
        <Typography variant="h6" gutterBottom align="center">
            Added Permissions
        </Typography>
        <Box style={{ width: '100%', textAlign: 'center' }}>
            {render()}
        </Box>
    </>
}