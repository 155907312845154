import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GetMetadata from '../../components/getMetadata'
import { OrgServerAddress } from '../../protos/server'
import { OrganisationServiceClient } from '../../protos/org_grpc_web_pb'
import { GetActivitiesRequest } from '../../protos/org_pb'

export default function useOrgActivities(orgId) {
    const [stat, setStat] = useState(2)
    const [activities, setActivities] = useState([])
    const userInfo = useSelector((state) => state.userInfo)
    const dispatch = useDispatch()

    const fetchActivities = () => {
        const md = GetMetadata()
        md.authorization = userInfo.token
        const client = new OrganisationServiceClient(OrgServerAddress, null, null)

        const req = new GetActivitiesRequest()
        req.setOrgid(orgId)

        client.getActivities(req, md, (err, response) => {
            if (err) {
                if (err.message === "token expired" || err.message === "error decoding token" || err.message === "no token" || err.message === "invalid token") {
                    dispatch({ type: 'LOGOUT' })
                    return
                } else if (err.code === 16 || err.code === 5) {
                    dispatch({ type: 'REMOVE_ORG', orgId: orgId })
                    setStat(-1)
                    return
                }
                setStat(-1)
            } else {
                const res = response.toObject()
                setActivities(res.activitiesList)
                setStat(0)
            }
        })
    }

    useEffect(() => {
        fetchActivities()
    }, [orgId])

    return [stat, activities]
}