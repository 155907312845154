import React from 'react'
import { Grid } from '@mui/material'
import ProfileTabs from './profileTabs'

export default function ProfileContainer() {

    return <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%' }} >
        <Grid container style={{ width: '60%', height: '60%', minWidth: '500px', minHeight: '400px' }} >
            <ProfileTabs />
        </Grid>
    </Grid>
}
