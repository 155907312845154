import React, { useState } from 'react'
import { Button, TextField, CircularProgress, Typography } from '@mui/material'
import { DoneAll, Error } from '@mui/icons-material'
import GetMetadata from '../components/getMetadata'
import { ClientServerAddress } from '../protos/server'
import { ClientServiceClient } from '../protos/client_grpc_web_pb'
import { Client } from '../protos/client_pb'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

const fieldReq = {
    appName: {
        lowLimit: 4,
        highLimit: 30,
    },
    logoUrl: {
        lowLimit: 10,
        highLimit: 512,
    },
    redirectUri: {
        lowLimit: 5,
        highLimit: 128,
    },
    privacyPolicyUrl: {
        lowLimit: 10,
        highLimit: 512,
    },
    termsUrl: {
        lowLimit: 10,
        highLimit: 512,
    },
}

export default function NewAppForm(props) {

    const [values, setValues] = useState({
        appName: "",
        orgName: props.orgData.orgname,
        appEnv: 'Development/Testing',
        logoUrl: '',
        redirectUri: '',
        privacyPolicyUrl: '',
        termsUrl: '',
    })
    const [btnStat, setBtnStat] = useState(0)
    const [error, setError] = useState("")
    const dispatch = useDispatch()
    const userInfo = useSelector((state) => state.userInfo)
    const navigate = useNavigate()

    const changeValues = (prop) => (event) => {
        setBtnStat(0)
        setValues({ ...values, [prop]: event.target.value })
    }

    const getHelperText = (fieldName) => {
        if (values[fieldName].length < fieldReq[fieldName].lowLimit) {
            return "Minimum " + fieldReq[fieldName].lowLimit + " characters"
        } else if (values[fieldName].length > fieldReq[fieldName].highLimit) {
            return "Maximum " + fieldReq[fieldName].highLimit + " characters"
        } else {
            return " "
        }
    }

    const handleSubmit = () => {
        setBtnStat(2)
        const md = GetMetadata()
        md.authorization = userInfo.token
        const client = new ClientServiceClient(ClientServerAddress, null, null)

        const req = new Client()
        req.setOrgid(props.orgData.orgid)
        req.setClientname(values.appName)
        req.setLogo(values.logoUrl)
        req.setRedirecturi(values.redirectUri)
        req.setPrivacypolicy(values.privacyPolicyUrl)
        req.setTerms(values.termsUrl)

        client.createClient(req, md, (err, response) => {
            if (err) {
                if (err.message === "token expired" || err.message === "error decoding token" || err.message === "no token" || err.message === "invalid token") {
                    dispatch({ type: 'LOGOUT' })
                    return
                }
                setBtnStat(-1)
                setError(err.message)
            } else {
                setBtnStat(1)
                setTimeout(() => navigate('/dashboard/' + props.orgData.orgid + '/clients/' + response.toObject().response), 2000)
            }
        })
        dispatch({ type: 'REMOVE_ORG', orgId: props.orgData.orgid })
    }

    const renderSubmitButton = () => {
        if (btnStat === 0) {
            return "Submit"
        } else if (btnStat === 2) {
            return <CircularProgress size={25} />
        } else if (btnStat === 1) {
            return <DoneAll />
        } else if (btnStat === -1) {
            return <Error color="error" />
        }
    }

    return <form
        noValidate autoComplete="off"
        onSubmit={e => e.preventDefault()}
    >
        <TextField
            id="org-name"
            label="Organisation"
            variant="outlined"
            name="orgName"
            value={values.orgName}
            fullWidth
            style={{ marginBottom: 30 }}
            disabled
            helperText="Cross check your organisation."
        />
        <TextField
            id="app-env"
            label="Environment"
            variant="outlined"
            name="appEnv"
            value={values.appEnv}
            fullWidth
            style={{ marginBottom: 30 }}
            disabled
            helperText="You can push to Production only after verification from us."
        />
        <TextField
            id="app-name"
            label="Client Name"
            variant="outlined"
            name="appName"
            value={values.appName}
            fullWidth
            onChange={changeValues('appName')}
            helperText={getHelperText('appName')}
            style={{ marginBottom: 30 }}
        />
        <TextField
            id="redirectUri"
            label="Redirect URI"
            variant="outlined"
            name="redirectUri"
            value={values.redirectUri}
            fullWidth
            onChange={changeValues('redirectUri')}
            helperText={getHelperText('redirectUri')}
            style={{ marginBottom: 30 }}
        />
        <TextField
            id="logoUrl"
            label="Address to Client's Logo"
            variant="outlined"
            name="logoUrl"
            value={values.logoUrl}
            fullWidth
            onChange={changeValues('logoUrl')}
            helperText={getHelperText('logoUrl')}
            style={{ marginBottom: 30 }}
        />
        <TextField
            id="privacyPolicyUrl"
            label="Address to Privacy Policy"
            variant="outlined"
            name="privacyPolicyUrl"
            value={values.privacyPolicyUrl}
            fullWidth
            onChange={changeValues('privacyPolicyUrl')}
            helperText={getHelperText('privacyPolicyUrl')}
            style={{ marginBottom: 30 }}
        />
        <TextField
            id="termsUrl"
            label="Address to Terms & Conditions"
            variant="outlined"
            name="termsUrl"
            value={values.termsUrl}
            fullWidth
            onChange={changeValues('termsUrl')}
            helperText={getHelperText('termsUrl')}
            style={{ marginBottom: 30 }}
        />
        <Typography variant="body2" color="error" gutterBottom>
            {error}
        </Typography>
        <br />
        <Button
            variant="contained"
            color="primary"
            disabled={btnStat !== 0}
            onClick={handleSubmit}
            style={{ textTransform: 'none' }}
        >
            {renderSubmitButton()}
        </Button>
    </form>
}
