import React, { useState } from 'react'
import { Grid } from '@mui/material'
import AddPerms from './addPerms'
import SelectedPerms from './selectedPerms'

export default function Permissions(props) {

    const [selectedPermKey, setSelectedPermKey] = useState(1)
    const updateSelectedPermsState = () => {
        setSelectedPermKey(key => key + 1)
    }

    return <Grid container spacing={0} style={{ paddingTop: 10 }}>
        <Grid item xs={6} sx={{ borderRight: '1px solid', borderColor: 'divider', padding: '10px 20px 10px 10px' }}>
            <AddPerms
                selectedPermKey={selectedPermKey}
                setSelectedPermKey={setSelectedPermKey}
                appId={props.appId}
                orgId={props.orgId}
            />
        </Grid>
        <Grid item xs={6} style={{ padding: '10px 10px 10px 20px' }}>
            <SelectedPerms
                key={selectedPermKey}
                appId={props.appId}
                orgId={props.orgId}
                updateSelectedPermsState={updateSelectedPermsState}
            />
        </Grid>
    </Grid>
}