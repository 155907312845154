import React, { useState } from 'react'
import { Grid, Button, Typography, CircularProgress } from '@mui/material'
import { ArrowBack, } from '@mui/icons-material'
import { useParams, useNavigate } from 'react-router'
import useAppData from '../customHooks/generalHooks/useAppData'
import AppsTabs from './appsTabs'
import AppDetails from './appDetails'
import UserLostContent from '../fallback/userLostContent'
import EachAppContainer from './eachAppContainer'

export default function EachApp() {

    const [containerKey, setContainerKey] = useState(0)
    const [appTab, setAppTab] = useState(0)

    return <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ height: '100%', width: '100%' }}
    >
        <EachAppContainer
            key={containerKey}
            containerKey={containerKey}
            setContainerKey={setContainerKey}
            appTab={appTab}
            setAppTab={setAppTab}
        />
    </Grid>
}
