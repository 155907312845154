import React, { useState } from 'react'
import { FormControl, Select, MenuItem } from '@mui/material'
import GetMetadata from '../components/getMetadata'
import { OrgServerAddress } from '../protos/server'
import { OrganisationServiceClient } from '../protos/org_grpc_web_pb'
import { ChangeUserAccessRequest } from '../protos/org_pb'
import { useSelector, useDispatch } from 'react-redux'

export default function UserAccess(props) {
    const [access, setAccess] = useState(props.accesslevel)
    const userInfo = useSelector((state) => state.userInfo)
    const dispatch = useDispatch()

    const changeAccess = (event) => {
        setAccess(event.target.value)
        props.setAccessUpdateAlert(false)
        props.setAccessUpdateResponse(0)
        props.setAccessUpdateMessage("")

        const md = GetMetadata()
        const client = new OrganisationServiceClient(OrgServerAddress, null, null)

        const req = new ChangeUserAccessRequest()
        req.setOrgid(props.orgid)
        req.setUserid(props.userid)
        req.setNewaccess(event.target.value)
        md.authorization = userInfo.token

        client.changeUserAccess(req, md, (err, response) => {
            if (err) {
                if (err.message === "token expired" || err.message === "error decoding token" || err.message === "no token" || err.message === "invalid token") {
                    dispatch({ type: 'LOGOUT' })
                    return
                }
                props.setAccessUpdateMessage(err.message)
                props.setAccessUpdateResponse(-1)
                props.setAccessUpdateAlert(true)
                setAccess(props.accesslevel)
            } else {
                props.setAccessUpdateMessage("Access Updated")
                props.setAccessUpdateResponse(1)
                props.setAccessUpdateAlert(true)
            }
        })
    };

    return <FormControl variant="standard" sx={{ minWidth: 170 }}>
        <Select
            id="access-type"
            value={access}
            onChange={changeAccess}
            label="Age"
            style={{ fontSize: 14 }}
        >
            <MenuItem value='Creator' disabled style={{ fontSize: 14 }}>Creator</MenuItem>
            <MenuItem value='Admin' style={{ fontSize: 14 }}>Admin Access</MenuItem>
            <MenuItem value='Developer' style={{ fontSize: 14 }}>Developer Access</MenuItem>
            <MenuItem value='Read-Only' style={{ fontSize: 14 }}>Read-Only Access</MenuItem>
        </Select>
    </FormControl>
}