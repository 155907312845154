import { Box, Button, List, ListItem, ListItemButton, ListItemText, Toolbar } from '@mui/material'
import React from 'react'

export default function SupportSidebar(props) {
    return <Box
        sx={{
            width: 240,
            pt: 3,
            height: `calc(100vh - 100px)`,
            textAlign: 'center',
        }}
    >
        <Toolbar />
        <Button
            variant="contained"
            sx={{
                textTransform: 'none'
            }}
            onClick={() => props.setView("new")}
        >
            Create New Ticket
        </Button>
        <List>
            <ListItem>
                <ListItemButton>
                    <ListItemText
                        primary="Title of the ticket"
                        secondary={<>
                            <strong>Date :</strong> 20/12/2022
                            <br />
                            <strong>Status :</strong> Open
                        </>}
                    />
                </ListItemButton>
            </ListItem>
        </List>
    </Box>
}