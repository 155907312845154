import React from 'react'
import { Grid, Paper, Box, Typography } from '@mui/material'
import Logo from '../components/buildWithLogo'

export default function SideOpts() {

    return <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: 'background.dark', width: '100%', height: '100%' }}
    >
        <Paper elevation={5} style={{ minWidth: 300, minHeight: 170 }}>
            <Box
                sx={{
                    ml: 5, mt: 2
                }}
            >
                <Logo scale="95%" />
            </Box>
        </Paper>
        <Typography
            variant="h5"
            color="white"
            gutterBottom
            sx={{
                mt: 5,
                bgcolor: 'primary.main',
                py: '15px',
                px: 8,
                borderRadius: '50px'
            }}
        >
            Developer Console
        </Typography>
    </Grid>
}
