import React from 'react'
import { Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export default function UserLostContent() {

    return <Typography varian="h6" color="textSecondary" align="center">
        Oops!
        <br />
        You seem lost.
        <br />
        <Link
            to="/dashboard"
            style={{ textDecoration: 'none', color: 'inherit' }}
        >
            Back to Dashboard.
        </Link>
    </Typography>
}
