import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import Fallback from './fallback'
import AuthFace from './auth'
import Dashboard from './dashboard'
import ProfileContainer from './profile'
import Support from './support'

export default function ConsoleRoutes() {
    return <BrowserRouter>
        <div style={{ width: "100%", padding: 0, minHeight: "100vh" }}>
            <Routes>
                <Route
                    path="/auth/:type"
                    element={<AuthFace />}
                />
                <Route
                    path="/profile"
                    element={<ProfileContainer />}
                />
                <Route
                    path="/support"
                    element={<Support />}
                />
                <Route
                    path="/dashboard"
                    element={<Dashboard />}
                />
                <Route
                    path="/dashboard/:orgId"
                    element={<Dashboard />}
                />
                <Route
                    path="/dashboard/:orgId/:orgOpts"
                    element={<Dashboard />}
                />
                <Route
                    path="/dashboard/:orgId/:orgOpts"
                    element={<Dashboard />}
                />
                <Route
                    path="/dashboard/:orgId/:orgOpts/:orgSubOpts"
                    element={<Dashboard />}
                />
                <Route
                    path="/"
                    element={<Navigate to="/auth/login" />}
                />
                <Route
                    path="*"
                    element={<Fallback />}
                />
            </Routes>
        </div>
    </BrowserRouter>
}
