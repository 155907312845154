import { CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ConsoleRoutes from './routes';

export default function PreLogin() {

    const dispatch = useDispatch()
    const initState = useSelector(state => state.initState)

    const getUser = () => {
        const userData = JSON.parse(localStorage.getItem('consoleUser'));
        if (userData !== null) {
            dispatch({ type: 'LOGIN', userData: userData })
        } else {
            dispatch({ type: 'LOGOUT' })
        }
    }

    useEffect(() => {
        getUser()
    }, [])

    const renderInCenter = (elem) => {
        return <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ width: '100%', height: '100vh' }}
        >
            {elem}
        </Grid>
    }

    const render = () => {
        if (initState === 2) {
            return renderInCenter(
                <CircularProgress size={25} />
            )
        } else if (initState === 0) {
            return <ConsoleRoutes />
        } else {
            return renderInCenter(
                <Typography variant="body1" color="error">
                    Something went wrong
                </Typography>
            )
        }
    }

    return render()
}