import { Box, Grid, TextField, Button, Typography } from '@mui/material'
import React from 'react'

export default function NewTicket(props) {
    return <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
    >
        <Typography variant="h5" gutterBottom>
            Create New Ticket
        </Typography>
        <Box
            component="form"
            sx={{
                width: '100%',
                maxWidth: 700,
                py: 2, pt: 2,
            }}
            noValidate
            autoComplete="off"
        >
            <TextField
                id="support-ticket-subject"
                label="Subject"
                variant="outlined"
                fullWidth
                sx={{
                    mb: 5,
                }}
            />
            <TextField
                id="support-ticket-body"
                label="Body"
                multiline
                rows={10}
                defaultValue=""
                fullWidth
                sx={{
                    mb: 5,
                }}
            />
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <Button
                    variant="contained"
                >
                    Submit
                </Button>
            </Grid>
        </Box>
    </Grid>
}