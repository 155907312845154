import React from 'react'
import { Grid, Typography, CircularProgress } from '@mui/material'
import { useParams } from 'react-router'
import useOrgActivities from '../customHooks/generalHooks/useOrgActivities'
import ActivitiesTable from './activitiesTable'

export default function Activities() {

    const { orgId } = useParams()
    const [loadStat, activities] = useOrgActivities(orgId)

    const returnInCenter = (elem) => {
        return <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ height: '80%', width: '100%' }}
        >
            {elem}
        </Grid>
    }

    const render = () => {
        if (loadStat === 2) {
            return returnInCenter(<CircularProgress size={25} />)
        } else if (loadStat === 0) {
            return <ActivitiesTable
                activities={activities}
            />
        } else if (loadStat === -1) {
            return returnInCenter(
                <Typography variant="body2" color="error">
                    Something went wrong.
                </Typography>
            )
        }
    }

    return <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ height: '100%', width: '100%', paddingTop: 20 }}
    >
        {render()}
    </Grid>
}
