/**
 * @fileoverview gRPC-Web generated client stub for devWithMazey.client
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: client.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.devWithMazey = {};
proto.devWithMazey.client = require('./client_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.devWithMazey.client.ClientServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.devWithMazey.client.ClientServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devWithMazey.client.Client,
 *   !proto.devWithMazey.client.GeneralResponse>}
 */
const methodDescriptor_ClientService_CreateClient = new grpc.web.MethodDescriptor(
  '/devWithMazey.client.ClientService/CreateClient',
  grpc.web.MethodType.UNARY,
  proto.devWithMazey.client.Client,
  proto.devWithMazey.client.GeneralResponse,
  /**
   * @param {!proto.devWithMazey.client.Client} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devWithMazey.client.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.devWithMazey.client.Client} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devWithMazey.client.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devWithMazey.client.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devWithMazey.client.ClientServiceClient.prototype.createClient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devWithMazey.client.ClientService/CreateClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_CreateClient,
      callback);
};


/**
 * @param {!proto.devWithMazey.client.Client} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devWithMazey.client.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.devWithMazey.client.ClientServicePromiseClient.prototype.createClient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devWithMazey.client.ClientService/CreateClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_CreateClient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devWithMazey.client.ClientId,
 *   !proto.devWithMazey.client.Client>}
 */
const methodDescriptor_ClientService_GetClientDetails = new grpc.web.MethodDescriptor(
  '/devWithMazey.client.ClientService/GetClientDetails',
  grpc.web.MethodType.UNARY,
  proto.devWithMazey.client.ClientId,
  proto.devWithMazey.client.Client,
  /**
   * @param {!proto.devWithMazey.client.ClientId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devWithMazey.client.Client.deserializeBinary
);


/**
 * @param {!proto.devWithMazey.client.ClientId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devWithMazey.client.Client)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devWithMazey.client.Client>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devWithMazey.client.ClientServiceClient.prototype.getClientDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devWithMazey.client.ClientService/GetClientDetails',
      request,
      metadata || {},
      methodDescriptor_ClientService_GetClientDetails,
      callback);
};


/**
 * @param {!proto.devWithMazey.client.ClientId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devWithMazey.client.Client>}
 *     Promise that resolves to the response
 */
proto.devWithMazey.client.ClientServicePromiseClient.prototype.getClientDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devWithMazey.client.ClientService/GetClientDetails',
      request,
      metadata || {},
      methodDescriptor_ClientService_GetClientDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devWithMazey.client.ClientId,
 *   !proto.devWithMazey.client.Client>}
 */
const methodDescriptor_ClientService_GetClientPermissions = new grpc.web.MethodDescriptor(
  '/devWithMazey.client.ClientService/GetClientPermissions',
  grpc.web.MethodType.UNARY,
  proto.devWithMazey.client.ClientId,
  proto.devWithMazey.client.Client,
  /**
   * @param {!proto.devWithMazey.client.ClientId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devWithMazey.client.Client.deserializeBinary
);


/**
 * @param {!proto.devWithMazey.client.ClientId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devWithMazey.client.Client)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devWithMazey.client.Client>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devWithMazey.client.ClientServiceClient.prototype.getClientPermissions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devWithMazey.client.ClientService/GetClientPermissions',
      request,
      metadata || {},
      methodDescriptor_ClientService_GetClientPermissions,
      callback);
};


/**
 * @param {!proto.devWithMazey.client.ClientId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devWithMazey.client.Client>}
 *     Promise that resolves to the response
 */
proto.devWithMazey.client.ClientServicePromiseClient.prototype.getClientPermissions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devWithMazey.client.ClientService/GetClientPermissions',
      request,
      metadata || {},
      methodDescriptor_ClientService_GetClientPermissions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devWithMazey.client.PermissionRequest,
 *   !proto.devWithMazey.client.GeneralResponse>}
 */
const methodDescriptor_ClientService_AddPermission = new grpc.web.MethodDescriptor(
  '/devWithMazey.client.ClientService/AddPermission',
  grpc.web.MethodType.UNARY,
  proto.devWithMazey.client.PermissionRequest,
  proto.devWithMazey.client.GeneralResponse,
  /**
   * @param {!proto.devWithMazey.client.PermissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devWithMazey.client.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.devWithMazey.client.PermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devWithMazey.client.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devWithMazey.client.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devWithMazey.client.ClientServiceClient.prototype.addPermission =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devWithMazey.client.ClientService/AddPermission',
      request,
      metadata || {},
      methodDescriptor_ClientService_AddPermission,
      callback);
};


/**
 * @param {!proto.devWithMazey.client.PermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devWithMazey.client.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.devWithMazey.client.ClientServicePromiseClient.prototype.addPermission =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devWithMazey.client.ClientService/AddPermission',
      request,
      metadata || {},
      methodDescriptor_ClientService_AddPermission);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devWithMazey.client.PermissionRequest,
 *   !proto.devWithMazey.client.GeneralResponse>}
 */
const methodDescriptor_ClientService_RemovePermission = new grpc.web.MethodDescriptor(
  '/devWithMazey.client.ClientService/RemovePermission',
  grpc.web.MethodType.UNARY,
  proto.devWithMazey.client.PermissionRequest,
  proto.devWithMazey.client.GeneralResponse,
  /**
   * @param {!proto.devWithMazey.client.PermissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devWithMazey.client.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.devWithMazey.client.PermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devWithMazey.client.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devWithMazey.client.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devWithMazey.client.ClientServiceClient.prototype.removePermission =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devWithMazey.client.ClientService/RemovePermission',
      request,
      metadata || {},
      methodDescriptor_ClientService_RemovePermission,
      callback);
};


/**
 * @param {!proto.devWithMazey.client.PermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devWithMazey.client.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.devWithMazey.client.ClientServicePromiseClient.prototype.removePermission =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devWithMazey.client.ClientService/RemovePermission',
      request,
      metadata || {},
      methodDescriptor_ClientService_RemovePermission);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devWithMazey.client.GeneralRequest,
 *   !proto.devWithMazey.client.SearchPermissionResponse>}
 */
const methodDescriptor_ClientService_SearchPermission = new grpc.web.MethodDescriptor(
  '/devWithMazey.client.ClientService/SearchPermission',
  grpc.web.MethodType.UNARY,
  proto.devWithMazey.client.GeneralRequest,
  proto.devWithMazey.client.SearchPermissionResponse,
  /**
   * @param {!proto.devWithMazey.client.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devWithMazey.client.SearchPermissionResponse.deserializeBinary
);


/**
 * @param {!proto.devWithMazey.client.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devWithMazey.client.SearchPermissionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devWithMazey.client.SearchPermissionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devWithMazey.client.ClientServiceClient.prototype.searchPermission =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devWithMazey.client.ClientService/SearchPermission',
      request,
      metadata || {},
      methodDescriptor_ClientService_SearchPermission,
      callback);
};


/**
 * @param {!proto.devWithMazey.client.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devWithMazey.client.SearchPermissionResponse>}
 *     Promise that resolves to the response
 */
proto.devWithMazey.client.ClientServicePromiseClient.prototype.searchPermission =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devWithMazey.client.ClientService/SearchPermission',
      request,
      metadata || {},
      methodDescriptor_ClientService_SearchPermission);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devWithMazey.client.ClientId,
 *   !proto.devWithMazey.client.GetUsageMetricsResponse>}
 */
const methodDescriptor_ClientService_GetUsageMetrics = new grpc.web.MethodDescriptor(
  '/devWithMazey.client.ClientService/GetUsageMetrics',
  grpc.web.MethodType.UNARY,
  proto.devWithMazey.client.ClientId,
  proto.devWithMazey.client.GetUsageMetricsResponse,
  /**
   * @param {!proto.devWithMazey.client.ClientId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devWithMazey.client.GetUsageMetricsResponse.deserializeBinary
);


/**
 * @param {!proto.devWithMazey.client.ClientId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devWithMazey.client.GetUsageMetricsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devWithMazey.client.GetUsageMetricsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devWithMazey.client.ClientServiceClient.prototype.getUsageMetrics =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devWithMazey.client.ClientService/GetUsageMetrics',
      request,
      metadata || {},
      methodDescriptor_ClientService_GetUsageMetrics,
      callback);
};


/**
 * @param {!proto.devWithMazey.client.ClientId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devWithMazey.client.GetUsageMetricsResponse>}
 *     Promise that resolves to the response
 */
proto.devWithMazey.client.ClientServicePromiseClient.prototype.getUsageMetrics =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devWithMazey.client.ClientService/GetUsageMetrics',
      request,
      metadata || {},
      methodDescriptor_ClientService_GetUsageMetrics);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devWithMazey.client.ClientId,
 *   !proto.devWithMazey.client.GeneralResponse>}
 */
const methodDescriptor_ClientService_GenerateClientSecret = new grpc.web.MethodDescriptor(
  '/devWithMazey.client.ClientService/GenerateClientSecret',
  grpc.web.MethodType.UNARY,
  proto.devWithMazey.client.ClientId,
  proto.devWithMazey.client.GeneralResponse,
  /**
   * @param {!proto.devWithMazey.client.ClientId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devWithMazey.client.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.devWithMazey.client.ClientId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devWithMazey.client.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devWithMazey.client.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devWithMazey.client.ClientServiceClient.prototype.generateClientSecret =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devWithMazey.client.ClientService/GenerateClientSecret',
      request,
      metadata || {},
      methodDescriptor_ClientService_GenerateClientSecret,
      callback);
};


/**
 * @param {!proto.devWithMazey.client.ClientId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devWithMazey.client.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.devWithMazey.client.ClientServicePromiseClient.prototype.generateClientSecret =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devWithMazey.client.ClientService/GenerateClientSecret',
      request,
      metadata || {},
      methodDescriptor_ClientService_GenerateClientSecret);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devWithMazey.client.ClientId,
 *   !proto.devWithMazey.client.GeneralResponse>}
 */
const methodDescriptor_ClientService_DeleteClient = new grpc.web.MethodDescriptor(
  '/devWithMazey.client.ClientService/DeleteClient',
  grpc.web.MethodType.UNARY,
  proto.devWithMazey.client.ClientId,
  proto.devWithMazey.client.GeneralResponse,
  /**
   * @param {!proto.devWithMazey.client.ClientId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devWithMazey.client.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.devWithMazey.client.ClientId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devWithMazey.client.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devWithMazey.client.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devWithMazey.client.ClientServiceClient.prototype.deleteClient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devWithMazey.client.ClientService/DeleteClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_DeleteClient,
      callback);
};


/**
 * @param {!proto.devWithMazey.client.ClientId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devWithMazey.client.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.devWithMazey.client.ClientServicePromiseClient.prototype.deleteClient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devWithMazey.client.ClientService/DeleteClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_DeleteClient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devWithMazey.client.ClientId,
 *   !proto.devWithMazey.client.GetActivitiesResponse>}
 */
const methodDescriptor_ClientService_GetActivities = new grpc.web.MethodDescriptor(
  '/devWithMazey.client.ClientService/GetActivities',
  grpc.web.MethodType.UNARY,
  proto.devWithMazey.client.ClientId,
  proto.devWithMazey.client.GetActivitiesResponse,
  /**
   * @param {!proto.devWithMazey.client.ClientId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devWithMazey.client.GetActivitiesResponse.deserializeBinary
);


/**
 * @param {!proto.devWithMazey.client.ClientId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devWithMazey.client.GetActivitiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devWithMazey.client.GetActivitiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devWithMazey.client.ClientServiceClient.prototype.getActivities =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devWithMazey.client.ClientService/GetActivities',
      request,
      metadata || {},
      methodDescriptor_ClientService_GetActivities,
      callback);
};


/**
 * @param {!proto.devWithMazey.client.ClientId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devWithMazey.client.GetActivitiesResponse>}
 *     Promise that resolves to the response
 */
proto.devWithMazey.client.ClientServicePromiseClient.prototype.getActivities =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devWithMazey.client.ClientService/GetActivities',
      request,
      metadata || {},
      methodDescriptor_ClientService_GetActivities);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devWithMazey.client.ClientId,
 *   !proto.devWithMazey.client.GeneralResponse>}
 */
const methodDescriptor_ClientService_PushToProd = new grpc.web.MethodDescriptor(
  '/devWithMazey.client.ClientService/PushToProd',
  grpc.web.MethodType.UNARY,
  proto.devWithMazey.client.ClientId,
  proto.devWithMazey.client.GeneralResponse,
  /**
   * @param {!proto.devWithMazey.client.ClientId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devWithMazey.client.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.devWithMazey.client.ClientId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devWithMazey.client.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devWithMazey.client.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devWithMazey.client.ClientServiceClient.prototype.pushToProd =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devWithMazey.client.ClientService/PushToProd',
      request,
      metadata || {},
      methodDescriptor_ClientService_PushToProd,
      callback);
};


/**
 * @param {!proto.devWithMazey.client.ClientId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devWithMazey.client.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.devWithMazey.client.ClientServicePromiseClient.prototype.pushToProd =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devWithMazey.client.ClientService/PushToProd',
      request,
      metadata || {},
      methodDescriptor_ClientService_PushToProd);
};


module.exports = proto.devWithMazey.client;

