import { Button } from '@mui/material'
import React, { useState } from 'react'
import EditDetails from './editDetails'
import ShowDetails from './showDetails'

export default function AppDetails(props) {

    const [view, setView] = useState('showDetails')

    const render = () => {
        if (view === 'showDetails') {
            return <ShowDetails
                appData={props.appData}
            />
        } else {
            return <EditDetails
                appData={props.appData}
                containerKey={props.containerKey}
                setContainerKey={props.setContainerKey}
            />
        }
    }

    const renderButton = () => {
        if (view === 'showDetails') {
            return <Button color="primary" style={{ textTransform: 'none' }} onClick={() => setView('editDetails')}>
                Edit Details
            </Button>
        } else {
            return <Button color="primary" style={{ textTransform: 'none' }} onClick={() => setView('showDetails')}>
                Show Details
            </Button>
        }
    }

    return <>
        {renderButton()}
        {render()}
    </>
}