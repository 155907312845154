import React from 'react'
import { MenuItem, Menu, IconButton } from '@mui/material'
import { AccountCircle } from '@mui/icons-material'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'

export default function ProfileOptions() {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const isMenuOpen = Boolean(anchorEl)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id='profile-menu-popup'
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {/* <MenuItem
                onClick={() => {
                    handleMenuClose()
                    navigate('/profile')
                }}
            >
                Profile
            </MenuItem> */}
            <MenuItem
                onClick={() => {
                    dispatch({ type: 'LOGOUT' })
                }}
                sx={{ color: 'error.main' }}
            >
                Logout
            </MenuItem>
        </Menu>
    );

    return <>
        <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls='profile-menu-popup'
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="primary"
        >
            <AccountCircle fontSize="large" />
        </IconButton>
        {renderMenu}
    </>
}