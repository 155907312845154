import { createStore } from "redux";

const defaultState = {
    loggedIn: false,
    initState: 2,
    userInfo: {},
    organizations: []
}

const rootReducer = (state = defaultState, action) => {

    switch (action.type) {

        case 'LOGIN':
            localStorage.setItem('consoleUser', JSON.stringify(action.userData))
            return {
                ...state,
                loggedIn: true,
                initState: 0,
                userInfo: action.userData
            }

        case 'LOGOUT':
            localStorage.removeItem('consoleUser')
            return {
                ...defaultState,
                initState: 0,
            }

        case 'ADD_ALL_ORGS':
            return {
                ...state,
                organizations: action.orgs
            }

        case 'ADD_ORG':
            var add_org_all_orgs = [...state.organizations]
            // if org exists, replace it
            for (let i = 0; i < add_org_all_orgs.length; i++) {
                if (add_org_all_orgs[i].orgid === action.org.orgid) {
                    add_org_all_orgs[i] = action.org
                    return {
                        ...state,
                        organizations: add_org_all_orgs
                    }
                }
            }
            // else add new
            return {
                ...state,
                organizations: [...state.organizations, action.org]
            }

        case 'REMOVE_ORG':
            return {
                ...state,
                organizations: state.organizations.filter(org => org.orgid !== action.orgId)
            }

        case 'REMOVE_CLIENT':
            var rc_org = [...state.organizations]
            for (let i = 0; i < rc_org.length; i++) {
                if (rc_org[i].orgid === action.orgId) {
                    rc_org[i].clientsList = rc_org[i].clientsList.filter(client => client.clientid !== action.clientId)
                }
            }
            return {
                ...state,
                organizations: rc_org
            }

        default:
            return state
    }
};

export const store = createStore(rootReducer);