import React from 'react'
import { Drawer } from '@mui/material'

export default function SideDrawer(props) {

    return <Drawer
        anchor="left"
        sx={{
            width: { xs: 240, md: 0 },
            display: { xs: 'block', md: 'none' },
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: 240,
                boxSizing: 'border-box',
                backgroundColor: 'background.main',
            },
        }}
        open={props.displaySideDrawer}
        onClose={() => props.setDisplaySideDrawer(!props.displaySideDrawer)}
    >
        {props.sidebarContent}
    </Drawer>
}
