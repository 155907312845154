import React from 'react'
import { Box, Grid, Typography } from '@mui/material'

const fields = [
    {
        label: 'Client Name',
        fieldName: 'clientname',
    },
    {
        label: 'Client ID',
        fieldName: 'clientid',
    },
    {
        label: 'Organisation',
        fieldName: 'orgname',
    },
    {
        label: 'Environment',
        fieldName: 'status',
    },
    {
        label: 'Redirect URI',
        fieldName: 'redirecturi',
    },
    {
        label: 'Logo URL',
        fieldName: 'logo',
    },
    {
        label: 'Privacy Policy URL',
        fieldName: 'privacypolicy',
    },
    {
        label: 'Terms URL',
        fieldName: 'terms',
    },
]

export default function ShowDetails(props) {

    return <Box style={{ width: '100%' }}>
        {
            fields.map((field) => {
                return <Grid container spacing={0} key={field.fieldName}>
                    <Grid item style={{ width: '200px', margin: '3px 0 3px 10px' }}>
                        <Typography variant="body2" align="right">
                            {field.label} :
                        </Typography>
                    </Grid>
                    <Grid item xs style={{ margin: '3px 10px 3px 5px' }}>
                        <Typography variant="body2" align="left">
                            {props.appData[field.fieldName]}
                        </Typography>
                    </Grid>
                </Grid>
            })
        }
    </Box>
}