/**
 * @fileoverview gRPC-Web generated client stub for devWithMazey.auth
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: auth.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.devWithMazey = {};
proto.devWithMazey.auth = require('./auth_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.devWithMazey.auth.AuthServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.devWithMazey.auth.AuthServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devWithMazey.auth.LoginRequest,
 *   !proto.devWithMazey.auth.LoginResponse>}
 */
const methodDescriptor_AuthService_Login = new grpc.web.MethodDescriptor(
  '/devWithMazey.auth.AuthService/Login',
  grpc.web.MethodType.UNARY,
  proto.devWithMazey.auth.LoginRequest,
  proto.devWithMazey.auth.LoginResponse,
  /**
   * @param {!proto.devWithMazey.auth.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devWithMazey.auth.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.devWithMazey.auth.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devWithMazey.auth.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devWithMazey.auth.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devWithMazey.auth.AuthServiceClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devWithMazey.auth.AuthService/Login',
      request,
      metadata || {},
      methodDescriptor_AuthService_Login,
      callback);
};


/**
 * @param {!proto.devWithMazey.auth.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devWithMazey.auth.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.devWithMazey.auth.AuthServicePromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devWithMazey.auth.AuthService/Login',
      request,
      metadata || {},
      methodDescriptor_AuthService_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devWithMazey.auth.SignUpRequest,
 *   !proto.devWithMazey.auth.GeneralResponse>}
 */
const methodDescriptor_AuthService_SignUp = new grpc.web.MethodDescriptor(
  '/devWithMazey.auth.AuthService/SignUp',
  grpc.web.MethodType.UNARY,
  proto.devWithMazey.auth.SignUpRequest,
  proto.devWithMazey.auth.GeneralResponse,
  /**
   * @param {!proto.devWithMazey.auth.SignUpRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devWithMazey.auth.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.devWithMazey.auth.SignUpRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devWithMazey.auth.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devWithMazey.auth.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devWithMazey.auth.AuthServiceClient.prototype.signUp =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devWithMazey.auth.AuthService/SignUp',
      request,
      metadata || {},
      methodDescriptor_AuthService_SignUp,
      callback);
};


/**
 * @param {!proto.devWithMazey.auth.SignUpRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devWithMazey.auth.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.devWithMazey.auth.AuthServicePromiseClient.prototype.signUp =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devWithMazey.auth.AuthService/SignUp',
      request,
      metadata || {},
      methodDescriptor_AuthService_SignUp);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devWithMazey.auth.GeneralRequest,
 *   !proto.devWithMazey.auth.GeneralResponse>}
 */
const methodDescriptor_AuthService_VerifyEmail = new grpc.web.MethodDescriptor(
  '/devWithMazey.auth.AuthService/VerifyEmail',
  grpc.web.MethodType.UNARY,
  proto.devWithMazey.auth.GeneralRequest,
  proto.devWithMazey.auth.GeneralResponse,
  /**
   * @param {!proto.devWithMazey.auth.GeneralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devWithMazey.auth.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.devWithMazey.auth.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devWithMazey.auth.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devWithMazey.auth.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devWithMazey.auth.AuthServiceClient.prototype.verifyEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devWithMazey.auth.AuthService/VerifyEmail',
      request,
      metadata || {},
      methodDescriptor_AuthService_VerifyEmail,
      callback);
};


/**
 * @param {!proto.devWithMazey.auth.GeneralRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devWithMazey.auth.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.devWithMazey.auth.AuthServicePromiseClient.prototype.verifyEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devWithMazey.auth.AuthService/VerifyEmail',
      request,
      metadata || {},
      methodDescriptor_AuthService_VerifyEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.devWithMazey.auth.ResetPasswordRequest,
 *   !proto.devWithMazey.auth.GeneralResponse>}
 */
const methodDescriptor_AuthService_ResetPassword = new grpc.web.MethodDescriptor(
  '/devWithMazey.auth.AuthService/ResetPassword',
  grpc.web.MethodType.UNARY,
  proto.devWithMazey.auth.ResetPasswordRequest,
  proto.devWithMazey.auth.GeneralResponse,
  /**
   * @param {!proto.devWithMazey.auth.ResetPasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.devWithMazey.auth.GeneralResponse.deserializeBinary
);


/**
 * @param {!proto.devWithMazey.auth.ResetPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.devWithMazey.auth.GeneralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.devWithMazey.auth.GeneralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.devWithMazey.auth.AuthServiceClient.prototype.resetPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/devWithMazey.auth.AuthService/ResetPassword',
      request,
      metadata || {},
      methodDescriptor_AuthService_ResetPassword,
      callback);
};


/**
 * @param {!proto.devWithMazey.auth.ResetPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.devWithMazey.auth.GeneralResponse>}
 *     Promise that resolves to the response
 */
proto.devWithMazey.auth.AuthServicePromiseClient.prototype.resetPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/devWithMazey.auth.AuthService/ResetPassword',
      request,
      metadata || {},
      methodDescriptor_AuthService_ResetPassword);
};


module.exports = proto.devWithMazey.auth;

