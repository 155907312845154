import React, { useState } from 'react'
import { Button, Typography, Box } from '@mui/material'
import GetMetadata from '../../../components/getMetadata'
import { ClientServerAddress } from '../../../protos/server'
import { ClientServiceClient } from '../../../protos/client_grpc_web_pb'
import { ClientId } from '../../../protos/client_pb'
import { useDispatch, useSelector } from 'react-redux'
import ButtonOnState from '../../../components/buttonOnState'

export default function GenerateSecret(props) {

    const [btnStat, setBtnStat] = useState(0)
    const [error, setError] = useState("")
    const [secret, setSecret] = useState("")
    const dispatch = useDispatch()
    const userInfo = useSelector(state => state.userInfo)

    const sendRequest = () => {
        setBtnStat(2)
        const md = GetMetadata()
        md.authorization = userInfo.token
        const client = new ClientServiceClient(ClientServerAddress, null, null)

        const req = new ClientId()
        req.setOrgid(props.appData.orgid)
        req.setClientid(props.appData.clientid)

        client.generateClientSecret(req, md, (err, response) => {
            if (err) {
                if (err.message === "token expired" || err.message === "error decoding token" || err.message === "no token" || err.message === "invalid token") {
                    dispatch({ type: 'LOGOUT' })
                    return
                }
                setBtnStat(-1)
                setError(err.message)
            } else {
                const res = response.toObject()
                setSecret(res.response)
                setBtnStat(1)
            }
        })
    }

    return <Box sx={{ width: '100%', marginBottom: '20px', borderBottom: '1px solid', borderColor: 'divider', paddingBottom: '20px' }}>
        <Button
            color="primary"
            style={{ marginTop: '10px', textTransform: 'none' }}
            disabled={btnStat !== 0}
            onClick={sendRequest}
        >
            <ButtonOnState
                loadState={btnStat}
                successText="Generate Client Secret"
            />
        </Button>
        <br />
        <Typography variant="caption" color="textSecondary">
            - This is a replacement action.
            <br />
            - Your requests with older secret will fail.
        </Typography>
        <Typography variant="body2" color="error" gutterBottom style={{ paddingLeft: 10 }} >
            {error}
        </Typography>
        {
            btnStat === 1 ? <Typography variant="body2" color="textSecondary" style={{ paddingLeft: 10 }} gutterBottom >
                Note this down. This is the only time you will be able to see it. (However, you can generate it again).
                <br />
                <strong>Client Secret : </strong> {secret}
            </Typography> : <></>
        }
    </Box>
}