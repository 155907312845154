import { useState, useEffect } from 'react'
import GetMetadata from '../../components/getMetadata'
import { ClientServerAddress } from '../../protos/server'
import { ClientServiceClient } from '../../protos/client_grpc_web_pb'
import { GeneralRequest } from '../../protos/client_pb'
import { useDispatch, useSelector } from 'react-redux'

export default function useSearchPerms(query) {
    const [stat, setStat] = useState(2)
    const [perms, setPerms] = useState([])
    const userInfo = useSelector((state) => state.userInfo)
    const dispatch = useDispatch()

    const fetchData = () => {
        setStat(2)
        const md = GetMetadata()
        md.authorization = userInfo.token
        const client = new ClientServiceClient(ClientServerAddress, null, null)

        const req = new GeneralRequest()
        req.setRequest(query)

        client.searchPermission(req, md, (err, response) => {
            if (err) {
                if (err.message === "token expired" || err.message === "error decoding token" || err.message === "no token" || err.message === "invalid token") {
                    dispatch({ type: 'LOGOUT' })
                    return
                }
                setStat(-1)
            } else {
                const res = response.toObject()
                setPerms(res.permissionList)
                setStat(0)
            }
        })
    }

    useEffect(() => {
        if (query.length > 3) {
            fetchData()
        }
    }, [query])

    return [stat, perms]
}