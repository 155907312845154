import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import GetMetadata from '../../components/getMetadata'
import { OrgServerAddress } from '../../protos/server'
import { OrganisationServiceClient } from '../../protos/org_grpc_web_pb'
import { GeneralRequest } from '../../protos/org_pb'

export default function useOrgs() {
    const [stat, setStat] = useState(2)
    const [orgs, setOrgs] = useState([])
    const organizations = useSelector((state) => state.organizations)
    const userInfo = useSelector((state) => state.userInfo)
    const dispatch = useDispatch()

    const fetchOrgs = () => {
        const md = GetMetadata()
        const client = new OrganisationServiceClient(OrgServerAddress, null, null)

        const req = new GeneralRequest()
        md.authorization = userInfo.token

        client.getOrganisations(req, md, (err, response) => {
            if (err) {
                if (err.message === "token expired" || err.message === "error decoding token" || err.message === "no token" || err.message === "invalid token") {
                    dispatch({ type: 'LOGOUT' })
                    return
                }
                setStat(-1)
            } else {
                const res = response.toObject()
                dispatch({ type: 'ADD_ALL_ORGS', orgs: res.organisationsList })
                setOrgs(res.organisationsList)
                setStat(0)
            }
        })
    }

    useEffect(() => {
        if (organizations.length === 0) {
            fetchOrgs()
        } else {
            setOrgs(organizations)
            setStat(0)
        }
    }, [organizations])

    return [stat, orgs]
}