import React from 'react'
import { Typography } from '@mui/material'

export default function TestingLimits() {

    const limits = [
        "Redirect URI can be localhost.",
        "Addresses of logo, privacy policy and terms & conditions are not necessary to be accurate (but should not be blank).",
        "Your client will be in a restricted sandbox.",
        "You cannot access production APIs with this client unless you move it to prod.",
        "You can test your workflows with devport users.",
        "Refer docs for dev environment and endpoints."
    ]

    return <>
        <Typography variant="subtitle1" gutterBottom color="textSecondary">
            Development Environment <strong>:</strong>
        </Typography>
        <Typography variant="subtitle2" gutterBottom color="textSecondary">
            <ul>
                {
                    limits.map((limit, index) => {
                        return <li key={index}>{limit}</li>
                    })
                }
            </ul>
        </Typography>
    </>
}
