import React, { useState } from 'react'
import { Grid, Button, Typography, CircularProgress, AppBar, Toolbar } from '@mui/material'
import { ArrowBack, } from '@mui/icons-material'
import { useParams, useNavigate } from 'react-router'
import useAppData from '../customHooks/generalHooks/useAppData'
import AppsTabs from './appsTabs'
import AppDetails from './appDetails'
import UserLostContent from '../fallback/userLostContent'
import Permissions from './permissions'
import AppMetrics from './metrics'
import AppSettings from './settings.js'
import AppActivities from './activities'

export default function EachAppContainer(props) {

    const { orgId, orgSubOpts } = useParams()
    const appId = orgSubOpts
    const [loadStat, appData] = useAppData(appId, orgId)
    const navigate = useNavigate()

    const changeAppTab = (event, newValue) => {
        props.setAppTab(newValue);
    }

    const returnInCenter = (elem) => {
        return <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ height: '80%', width: '100%' }}
        >
            {elem}
        </Grid>
    }

    const renderTabContent = () => {
        if (props.appTab === 0) {
            return <AppDetails
                appData={appData}
                containerKey={props.containerKey}
                setContainerKey={props.setContainerKey}
            />
        } else if (props.appTab === 1) {
            return <Permissions
                appId={appId}
                orgId={orgId}
            />
        } else if (props.appTab === 2) {
            return <AppActivities
                appId={appId}
                orgId={orgId}
            />
        } else if (props.appTab === 3) {
            return <AppMetrics
                appId={appId}
                orgId={orgId}
            />
        } else if (props.appTab === 4) {
            return <AppSettings
                appData={appData}
                containerKey={props.containerKey}
                setContainerKey={props.setContainerKey}
            />
        } else {
            return <UserLostContent />
        }
    }

    const render = () => {
        if (loadStat === 2) {
            return returnInCenter(<CircularProgress size={25} />)
        } else if (loadStat === 0) {
            return <>
                <div style={{ paddingTop: 10, paddingBottom: 10, width: '100%', marginTop: '85px' }}>
                    {renderTabContent()}
                </div>
            </>
        } else if (loadStat === -1) {
            return returnInCenter(
                <Typography variant="body1" color="error">
                    Something went wrong.
                </Typography>
            )
        }
    }

    return <>
        <AppBar
            position="fixed"
            sx={{
                marginTop: '65px',
                paddingLeft: '240px',
                backgroundColor: 'background.main',
                boxShadow: 'none'
            }}
        >
            <Toolbar variant="dense">
                <div style={{ width: '100%', marginTop: '0px' }}>
                    <Button
                        startIcon={<ArrowBack />}
                        sx={{ color: 'text.secondary', textTransform: 'none' }}
                        onClick={() => navigate('/dashboard/' + orgId + '/clients')}
                    >
                        Back
                    </Button>
                    {
                        loadStat === 0 ? <AppsTabs
                            appTab={props.appTab}
                            changeAppTab={changeAppTab}
                        /> : <></>
                    }
                </div>
            </Toolbar>
        </AppBar>
        {render()}
    </>
}
