import React, { useState } from 'react'
import { Button, Box, TextField, CircularProgress, Typography } from '@mui/material'
import HelperButtons from './helperButtons'
import GetMetadata from '../../components/getMetadata'
import { AuthServerAddress } from '../../protos/server'
import { AuthServiceClient } from '../../protos/auth_grpc_web_pb'
import { LoginRequest } from '../../protos/auth_pb'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import useQuery from '../../customHooks/generalHooks/useQuery'

export default function LoginForm() {
    const [values, setValues] = useState({
        email: '',
        password: '',
        error: '',
    })
    const [btnStat, setBtnStat] = useState(0)
    const changeValue = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value, error: '' })
        setBtnStat(0)
    }
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const query = useQuery()
    const redirectUrl = query.get('redirect')

    const submitButton = () => {
        if (btnStat === 2) {
            return <CircularProgress size={25} />
        } else if (btnStat === -1) {
            return "Error"
        } else {
            return "Log In"
        }
    }

    const handleSubmit = () => {
        setBtnStat(2)

        const md = GetMetadata()
        const client = new AuthServiceClient(AuthServerAddress, null, null)

        const req = new LoginRequest();
        req.setEmail(values.email)
        req.setPassword(values.password)

        client.login(req, md, (err, response) => {
            if (err) {
                setValues({ ...values, error: err.message })
                setBtnStat(-1)
            } else {
                setBtnStat(0)
                dispatch({ type: 'LOGIN', userData: response.toObject() })
                if (redirectUrl === undefined || redirectUrl === null) {
                    navigate('/dashboard')
                } else {
                    navigate(redirectUrl)
                }
            }
        })
    }

    return <Box
        component="form"
        noValidate
        autoComplete="off"
        style={{ width: '100%', maxWidth: 500 }}
    >
        <TextField
            id="email"
            label="Email Address"
            variant="outlined"
            fullWidth
            onChange={changeValue('email')}
            value={values.email}
            style={{ marginBottom: 15 }}
            disabled={btnStat === 2}
        />
        <TextField
            id="password"
            label="Pasword"
            variant="outlined"
            type="password"
            fullWidth
            onChange={changeValue('password')}
            value={values.password}
            style={{ marginBottom: '40px' }}
            disabled={btnStat === 2}
        />
        <Button
            variant="contained"
            disabled={btnStat !== 0 || values.email.length < 5 || values.password.length < 8 || values.error.length > 0}
            style={{ width: '100%', marginBottom: 40, textTransform: 'none' }}
            onClick={handleSubmit}
        >
            {submitButton()}
        </Button>
        <Typography variant="caption" color="error">
            {values.error}
        </Typography>
        <HelperButtons />
    </Box>
}
