import React, { useState } from 'react'
import { Typography, Grid, Button, TextField } from '@mui/material'
import PermsAddParams from './permsAddParams'
import ButtonOnState from '../../components/buttonOnState'
import { useDispatch, useSelector } from 'react-redux'
import GetMetadata from '../../components/getMetadata'
import { ClientServerAddress } from '../../protos/server'
import { ClientServiceClient } from '../../protos/client_grpc_web_pb'
import { PermissionRequest, Permission } from '../../protos/client_pb'

export default function PermsAddBox(props) {

    const [btnStat, setBtnStat] = useState(0)
    const [error, setError] = useState("")
    const dispatch = useDispatch()
    const userInfo = useSelector(state => state.userInfo)

    const changeDescription = (event) => {
        props.setPermToAdd({
            ...props.permToAdd,
            permdesc: event.target.value
        })
        setError("")
        setBtnStat(0)
    }

    const handleSubmit = () => {
        setBtnStat(2)
        const md = GetMetadata()
        md.authorization = userInfo.token
        const client = new ClientServiceClient(ClientServerAddress, null, null)

        const permToAdd = new Permission()
        permToAdd.setPermid(parseInt(props.permToAdd.permid))
        permToAdd.setPermdesc(props.permToAdd.permdesc)

        const req = new PermissionRequest()
        req.setClientid(props.appId)
        req.setOrgid(props.orgId)
        req.setPermission(permToAdd)
        req.setParameterList(props.permToAdd.paramsList)

        client.addPermission(req, md, (err, response) => {
            if (err) {
                if (err.message === "token expired" || err.message === "error decoding token" || err.message === "no token" || err.message === "invalid token") {
                    dispatch({ type: 'LOGOUT' })
                    return
                }
                setBtnStat(-1)
                setError(err.message)
            } else {
                setBtnStat(0)
                props.setSelectedPermKey(key => key + 1)
            }
        })
    }

    const renderPermName = () => {
        if (props.permToAdd.paramsList === undefined) {
            return props.permToAdd.permname
        } else if (props.permToAdd.paramsList.length === 0) {
            return props.permToAdd.permname
        } else if (props.permToAdd.paramsList.length === 1) {
            return props.permToAdd.permname + '/' + props.permToAdd.paramsList[0]
        } else {
            return props.permToAdd.permname + '/[' + props.permToAdd.paramsList.join() + ']'
        }
    }

    const render = () => {
        if (props.permToAdd.permid === undefined) {
            return <Typography variant="subtitle1" color="error" align="center">
                Something went wrong.
            </Typography>
        } else {
            return <>
                <Typography variant="subtitle1" align="left" style={{ width: '100%' }}>
                    Permission Name - {renderPermName()}
                </Typography>
                <Typography variant="subtitle1" align="left"
                    style={{ width: '100%' }}
                >
                    Permission ID - {props.permToAdd.permid}
                </Typography>
            </>
        }
    }

    return <>
        {render()}
        <PermsAddParams
            permToAdd={props.permToAdd}
            setPermToAdd={props.setPermToAdd}
        />
        <TextField
            id="perm-desc"
            label="Description"
            variant="outlined"
            placeholder="Describe the permission use properly"
            style={{ width: '100%', margin: '30px 10px 0 0' }}
            value={props.permToAdd.permdesc}
            onChange={changeDescription}
            helperText="Describe the permission use properly"
        />
        <Typography variant="body2" color="error" align="center" style={{ marginTop: '20px', marginBottom: '10px' }}>
            {error}
        </Typography>
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            style={{ marginTop: 20 }}
        >
            <Button
                color="error"
                variant="outlined"
                style={{ textTransform: 'none' }}
                onClick={() => props.setView('permSearch')}
            >
                Cancel
            </Button>
            <Button
                color="primary"
                variant="outlined"
                style={{ textTransform: 'none' }}
                disabled={props.permToAdd.permid === undefined || btnStat !== 0}
                onClick={handleSubmit}
            >
                <ButtonOnState
                    successText="Submit"
                    loadState={btnStat}
                />
            </Button>
        </Grid>
    </>
}